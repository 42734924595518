import React, { useState } from 'react';
import {
  objectOf,
  shape,
  func,
  string,
  oneOf,
  number,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/UnfoldMore';
import InputBase from '@material-ui/core/InputBase';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import styles from './FastLibraryMuiStyles';
import variables from '../../../styles/variables.module.scss';
import MyNotesIcon from '../../../assets/Icons/MyNotesIcon';
import MyDocsIcon from '../../../assets/Icons/MyDocsIcon';
import ListIcon from '../../../assets/Icons/ListIcon';
import GridIcon from '../../../assets/Icons/GridIcon';
import {
  BOOK,
  LEFT,
  NOTE,
  RIGHT,
  UPLOAD,
} from '../../../utils/constants';
import { MenuBook } from '@material-ui/icons';
import { arrayOf } from 'prop-types';

const StyledMenu = withStyles({
  paper: {
    backgroundColor: variables.colorExtraLightGrey,
    width: 180,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(() => ({
  root: {
    backgroundColor: variables.colorExtraLightGrey,
    '&:focus': {
      backgroundColor: variables.colorExtraLightGrey,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary':
        {
          color: variables.colorExtraLightGrey,
        },
    },
  },
}))(MenuItem);

const FastLibraryMenu = ({
  anchorEl,
  handleClick,
  handleClose,
  setSearchText,
  classes,
  buttonText,
  paneSide,
  addNote,
  onToggleLayout,
  onFilterListItemeClick,
  filterMenuItems,
  onUploadFile,
}) => {
  const [alignment, setAlignment] = useState('left');

  const uploadFile = ({ target }) => {
    const file = target.files[0];
    onUploadFile(file);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <div className={classes.menuContainer}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <Grid item xs={12}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="outlined"
              onClick={handleClick}
              classes={{ root: classes.categoryButton }}
            >
              {buttonText}
              <MoreIcon className={classes.rightIcon} />
            </Button>
            <ToggleButtonGroup
              classes={{ root: classes.toggleGroup }}
              value={alignment}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton
                classes={{ root: classes.toggleButton }}
                onClick={() => onToggleLayout(false)}
                key={1}
                value="left"
              >
                <ListIcon fill={variables.colorPrimary} />
              </ToggleButton>
              <ToggleButton
                classes={{ root: classes.toggleButton }}
                onClick={() => onToggleLayout(true)}
                key={2}
                value="right"
              >
                <GridIcon fill={variables.colorPrimary} />
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="outlined"
              onClick={() => addNote(paneSide)}
              classes={{ root: classes.addNoteButton }}
            >
              Skapa
              <div className={classes.addNoteIcon}>
                <MyNotesIcon
                  fill={variables.colorPrimary}
                  width={26}
                />
              </div>
            </Button>
            <input
              type="file"
              style={{ display: 'none' }}
              id="buttonUpload"
              name="Uppladdning"
              onChange={uploadFile}
              multiple={false}
              accept="application/pdf"
            />
            <Button
              variant="outlined"
              component="label"
              htmlFor="buttonUpload"
              classes={{ root: classes.addDocButton }}
            >
              Ladda upp
              <div className={classes.addDocIcon}>
                <MyDocsIcon
                  fill={variables.colorPrimary}
                  width={20}
                />
              </div>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menuToggleContainer}
            >
              {filterMenuItems.map((menuItem) => (
                <StyledMenuItem
                  key={menuItem.name}
                  classes={{
                    root: classes.menuItem,
                    selected: classes.selected,
                  }}
                  onClick={() =>
                    onFilterListItemeClick(menuItem.type)
                  }
                >
                  <ListItemIcon>
                    <FilterMenuIcon
                      type={menuItem.type}
                      fill={variables.colorPrimary}
                      width={20}
                    />
                  </ListItemIcon>
                  <ListItemText
                    secondary={menuItem.name}
                    classes={{
                      secondary: classes.listItemText,
                    }}
                  />
                </StyledMenuItem>
              ))}
            </StyledMenu>
          </Grid>
        </div>

        <div className={classes.searchInput}>
          <Grid item xs={12}>
            <IconButton
              className={classes.iconButton}
              aria-label="Search"
            >
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
            <InputBase
              placeholder="Sök"
              onChange={({ target }) =>
                setSearchText(target.value)
              }
            />
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

FastLibraryMenu.propTypes = {
  handleClick: func,
  handleClose: func,
  setSearchText: func,
  classes: objectOf(shape),
  addNote: func,
  onToggleLayout: func.isRequired,
  buttonText: string.isRequired,
  onFilterListItemeClick: func.isRequired,
  paneSide: oneOf([LEFT, RIGHT]),
  filterMenuItems: arrayOf(
    shape({ name: string, type: string })
  ).isRequired,
  onUploadFile: func,
};

FastLibraryMenu.defaultProps = {
  handleClick: null,
  handleClose: null,
  setSearchText: null,
  classes: null,
  addNote: null,
};

const FilterMenuIcon = ({ type, ...props }) => {
  switch (type) {
    case BOOK:
      return (
        <MenuBook
          style={{
            color: props.fill,
            width: props.width,
          }}
        />
      );
    case NOTE:
      return <MyNotesIcon {...props} />;
    case UPLOAD:
      return <MyDocsIcon {...props} />;
    default:
      return <div />;
  }
};

FilterMenuIcon.propTypes = {
  type: string,
  fill: string,
  width: number,
};

export default withStyles(styles)(FastLibraryMenu);
