import { Chip, MenuItem, Select } from '@material-ui/core';
import {
  arrayOf,
  bool,
  func,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import useStyles from './style';

const LibrarySelect = ({
  data,
  value,
  onChange,
  disabled,
}) => {
  const classes = useStyles();

  const getChipLabel = (value) => {
    const item = data.publicTags.find(
      (item) => item.id === value
    );
    return item.shortName ?? item.name;
  };

  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      variant="outlined"
      multiple
      displayEmpty
      className={classes.select}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      renderValue={(selected) =>
        selected.length === 0 ? (
          <em>{data?.name ?? ''}</em>
        ) : (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={getChipLabel(value)}
                className={classes.chip}
              />
            ))}
          </div>
        )
      }
    >
      <MenuItem value="" disabled>
        <em>{data?.name ?? ''}</em>
      </MenuItem>
      {data?.publicTags.map((tag) => (
        <MenuItem key={tag.id} value={tag.id}>
          {tag.name}
        </MenuItem>
      ))}
    </Select>
  );
};

LibrarySelect.propTypes = {
  value: arrayOf(string).isRequired,
  onChange: func.isRequired,
  data: shape({
    id: string,
    name: string,
    publicTags: arrayOf(
      shape({
        id: string,
        name: string,
        shortName: string,
      })
    ),
  }),
  disabled: bool,
};

LibrarySelect.defaultProps = {
  data: undefined,
  disabled: false,
};

export default LibrarySelect;
