import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { string, arrayOf, func, shape } from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import styles from './NotesContainer.module.scss';
import Divider from '../../Divider';
import ListItem from '../../Items/ListItem';
import NoteIcon from '../../../assets/Icons/NoteIcon';
import { NOTE } from '../../../utils/constants';
import {
  UPDATE_NOTE_TITLE,
  DELETE_NOTE,
  INSERT_USER_NOTE_TAG,
  DELETE_USER_NOTE_TAG,
} from '../../../graphql/mutations';
import {
  updateCacheForNoteTag,
  updateCacheForNoteDelete,
  optimisticUIForNoteTitle,
  optimisticUIForNoteTagAdd,
} from '../../../utils/apolloHelpers';

const NotesContainer = ({
  searchFilter,
  userTagData,
  onItemSelected,
  selectedTags,
  notes,
}) => {
  const [updateTitle] = useMutation(UPDATE_NOTE_TITLE);
  const [deleteItem] = useMutation(DELETE_NOTE);
  const [addTagToItem] = useMutation(INSERT_USER_NOTE_TAG);
  const [deleteTagToItem] = useMutation(
    DELETE_USER_NOTE_TAG
  );

  const onUpdateItemTitle = (title, id) => {
    updateTitle({
      variables: { id, title },
      optimisticResponse: optimisticUIForNoteTitle(
        id,
        title
      ),
    });
  };

  const onDeleteItem = (noteId) => {
    deleteItem({
      variables: { id: noteId },
      update(cache, { data: { delete_note: returnData } }) {
        updateCacheForNoteDelete(cache, returnData);
      },
    });
  };

  const onAddTagToItem = (userNoteId, tagId) => {
    addTagToItem({
      variables: { userNoteId, tagId },
      update(
        cache,
        { data: { insert_user_note_tag: returnData } }
      ) {
        updateCacheForNoteTag(cache, returnData);
      },
      optimisticResponse: optimisticUIForNoteTagAdd(
        userNoteId,
        userTagData.find((e) => e.id === tagId)
      ),
    });
  };

  const onDeleteTagToItem = (id) => {
    deleteTagToItem({
      variables: { id },
      update(
        cache,
        { data: { delete_user_note_tag: returnData } }
      ) {
        updateCacheForNoteTag(cache, returnData, false);
      },
    });
  };

  const onTagSelected =
    (userNoteId) => (tagId, userNoteTagId) => {
      const note = notes.find((e) => e.id === userNoteId);
      const tagIsMarked = note.user_note_tags.find(
        (e) => e.tag.id === tagId
      );
      if (tagIsMarked) {
        onDeleteTagToItem(userNoteTagId);
      } else {
        onAddTagToItem(userNoteId, tagId);
      }
    };

  const filterHelper = (e) => {
    let found = false;
    e.user_note_tags.forEach((x) => {
      if (selectedTags.includes(x.tag.id)) {
        found = true;
      }
    });
    return found;
  };

  const filteredNotes = notes
    ? notes.filter(
        (e) =>
          e.note.title
            .toLowerCase()
            .includes(searchFilter) &&
          (selectedTags.length > 0 ? filterHelper(e) : true)
      )
    : null;

  const emptyPlaceholder = () => (
    <div className={styles.emptyPlaceholderContainer}>
      <h2 className={styles.emptyPlaceholderText}>
        Inga anteckningar hittades
      </h2>
    </div>
  );

  return (
    <Grid item xs={6}>
      <div className={styles.container}>
        <h2 className={styles.headerStyle}>Anteckningar</h2>
        {!notes || (notes && notes.length === 0) ? (
          emptyPlaceholder()
        ) : (
          <div className={styles.listItems}>
            {filteredNotes.map((item, index) => (
              <Fragment key={item.note.id}>
                <ListItem
                  item={{ ...item.note }}
                  tags={item.user_note_tags}
                  userTagData={userTagData}
                  listIcon={
                    <NoteIcon
                      style={{ marginRight: '14px' }}
                      width="30"
                      height="39"
                    />
                  }
                  onChangeItemName={onUpdateItemTitle}
                  onTagSelected={onTagSelected(item.id)}
                  onRemoveItem={() => {
                    onDeleteItem(item.note.id);
                  }}
                  onClick={() => {
                    onItemSelected(item.id, NOTE);
                  }}
                />
                {notes &&
                  notes &&
                  index !== notes.length - 1 && <Divider />}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </Grid>
  );
};

NotesContainer.propTypes = {
  searchFilter: string.isRequired,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  onItemSelected: func.isRequired,
  selectedTags: arrayOf(string).isRequired,
};

NotesContainer.defaultProps = {
  userTagData: null,
};

export default NotesContainer;
