import React from 'react';

const ListIcon = ({
  style = {},
  fill = '#000',
  width = '18',
  height = '18',
  className = '',
  viewBox = '0 -52 512 512',
}) => (
  <svg
    style={style}
    height={height}
    viewBox={viewBox}
    width={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="m0 0h113.292969v113.292969h-113.292969zm0 0"
    />
    <path
      fill={fill}
      d="m149.296875 0h362.703125v113.292969h-362.703125zm0 0"
    />
    <path
      fill={fill}
      d="m0 147.007812h113.292969v113.292969h-113.292969zm0 0"
    />
    <path
      fill={fill}
      d="m149.296875 147.007812h362.703125v113.292969h-362.703125zm0 0"
    />
    <path
      fill={fill}
      d="m0 294.011719h113.292969v113.296875h-113.292969zm0 0"
    />
    <path
      fill={fill}
      d="m149.296875 294.011719h362.703125v113.296875h-362.703125zm0 0"
    />
  </svg>
);

export default ListIcon;
