import React, { useState, useEffect } from 'react';
import {
  string,
  objectOf,
  shape,
  func,
  oneOfType,
  bool,
} from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  all: {
    flexWrap: 'nowrap',
    padding: '0.3em 0.8em',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  normal: {
    backgroundColor: theme.palette.grey[900],
  },
  icon: {
    fontSize: 20,
    marginRight: '0.5em',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
});

const Error = ({ message, classes, msgType, onClose }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = (e, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
  };

  if (message) {
    return (
      <Snackbar
        open={open}
        onClose={() => {
          handleClose();
          if (onClose) {
            onClose();
          }
        }}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        ContentProps={{
          className: `${classes.all} ${classes[msgType]}`,
        }}
        message={
          <p className={classes.message}>
            {msgType === 'error' ? (
              <ErrorOutlineSharpIcon
                className={classes.icon}
              />
            ) : null}
            <span>{message}</span>
          </p>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
  return null;
};

Error.propTypes = {
  message: oneOfType([string, bool]),
  classes: objectOf(shape),
  msgType: string,
  onClose: func,
};

Error.defaultProps = {
  message: null,
  classes: null,
  msgType: 'error',
  onClose: null,
};

export default withStyles(styles)(Error);
