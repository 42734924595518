import { gql } from 'apollo-boost';
import { PageParts, AnnotationParts } from '../fragments';

export default gql`
  query getPages(
    $bookId: uuid!
    $offset: Int
    $limit: Int
  ) {
    page(
      where: { book_id: { _eq: $bookId } }
      order_by: { book_id: asc, page_number: asc }
      offset: $offset
      limit: $limit
    ) @connection(key: "page", filter: ["where"]) {
      ...PageParts
      annotations {
        ...AnnotationParts
      }
    }
  }
  ${PageParts}
  ${AnnotationParts}
`;
