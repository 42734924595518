import variables from '../../../styles/variables.module.scss';

const styles = {
  cyanButton: {
    width: '2px',
    paddingBottom: '4px',
    paddingTop: '4px',
    backgroundColor: `${variables.colorHighlightCyan} !important`,
    '&:hover': {
      opacity: 0.5,
    },
  },
  yellowButton: {
    width: '2px',
    paddingBottom: '4px',
    paddingTop: '4px',
    backgroundColor: `${variables.colorHighlightYellow} !important`,
    '&:hover': {
      opacity: 0.5,
    },
  },
  redButton: {
    width: '2px',
    paddingBottom: '4px',
    paddingTop: '4px',
    backgroundColor: `${variables.colorHighlightRed} !important`,
    '&:hover': {
      opacity: 0.5,
    },
  },
  rightButtons: {
    width: '2px',
    paddingBottom: '4px',
    paddingTop: '4px',
    borderRadius: '4px',
    backgroundColor: variables.colorGrey,
    '&:hover': {
      backgroundColor: variables.colorGrey,
      opacity: 0.5,
    },
    alignItems: 'center',
    justifyContent: 'center',
    color: variables.colorWhite,
    minHeight: '35px',
    marginLeft: '8px',
  },
  commentIcon: {
    color: variables.colorWhite,
    width: '18px',
    height: '18px',
  },
  deleteIcon: {
    color: variables.colorWhite,
    width: '18px',
    height: '18px',
  },
  minHeightButton: {
    minHeight: '35px',
  },
  synonymButton: {
    width: '2px',
    paddingBottom: '4px',
    paddingTop: '4px',
    borderRadius: '4px',
    backgroundColor: variables.colorGrey,
    '&:hover': {
      backgroundColor: variables.colorGrey,
      opacity: 0.5,
    },
    alignItems: 'center',
    justifyContent: 'center',
    color: variables.colorWhite,
    minHeight: '35px',
  },
};

export default styles;
