import React, { useEffect, useRef, useState } from 'react';
import {
  objectOf,
  shape,
  arrayOf,
  string,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { pdfjs, Document, Page } from 'react-pdf';
import { useMutation } from '@apollo/react-hooks';
import stylesScss from './PdfReader.module.scss';
import variables from '../../../styles/variables.module.scss';
import { removeTextLayerOffset } from '../../../utils/helperFunctions';
import PageHeader from '../PageHeader';
import {
  UPDATE_UPLOAD_TITLE,
  INSERT_USER_UPLOAD_TAG,
  DELETE_USER_UPLOAD_TAG,
} from '../../../graphql/mutations';
import {
  updateCacheForUploadTag,
  optimisticUIForUploadTitle,
  optimisticUIForUploadTagAdd,
} from '../../../utils/apolloHelpers';
import { Box } from '@material-ui/core';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const styles = () => ({
  paper: {
    marginTop: '1rem',
    marginBottom: '1rem',
    backgroundColor: variables.colorWhite,
  },
  pageButton: {
    minWidth: 0,
    padding: 0,
  },
  iconProps: {
    color: variables.colorWhite,
    backgroundColor: variables.colorLightGrey,
  },
});

const PdfReader = ({
  classes,
  userTagData,
  userUpload,
}) => {
  const pdfContainerRef = useRef();

  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfRect, setPdfRect] = useState();
  const [isLandscape, setIsLandscape] = useState(true);

  const [updateTitle] = useMutation(UPDATE_UPLOAD_TITLE);
  const [addTagToItem] = useMutation(
    INSERT_USER_UPLOAD_TAG
  );
  const [deleteTagToItem] = useMutation(
    DELETE_USER_UPLOAD_TAG
  );

  useEffect(() => {
    const resize = () => {
      setPdfRect(
        pdfContainerRef.current.getBoundingClientRect()
      );
    };
    window.addEventListener('resize', resize);

    setPdfRect(
      pdfContainerRef.current.getBoundingClientRect()
    );

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const onUpdateItemTitle = (title) => {
    updateTitle({
      variables: {
        id: userUpload.upload.id,
        title,
      },
      optimisticResponse: optimisticUIForUploadTitle(
        userUpload.upload.id,
        title
      ),
    });
  };

  const onAddTagToItem = (tagId) => {
    addTagToItem({
      variables: { userUploadId: userUpload.id, tagId },
      optimisticResponse: optimisticUIForUploadTagAdd(
        userUpload.id,
        userTagData.find((e) => e.id === tagId)
      ),
      update(
        cache,
        { data: { insert_user_upload_tag: returnData } }
      ) {
        updateCacheForUploadTag(cache, returnData);
      },
    });
  };

  const onDeleteTagToItem = (id) => {
    deleteTagToItem({
      variables: { id },
      update(
        cache,
        { data: { delete_user_upload_tag: returnData } }
      ) {
        updateCacheForUploadTag(cache, returnData, false);
      },
    });
  };

  const onTagSelected = (tagId, userUploadTagId) => {
    const { user_upload_tags: userUploadTags } = userUpload;
    const tagIsMarked = userUploadTags.find(
      (e) => e.tag.id === tagId
    );
    if (tagIsMarked) {
      onDeleteTagToItem(userUploadTagId);
    } else {
      onAddTagToItem(tagId);
    }
  };

  const leftClick = () => {
    if (currentPage >= 2) {
      setCurrentPage(currentPage - 1);
    }
  };

  const rightClick = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onDocumentLoadSuccess = (i) => {
    setNumPages(i.numPages);
  };

  const renderLoadingView = () => (
    <div className={stylesScss.containerLoading}>
      <p>Läser in bok...</p>
    </div>
  );

  const getPdfWidth = () => {
    if (!isLandscape) {
      return pdfRect?.width * 0.9;
    }
  };

  const getPdfHeight = () => {
    if (isLandscape) {
      return pdfRect?.height * 0.8;
    }
  };

  const pdf = () => (
    <Box className={classes.paper}>
      <PageHeader
        userTagData={userTagData}
        onTagSelected={onTagSelected}
        onChangeItemName={onUpdateItemTitle}
        // onRemoveItem={onRemoveItem}
        tags={userUpload && userUpload.user_upload_tags}
        item={{
          title: userUpload && userUpload.upload.title,
          id: userUpload && userUpload.upload.id,
          current_page: currentPage,
          page_amount: numPages,
        }}
        moreMenuItems={[
          { title: 'Taggar' },
          { title: 'Ändra namn' },
          // { title: 'Radera' },
        ]}
      />
      {userUpload?.upload?.url && (
        <Document
          file={userUpload.upload.url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={renderLoadingView()}
        >
          <Page
            pageNumber={currentPage}
            width={getPdfWidth()}
            height={getPdfHeight()}
            onRenderSuccess={({ height, width }) => {
              setIsLandscape(width < height);
              removeTextLayerOffset();
            }}
          />
        </Document>
      )}
    </Box>
  );

  const previousButton = () => (
    <div className={stylesScss.pageButton}>
      <Button
        classes={{ root: classes.pageButton }}
        onClick={leftClick}
      >
        <ArrowLeft classes={{ root: classes.iconProps }} />
      </Button>
    </div>
  );

  const nextButton = () => (
    <div className={stylesScss.pageButton}>
      <Button
        classes={{ root: classes.pageButton }}
        onClick={rightClick}
      >
        <ArrowRight classes={{ root: classes.iconProps }} />
      </Button>
    </div>
  );

  return (
    <div
      className={stylesScss.pdfContainer}
      ref={pdfContainerRef}
    >
      <div className={stylesScss.containerInnerPdf}>
        {previousButton()}
        {pdf()}
        {nextButton()}
      </div>
    </div>
  );
};

PdfReader.propTypes = {
  classes: objectOf(shape).isRequired,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
};

PdfReader.defaultProps = {
  userTagData: null,
};

export default withStyles(styles)(PdfReader);
