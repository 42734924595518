import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spacer from '../../components/shared/Spacer';
import { INSERT_USER_BOOK } from '../../graphql/mutations';
import { GET_USER_ITEMS } from '../../graphql/queries';
import routes from '../../routers/routes';
import variables from '../../styles/variables.module.scss';

const useStyles = makeStyles(() => ({
  button: {
    color: variables.colorWhite,
  },
}));

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [book, setBook] = useState();

  const [insertUserBook] = useMutation(INSERT_USER_BOOK, {
    onCompleted: ({ insertUserBook }) => {
      setBook(insertUserBook.returning[0].book);
    },
    onError: () => {
      navigate(routes.PAYMENT_ERROR);
    },
  });

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    if (!params) return;
    const bookId = params.get('itemId');
    if (!bookId) return;

    insertUserBook({
      variables: { bookId },
      refetchQueries: [{ query: GET_USER_ITEMS }],
    });
  }, [insertUserBook]);

  return (
    <Container>
      <Spacer spacing={4} />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        {book ? (
          <>
            <Grid item xs={12}>
              <Typography align="center" variant="h4">
                Tack för ditt köp!
              </Typography>
            </Grid>
            <Spacer spacing={3} />
            {book?.cover && (
              <Grid item xs={12}>
                <img
                  width="150px"
                  src={book.cover}
                  alt={book.title ?? 'book cover'}
                />
              </Grid>
            )}
            <Spacer />
            {book?.title && (
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  <Typography
                    variant="h6"
                    component="span"
                    color="primary"
                  >
                    {book.title}
                  </Typography>{' '}
                  har nu lagts till i ditt bibliotek.
                </Typography>
              </Grid>
            )}
            <Spacer />
            <Grid item xs={12}>
              <Button
                className={classes.button}
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => navigate(routes.DASHBOARD)}
              >
                Till mitt bibliotek
              </Button>
            </Grid>
          </>
        ) : (
          <CircularProgress color="primary" size={30} />
        )}
      </Grid>
    </Container>
  );
};

export default PaymentSuccess;
