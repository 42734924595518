import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import Dashboard from '../pages/Dashboard';
import StudyDesk from '../pages/StudyDesk';
import Library from '../pages/Library';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { checkToken } from '../utils/jwtHelpers';
import ReactGA from 'react-ga';
import ProtectedRoute from './ProtectedRoute';
import PaymentError from '../pages/PaymentError';
import PaymentSuccess from '../pages/PaymentSuccess';
import routes from './routes';

ReactGA.initialize('UA-151636214-1');

const AppRouter = () => {
  useEffect(() => {
    checkToken();
  }, []);

  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route
          exact
          path={routes.LOGIN}
          element={<LoginPage />}
        />
        <Route
          path={routes.LIBRARY}
          element={<ProtectedRoute />}
        >
          <Route
            path={routes.LIBRARY}
            element={<Library />}
          />
        </Route>
        <Route
          path={routes.DASHBOARD}
          element={<ProtectedRoute />}
        >
          <Route
            path={routes.DASHBOARD}
            element={<Dashboard />}
          />
        </Route>
        <Route
          path={routes.STUDY_DESK}
          element={<ProtectedRoute />}
        >
          <Route
            path={routes.STUDY_DESK}
            element={<StudyDesk />}
          />
        </Route>
        <Route
          path={routes.PAYMENT_SUCCESS}
          element={<ProtectedRoute />}
        >
          <Route
            path={routes.PAYMENT_SUCCESS}
            element={<PaymentSuccess />}
          />
        </Route>
        <Route
          path={routes.PAYMENT_ERROR}
          element={<ProtectedRoute />}
        >
          <Route
            path={routes.PAYMENT_ERROR}
            element={<PaymentError />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
