import React, {
  useState,
  Fragment,
  useEffect,
} from 'react';
import { func, objectOf, shape } from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import variables from '../../../styles/variables.module.scss';
import { Badge } from '@material-ui/core';
import {
  getLocalStorageItem,
  saveLocalStorageItem,
  storageKeys,
} from '../../../utils/localStorage';

const styles = () => ({
  accountIcon: {
    color: variables.colorWhite,
  },
});

const ProfileButton = ({
  onLogout,
  classes,
  onToggleProfile,
}) => {
  const [anchorEl, setanchorEl] = useState(null);
  const [badgeCount, setBadgeCount] = useState(0);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const showGuide = getLocalStorageItem(
      storageKeys.GUIDE
    );
    if (!showGuide) {
      setBadgeCount(1);
    }
  }, []);

  const handleMenu = (event) =>
    setanchorEl(event.currentTarget);

  const handleClose = () => {
    setanchorEl(null);
  };

  const onChangePasswordSelected = () => {
    handleClose();
    onToggleProfile();
  };

  const onGetStartedGuideSelected = () => {
    window.open(
      'https://firebasestorage.googleapis.com/v0/b/easyeducation-f9cd0.appspot.com/o/terms_documents%2FGuide%20Easy%20Education.pdf?alt=media&token=c5d6ebb1-2263-4847-a27f-4ec1c9c57440',
      '__blank'
    );
    setBadgeCount(0);
    saveLocalStorageItem(storageKeys.GUIDE, 'done');
  };

  const handleLogout = () => {
    handleClose();
    onLogout();
  };

  return (
    <Fragment>
      <IconButton
        aria-owns={anchorEl && 'menu-appbar'}
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <Badge badgeContent={badgeCount} color="primary">
          <AccountCircle
            classes={{ root: classes.accountIcon }}
          />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={onGetStartedGuideSelected}
          style={
            badgeCount > 0
              ? { backgroundColor: variables.colorPrimary }
              : undefined
          }
        >
          Kom igång guide!
        </MenuItem>
        <MenuItem onClick={onChangePasswordSelected}>
          Byt lösenord
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logga ut</MenuItem>
      </Menu>
    </Fragment>
  );
};

ProfileButton.propTypes = {
  onLogout: func,
  onToggleProfile: func,
  classes: objectOf(shape),
};

ProfileButton.defaultProps = {
  onLogout: null,
  onToggleProfile: null,
  classes: null,
};

export default withStyles(styles)(ProfileButton);
