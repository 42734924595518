import React from 'react';
import variables from '../../../styles/variables.module.scss';

const PageThumbnailIcon = ({
  style,
  fill = variables.colorWhite,
  width = '426',
  height = '426',
  className,
  viewBox = '-42 0 426 426.66667',
}) => (
  <svg
    height={height}
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="m288.332031 0h-234.664062c-29.445313.0273438-53.308594 23.890625-53.335938 53.332031v320c.027344 29.445313 23.890625 53.308594 53.335938 53.335938h234.664062c29.445313-.027344 53.308594-23.890625 53.335938-53.335938v-320c-.027344-29.441406-23.890625-53.3046872-53.335938-53.332031zm32 373.332031c-.019531 17.667969-14.332031 31.980469-32 32h-234.664062c-17.667969-.019531-31.980469-14.332031-32-32v-320c.019531-17.664062 14.332031-31.980469 32-32h234.664062c17.667969.019531 31.980469 14.335938 32 32zm0 0"
    />
    <path
      fill={fill}
      d="m245.667969 85.332031h-149.335938c-5.890625 0-10.664062 4.777344-10.664062 10.667969s4.773437 10.667969 10.664062 10.667969h149.335938c5.890625 0 10.664062-4.777344 10.664062-10.667969s-4.773437-10.667969-10.664062-10.667969zm0 0"
    />
    <path
      fill={fill}
      d="m245.667969 149.332031h-149.335938c-5.890625 0-10.664062 4.777344-10.664062 10.667969s4.773437 10.667969 10.664062 10.667969h149.335938c5.890625 0 10.664062-4.777344 10.664062-10.667969s-4.773437-10.667969-10.664062-10.667969zm0 0"
    />
    <path
      fill={fill}
      d="m245.667969 213.332031h-149.335938c-5.890625 0-10.664062 4.777344-10.664062 10.667969s4.773437 10.667969 10.664062 10.667969h149.335938c5.890625 0 10.664062-4.777344 10.664062-10.667969s-4.773437-10.667969-10.664062-10.667969zm0 0"
    />
    <path
      fill={fill}
      d="m160.332031 277.332031h-64c-5.890625 0-10.664062 4.777344-10.664062 10.667969s4.773437 10.667969 10.664062 10.667969h64c5.890625 0 10.667969-4.777344 10.667969-10.667969s-4.777344-10.667969-10.667969-10.667969zm0 0"
    />
  </svg>
);

export default PageThumbnailIcon;
