import { gql } from 'apollo-boost';

export default gql`
  mutation updateAnnotationIsSummarized(
    $annotationIds: [uuid!]
  ) {
    update_annotation(
      where: { id: { _in: $annotationIds } }
      _set: { is_summarized: true }
    ) {
      affected_rows
    }
  }
`;
