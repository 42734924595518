export const storageKeys = Object.freeze({
  GUIDE: 'guide',
});

export const saveLocalStorageItem = (key, value = '') => {
  window.localStorage.setItem(key, value.toString());
};

export const getLocalStorageItem = (key) =>
  window.localStorage.getItem(key);
