import { gql } from 'apollo-boost';

export default gql`
  query getChapters($bookId: uuid!) {
    chapter(
      where: { book_id: { _eq: $bookId } }
      order_by: { chapter_number: asc }
    ) {
      chapter_number
      page {
        page_number
      }
      title
      id
    }
  }
`;
