import React, { useState } from 'react';
import ErrorContext from './context';
import {
  Snackbar,
  Button,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { node } from 'prop-types';
export { ErrorContext };

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.secondary,
    fontWeight: '500',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ErrorProvider = ({ children }) => {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleError = (message = 'Något gick fel...') => {
    setError(message);
  };

  const handleSuccess = (message = 'Åtgärd slutförd!') => {
    setSuccess(message);
  };

  const resetMessages = () => {
    setError(null);
    setSuccess(null);
  };

  const providerValue = {
    handleError,
    handleSuccess,
  };

  return (
    <ErrorContext.Provider value={providerValue}>
      {children}
      {(error || success) && (
        <Snackbar
          open={!!error || !!success}
          autoHideDuration={error ? 5000 : 2500}
          onClose={resetMessages}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Alert
            severity={error ? 'error' : 'success'}
            variant="filled"
            action={
              error && (
                <Button
                  size="small"
                  className={classes.button}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  uppdatera sida
                </Button>
              )
            }
          >
            {error || success}
          </Alert>
        </Snackbar>
      )}
    </ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: node,
};

export default ErrorProvider;
