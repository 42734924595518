import { gql } from 'apollo-boost';

export default gql`
  query pageSearch(
    $bookId: uuid!
    $searchString: String!
    $pageOffset: Int!
  ) {
    page(
      where: {
        book_id: { _eq: $bookId }
        _and: {
          text_content: { _ilike: $searchString }
          _and: { page_number: { _gt: $pageOffset } }
        }
      }
      order_by: { page_number: asc }
    ) {
      page_number
    }
  }
`;
