import variables from '../../../styles/variables.module.scss';

const { colorInputLabel } = variables;

const styles = () => ({
  selectMenu: {
    height: 400,
  },
  select: {
    backgroundColor: variables.colorExtraLightGrey,
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    height: 30,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    color: colorInputLabel,
  },
  containerSelect: {
    marginBottom: 14,
    marginTop: 14,
  },
});

export default styles;
