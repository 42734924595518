import { makeStyles } from '@material-ui/core';
import variables from '../../styles/variables.module.scss';

export default makeStyles(() => ({
  select: {
    minWidth: 120,
    backgroundColor: variables.colorExtraLightGrey,
    height: 50,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor: variables.colorPrimary,
  },
}));
