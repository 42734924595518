import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import {
  func,
  objectOf,
  shape,
  string,
  arrayOf,
} from 'prop-types';
import DefaultTextField from '../Inputs/DefaultTextInput';
import TextButton from '../Buttons/TextButton';
import stylesSCSS from './DashboardToolbar.module.scss';
import FilterContainer from '../Containers/FilterContainer/FilterContainer';
import variables from '../../styles/variables.module.scss';

const DashboardToolbar = ({
  userTagData,
  searchInput,
  setSearchInput,
  onTagSelected,
  classes,
  selectedTags,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setFilterOpen(false);
  };

  const handleFilterPopup = (event) => {
    setAnchorEl(event.currentTarget);
    setFilterOpen(!filterOpen);
  };

  return (
    <Grid item xs className={stylesSCSS.container}>
      <TextButton
        onClick={handleFilterPopup}
        className={classes.buttonFilter}
      >
        Filtrera
        <KeyboardArrowDown
          classes={{ root: classes.arrownDown }}
        />
      </TextButton>
      <Popover
        open={filterOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FilterContainer
          userTagData={userTagData}
          onTagSelected={onTagSelected}
          selectedTags={selectedTags}
        />
      </Popover>
      <DefaultTextField
        label="Sök"
        type="search"
        name="search"
        onChange={setSearchInput}
        value={searchInput}
        icon={<SearchIcon className={classes.searchIcon} />}
        InputProps={{ classes: { root: classes.input } }}
      />
    </Grid>
  );
};

const styles = () => ({
  searchIcon: {
    fontSize: 14,
    color: variables.colorPrimary,
  },
  buttonFilter: {
    padding: '4px 4px 4px 20px',
    letterSpacing: '0.5px',
    textTransform: 'none',
    margin: 8,
    color: variables.colorGrey,
    backgroundColor: variables.colorExtraLightGrey,
  },
  arrownDown: {
    fontSize: 18,
    color: variables.colorGrey,
    marginLeft: 8,
  },
  input: {
    height: 40,
  },
});

DashboardToolbar.propTypes = {
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  setSearchInput: func.isRequired,
  searchInput: string.isRequired,
  onTagSelected: func.isRequired,
  classes: objectOf(shape),
  selectedTags: arrayOf(string).isRequired,
};

DashboardToolbar.defaultProps = {
  classes: null,
  userTagData: null,
};

export default withStyles(styles)(DashboardToolbar);
