import React from 'react';
import {
  arrayOf,
  shape,
  string,
  func,
  number,
  instanceOf,
  oneOfType,
} from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import MoreMenu from '../../MoreMenu';
import styles from './PageHeader.module.scss';
import { formatDateWithSeconds } from '../../../utils/dateHelper';

const PageHeader = ({
  userTagData,
  tags,
  item,
  onTagSelected,
  onRemoveItem,
  onChangeItemName,
  moreMenuItems,
  saveNotes,
}) => (
  <div className={styles.container}>
    <div className={styles.containerTitle}>
      <p className={styles.bookTitle}>
        <b>{item.title}</b>
        {' - '}
        {item.opened
          ? `Senast ändrad: ${formatDateWithSeconds(
              item.opened
            )}`
          : item.author}
      </p>
      {item.current_page && (
        <p className={styles.bookTitle}>
          {' sida '}
          <b>{item.current_page}</b>
          {' av '}
          <b>{item.page_amount}</b>
        </p>
      )}
    </div>
    <div className={styles.containerIcons}>
      {saveNotes && (
        <SaveIcon
          onClick={() => saveNotes()}
          className={styles.iconSave}
        />
      )}
      <div className={styles.moreIcon}>
        <MoreMenu
          data={moreMenuItems}
          itemTags={tags}
          userTagData={userTagData}
          onTagSelected={onTagSelected}
          onRemoveItem={onRemoveItem}
          onChangeItemName={onChangeItemName}
          item={item}
        />
      </div>
    </div>
  </div>
);

PageHeader.propTypes = {
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  onTagSelected: func.isRequired,
  onRemoveItem: func,
  onChangeItemName: func,
  moreMenuItems: arrayOf(
    shape({
      title: string,
    })
  ),
  saveNotes: func,
  item: shape({
    title: string,
    id: string,
    current_page: number,
    page_amount: number,
    opened: oneOfType([string, instanceOf(Date)]),
    author: string,
  }).isRequired,
};

PageHeader.defaultProps = {
  userTagData: null,
  onRemoveItem: null,
  onChangeItemName: null,
  saveNotes: null,
  moreMenuItems: [{ title: 'Taggar' }],
};

export default PageHeader;
