import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  select: {
    minWidth: 120,
    height: 50,
    border: `1px solid ${theme.colors.colorExtraLightGrey}`,
    padding: theme.spacing(1),
  },
  containerCurrent: {
    display: 'flex',
  },
  nameTypo: {
    color: theme.colors.colorExtraLightGrey,
  },
  iconArrow: {
    fill: theme.colors.colorExtraLightGrey,
  },
  iconArrowDisabled: {
    fill: 'transparent',
  },
}));
