import React from 'react';

const GridIcon = ({
  style = {},
  fill = '#000',
  width = '18',
  height = '18',
  className = '',
  viewBox = '0 0 444 444',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="m7 0h181c3.867188 0 7 3.132812 7 7v181c0 3.867188-3.132812 7-7 7h-181c-3.867188 0-7-3.132812-7-7v-181c0-3.867188 3.132812-7 7-7zm0 0"
    />
    <path
      fill={fill}
      d="m256 0h181c3.867188 0 7 3.132812 7 7v181c0 3.867188-3.132812 7-7 7h-181c-3.867188 0-7-3.132812-7-7v-181c0-3.867188 3.132812-7 7-7zm0 0"
    />
    <path
      fill={fill}
      d="m7 249h181c3.867188 0 7 3.132812 7 7v181c0 3.867188-3.132812 7-7 7h-181c-3.867188 0-7-3.132812-7-7v-181c0-3.867188 3.132812-7 7-7zm0 0"
    />
    <path
      fill={fill}
      d="m256 249h181c3.867188 0 7 3.132812 7 7v181c0 3.867188-3.132812 7-7 7h-181c-3.867188 0-7-3.132812-7-7v-181c0-3.867188 3.132812-7 7-7zm0 0"
    />
  </svg>
);

export default GridIcon;
