import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Spacer from '../../components/shared/Spacer';
import routes from '../../routers/routes';
import variables from '../../styles/variables.module.scss';

const useStyles = makeStyles(() => ({
  button: {
    color: variables.colorWhite,
  },
}));

const PaymentError = () => {
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <Container>
      <Spacer spacing={4} />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="h4">
            Ditt köp gick inte igenom...
          </Typography>
          <Spacer />
          <Typography>
            Vänligen försök igen eller kontakta oss på{' '}
            <a href="mailto:kundservice@easyeducaiton.se">
              kundservice@easyeducaiton.se
            </a>
          </Typography>
          <Spacer />
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => navigate(routes.LIBRARY)}
          >
            Till det globala arkivet
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentError;
