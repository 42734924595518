import styles from '../components/Desk/BookTool/BookTool.module.scss';

const { cyan, yellow, red, comment } = styles;

export const BOOK = 'BOOK';
export const OTHER = 'OTHER';
export const UPLOAD = 'UPLOAD';
export const NOTE = 'NOTE';
export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';
export const LIBRARY = 'LIBRARY';
export const CHANGETITLE = 'Ändra namn';
export const ADDTAG = 'Taggar';
export const REMOVEITEM = 'Radera';
export const ALL = 'ALL';
export const CYAN = cyan;
export const YELLOW = yellow;
export const RED = red;
export const COMMENT = comment;

export const USER_TYPE = Object.freeze({
  FREE: 'FREE',
  ORGANISATION_FREE: 'ORGANISATION_FREE',
  ORGANISATION_PAID: 'ORGANISATION_PAID',
});

export const BOOK_TYPE = Object.freeze({
  BUY: 'BUY',
  RENTAL: 'RENTAL',
});
