import { gql } from 'apollo-boost';
import NoteParts from './note';
import TagParts from './tag';

export default gql`
  fragment UserNoteParts on user_note {
    id
    note {
      ...NoteParts
    }
    user_note_tags {
      id
      tag {
        ...TagParts
      }
    }
  }
  ${NoteParts}, ${TagParts}
`;
