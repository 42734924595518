import { gql } from 'apollo-boost';

export default gql`
  mutation deleteAnnotation($elementId: String!) {
    delete_annotation(
      where: { element_id: { _eq: $elementId } }
    ) {
      returning {
        id
        class_name
      }
    }
  }
`;
