import React from 'react';
import variables from '../../../styles/variables.module.scss';

const NoteIcon = ({
  style,
  fill = variables.colorGrey,
  width = '64',
  height = '64',
  className,
  viewBox = '0 0 64 64',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="noteIcon"
    data-name="noteIcon"
    viewBox={viewBox}
    width={width}
    height={height}
    style={style}
    className={className}
  >
    <g>
      <path
        d="M6.5,59.5h38A1.5,1.5,0,0,0,46,58V6a1.5,1.5,0,0,0-1.5-1.5H6.5A1.5,1.5,0,0,0,5,6V58A1.5,1.5,0,0,0,6.5,59.5Zm36.5-3H8v-2H43ZM8,7.5H43v44H16V12a1.5,1.5,0,0,0-3,0V51.5H8Z"
        data-original="#000000"
        className="active-path"
        data-old_color="#000000"
        fill={fill}
      />
      <path
        d="M21.5,28.5h16A1.5,1.5,0,0,0,39,27V17a1.5,1.5,0,0,0-1.5-1.5h-16A1.5,1.5,0,0,0,20,17V27A1.5,1.5,0,0,0,21.5,28.5Zm1.5-10H36v7H23Z"
        data-original="#000000"
        className="active-path"
        data-old_color="#000000"
        fill={fill}
      />
      <path
        d="M52.77,58.86a1.5,1.5,0,0,0,2.46,0l3.5-5A1.46,1.46,0,0,0,59,53V25a1.5,1.5,0,0,0-1.5-1.5h-7A1.5,1.5,0,0,0,49,25V53a1.46,1.46,0,0,0,.27.86ZM54,55.38l-2-2.85v-20h4v20ZM56,26.5v3H52v-3Z"
        data-original="#000000"
        className="active-path"
        data-old_color="#000000"
        fill={fill}
      />
    </g>
  </svg>
);

export default NoteIcon;
