import { gql } from 'apollo-boost';

export default gql`
  mutation updateComment(
    $elementId: String!
    $content: String!
  ) {
    update_comment(
      where: { element_id: { _eq: $elementId } }
      _set: { content: $content }
    ) {
      returning {
        content
        element_id
        id
      }
    }
  }
`;
