import React, { useEffect, useMemo, useState } from 'react';
import {
  string,
  objectOf,
  shape,
  func,
  bool,
} from 'prop-types';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import variables from '../../../styles/variables.module.scss';
import FastLibraryMenu from '../FastLibraryMenu';
import {
  BOOK,
  LEFT,
  NOTE,
  UPLOAD,
} from '../../../utils/constants';
import Loading from '../../Helpers/Loading';
import LibraryGridItem from '../../Items/LibraryGridItem';
import FastLibraryListItem from '../../Items/FastLibraryListItem';
import useError from '../../../hooks/useError';
import { getUserIdFromCookie } from '../../../utils/jwtHelpers';
import { fileUpload } from '../../../utils/backendHelper';
import useStudyDeskContext from '../../../hooks/useStudyDeskContext';

const MAX_CATEGORIES = 3;

const filterMenuItems = [
  { name: 'ALLT', type: undefined },
  { name: 'BÖCKER', type: BOOK },
  { name: 'ANTECKNINGAR', type: NOTE },
  { name: 'ÖVRIGT MATERIAL', type: UPLOAD },
];

const styles = () => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    backgroundColor: variables.colorExtraLightGrey,
  },
  list: {
    overflow: 'scroll',
  },
  emptyPlaceholderContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingLeft: 16,
  },
  emptyPlaceholderText: {
    color: variables.colorDarkGrey,
    fontWeight: 400,
  },
  paper: {
    display: 'flex',
    flex: 1,
    backgroundColor: variables.colorWhite,
    marginTop: '0.5em',
    marginBottom: '0.5em',
    flexDirection: 'column',
    overflow: 'hidden',
    marginRight: '24px',
    marginLeft: '24px',
  },
  subHeader: {
    color: variables.colorPrimary,
    fontSize: '1.2em',
    position: 'inherit',
  },
});

const FastLibrary = ({
  paneSide,
  pickedFromFastLib,
  classes,
  items,
  loading,
  addNote,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = useState('');
  const [isGridView, setIsGridView] = useState(false);
  const [currentList, setCurrentList] = useState([]);
  const { handleError } = useError();

  const { updatePane, leftPane, rightPane } =
    useStudyDeskContext();

  const { bookList, noteList, uploadList } = useMemo(
    () => ({
      bookList: items?.userBooks.filter(
        (item) =>
          !searchText ||
          item.book?.title
            .toLowerCase()
            .includes(searchText)
      ),
      noteList: items?.userNotes.filter(
        (item) =>
          !searchText ||
          item.note?.title
            .toLowerCase()
            .includes(searchText)
      ),
      uploadList: items?.userUploads.filter(
        (item) =>
          !searchText ||
          item.upload?.title
            .toLowerCase()
            .includes(searchText)
      ),
    }),
    [searchText, items]
  );

  useEffect(() => {
    if (
      currentList.length === 0 ||
      currentList.length === MAX_CATEGORIES
    ) {
      handleFilterItemClick();
      return;
    }
    handleFilterItemClick(currentList[0].type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, items]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const handleToggleLayout = (isGridView = false) => {
    setIsGridView(isGridView);
  };

  const handleUploadFile = async (file) => {
    const userId = getUserIdFromCookie();
    const newUpload = await fileUpload(
      file,
      userId,
      () => {},
      handleError
    );
    if (!newUpload) return;
    updatePane(paneSide, {
      type: UPLOAD,
      content: newUpload,
    });
  };

  const emptyPlaceholder = (text) => (
    <div className={classes.emptyPlaceholderContainer}>
      <h3 className={classes.emptyPlaceholderText}>
        {text}
      </h3>
    </div>
  );

  const handleFilterItemClick = (category) => {
    switch (category) {
      case BOOK:
        setCurrentList([
          {
            name: filterMenuItems[1].name,
            list: bookList,
            type: filterMenuItems[1].type,
          },
        ]);
        break;
      case NOTE:
        setCurrentList([
          {
            name: filterMenuItems[2].name,
            list: noteList,
            type: filterMenuItems[2].type,
          },
        ]);
        break;
      case UPLOAD:
        setCurrentList([
          {
            name: filterMenuItems[3].name,
            list: uploadList,
            type: filterMenuItems[3].type,
          },
        ]);
        break;
      default:
        setCurrentList([
          {
            name: filterMenuItems[1].name,
            list: bookList,
            type: filterMenuItems[1].type,
          },
          {
            name: filterMenuItems[2].name,
            list: noteList,
            type: filterMenuItems[2].type,
          },
          {
            name: filterMenuItems[3].name,
            list: uploadList,
            type: filterMenuItems[3].type,
          },
        ]);
        break;
    }
    handleClose();
  };

  const getItemKey = (category) => {
    switch (category) {
      case BOOK:
        return 'book';
      case NOTE:
        return 'note';
      case UPLOAD:
        return 'upload';
      default:
        return '';
    }
  };

  const isBooksSectionDisabled = useMemo(() => {
    const otherPaneSide =
      paneSide === LEFT ? rightPane : leftPane;

    return otherPaneSide?.type === BOOK;
  }, [paneSide, leftPane, rightPane]);

  const Wrapper = (props) =>
    isGridView ? (
      <Grid container direction="row" {...props} />
    ) : (
      <React.Fragment {...props} />
    );

  const renderListContent = (
    list,
    type,
    name,
    disabled
  ) => {
    if (disabled) {
      return emptyPlaceholder(
        `Kan inte ha uppe 2 böcker samtidigt...`
      );
    }
    if (!list && list.length === 0) {
      return emptyPlaceholder(
        `Inga ${name?.toLowerCase()} hittades`
      );
    }
    return (
      <Wrapper>
        {list.map((item) => {
          const itemData = item[getItemKey(type)];

          return isGridView ? (
            <LibraryGridItem
              key={item.id}
              item={itemData}
              type={type}
              onPress={() =>
                pickedFromFastLib(item.id, paneSide, type)
              }
            />
          ) : (
            <FastLibraryListItem
              key={item.id}
              item={item}
              itemData={itemData}
              type={type}
              onClick={() =>
                pickedFromFastLib(item.id, paneSide, type)
              }
            />
          );
        })}
      </Wrapper>
    );
  };

  return (
    <div className={classes.container}>
      <Paper classes={{ root: classes.paper }}>
        <Loading loading={loading}>
          <FastLibraryMenu
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            setSearchText={setSearchText}
            buttonText={
              currentList.length === 1
                ? currentList[0].name
                : 'ALLT'
            }
            paneSide={paneSide}
            addNote={addNote}
            onToggleLayout={handleToggleLayout}
            onFilterListItemeClick={handleFilterItemClick}
            filterMenuItems={filterMenuItems}
            onUploadFile={handleUploadFile}
          />
          <div className={classes.list}>
            {currentList.map(
              ({ list, type, name, disabled }) => (
                <List
                  key={name}
                  subheader={
                    <ListSubheader
                      classes={{ root: classes.subHeader }}
                    >
                      {name}
                    </ListSubheader>
                  }
                >
                  {renderListContent(
                    list,
                    type,
                    name,
                    type === BOOK
                      ? isBooksSectionDisabled
                      : false
                  )}
                </List>
              )
            )}
          </div>
        </Loading>
      </Paper>
    </div>
  );
};

FastLibrary.propTypes = {
  classes: objectOf(shape),
  paneSide: string,
  pickedFromFastLib: func.isRequired,
  loading: bool,
  addNote: func,
  items: objectOf(shape),
};

FastLibrary.defaultProps = {
  classes: null,
  paneSide: null,
  loading: false,
  addNote: null,
  items: undefined,
};

export default withStyles(styles)(FastLibrary);
