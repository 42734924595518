import React from 'react';
import Chip from '@material-ui/core/Chip';
import { string, func, arrayOf, shape } from 'prop-types';
import stylesScss from './AddTagContainer.module.scss';

const AddTagContainer = ({
  userTagData,
  onTagSelected,
  itemTags,
}) => (
  <div className={stylesScss.innerContainer}>
    <div>
      {userTagData.map(({ id, name }) => {
        const tagFound = itemTags.find(
          (e) => e.tag.id === id
        );
        return (
          <Chip
            key={id}
            label={name}
            style={{ margin: 4 }}
            variant={tagFound ? 'default' : 'outlined'}
            color={tagFound ? 'primary' : 'default'}
            onClick={() => {
              onTagSelected(id, tagFound && tagFound.id);
            }}
          />
        );
      })}
    </div>
  </div>
);

AddTagContainer.propTypes = {
  itemTags: arrayOf(
    shape({
      id: string,
      tag: shape({
        id: string,
        name: string,
      }),
    })
  ).isRequired,
  onTagSelected: func.isRequired,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ).isRequired,
};

export default AddTagContainer;
