import React from 'react';
import {
  arrayOf,
  object,
  func,
  instanceOf,
} from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIconMUI from '@material-ui/icons/MoreHoriz';
import styles from './MoreButton.module.scss';

const MoreButton = ({
  data,
  handleMenuItemClick,
  anchorEl,
  handleClick,
  handleClose,
}) => (
  <div className={styles.iconMore}>
    <MoreIconMUI onClick={handleClick} />
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {data.map((item) => (
        <MenuItem
          key={item.title}
          onClick={(event) =>
            handleMenuItemClick(item, event)
          }
        >
          {item.title}
        </MenuItem>
      ))}
    </Menu>
  </div>
);

MoreButton.propTypes = {
  data: arrayOf(object),
  handleMenuItemClick: func.isRequired,
  handleClick: func.isRequired,
  handleClose: func.isRequired,
  anchorEl: instanceOf(Element),
};

MoreButton.defaultProps = {
  data: [{ title: 'Example' }],
  anchorEl: null,
};

export default MoreButton;
