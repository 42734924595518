import { gql } from 'apollo-boost';
import BookParts from './book';
import TagParts from './tag';

export default gql`
  fragment UserBookParts on user_book {
    id
    current_page
    book {
      ...BookParts
    }
    user_book_tags {
      id
      tag {
        ...TagParts
      }
    }
  }
  ${BookParts}, ${TagParts}
`;
