import { gql } from 'apollo-boost';
import UploadParts from './upload';
import TagParts from './tag';

export default gql`
  fragment UserUploadParts on user_upload {
    id
    upload {
      ...UploadParts
    }
    user_upload_tags {
      id
      tag {
        ...TagParts
      }
    }
  }
  ${UploadParts}, ${TagParts}
`;
