import variables from '../../../styles/variables.module.scss';

const styles = (theme) => ({
  root: {
    width: '90%',
    overflow: 'auto',
    maxHeight: '88vh',
    margin: 'auto',
    marginTop: 20,
  },
  paperRoot: {
    ...theme.mixins.gutters(),
    flexGrow: 1,
    height: '88vh',
  },
  header: {
    color: variables.colorPrimary,
    fontWeight: 400,
    marginLeft: '1em',
    marginBottom: '10px',
    marginBlockStart: '2em',
  },
  iconProps: {
    color: variables.colorExtraLightGrey,
    fontSize: 10,
    fill: variables.colorExtraLightGrey,
  },
  searchInput: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    margin: 8,
  },
  iconButton: {
    padding: 10,
    fontSize: '5px',
  },
  searchIcon: {
    fontSize: '14px',
    color: variables.colorPrimary,
  },
  categoryButton: {
    color: variables.colorPrimary,
    fontSize: 10,
    boxShadow: 'none',
    margin: 4,
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  addNoteButton: {
    color: variables.colorPrimary,
    fontSize: 10,
    boxShadow: 'none',
    paddingTop: '2px',
    paddingBottom: '0px',
    lineHeight: '0px',
    maxWidth: '104px',
    margin: 4,
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  addNoteIcon: {
    paddingTop: 6,
    marginLeft: 10,
  },
  addDocButton: {
    color: variables.colorPrimary,
    fontSize: 10,
    boxShadow: 'none',
    paddingTop: '6px',
    paddingBottom: '6px',
    lineHeight: '0px',
    minWidth: '96px',
    margin: 4,
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  addDocIcon: {
    paddingTop: 0,
    marginLeft: 10,
  },
  leftIcon: {
    marginRight: 10,
  },
  rightIcon: {
    marginLeft: 30,
  },
  listItemText: {
    fontSize: '0.65em',
  },
  menuToggleContainer: {
    color: 'blue',
  },
  materialContainer: {
    overflow: 'hidden',
    height: '100vh',
  },
  subHeader: {
    color: variables.colorPrimary,
    fontSize: '1.2em',
  },
  list: {
    height: '88vh',
    overflow: 'scroll',
  },
  container: {
    marginTop: '0.5em',
    maxHeight: '98%',
    overflow: 'hidden',
  },
  toggleGroup: {
    display: 'initial',
    margin: '8px',
  },
  toggleButton: {
    height: '36px',
  },
});

export default styles;
