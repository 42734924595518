import variables from '../../../styles/variables.module.scss';

const {
  colorExtraLightGrey,
  colorPrimary,
  colorDarkNav,
  colorInputLabel,
} = variables;

const styles = () => ({
  searchInput: {
    border: `1px solid ${colorExtraLightGrey}`,
    borderRadius: 4,
    marginBottom: 14,
    fontSize: '18px',
  },
  input: {
    height: 40,
  },
  iconButton: {
    padding: 10,
    fontSize: '5px',
  },
  searchIcon: {
    fontSize: 14,
    color: colorPrimary,
  },
  leftButton: {
    color: colorDarkNav,
    backgroundColor: colorExtraLightGrey,
    width: '50%',
    marginRight: 14,
    textTransform: 'none',
    boxShadow: 'none',
  },
  rightButton: {
    color: colorDarkNav,
    backgroundColor: colorExtraLightGrey,
    width: '50%',
    textTransform: 'none',
    boxShadow: 'none',
  },
  lastButton: {
    color: colorDarkNav,
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: colorExtraLightGrey,
    width: '100%',
  },
  icon: {
    fontSize: 16,
  },
  loading: {
    marginTop: '40px',
  },
  buttonSearch: {
    marginTop: 8,
    width: '100%',
  },
  textFieldSearch: {
    width: '100%',
  },
  containerNavActions: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
    justifyContent: 'space-between',
  },
  containerSearchNav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 14,
    color: colorInputLabel,
  },
});

export default styles;
