import React, { useState, useEffect, useRef } from 'react';
import {
  objectOf,
  string,
  shape,
  arrayOf,
} from 'prop-types';
import ReactQuill from 'react-quill';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import './QuillEditor.module.scss';
import { useQuery, useMutation } from '@apollo/react-hooks';
import stylesScss from './TextEditor.module.scss';
import PageHeader from '../PageHeader';
import { GET_NOTE_CONTENT } from '../../../graphql/queries';
import {
  UPDATE_NOTE_CONTENT,
  UPDATE_NOTE_TITLE,
  INSERT_USER_NOTE_TAG,
  DELETE_USER_NOTE_TAG,
} from '../../../graphql/mutations';
import Loading from '../../Helpers/Loading';
import Error from '../../Helpers/Error';
import {
  updateCacheForNoteTag,
  updateCacheForNoteContent,
  optimisticUIForNoteTitle,
  optimisticUIForNoteTagAdd,
} from '../../../utils/apolloHelpers';

// const Font = Quill.import('formats/font');
// Font.whitelist = ['Arial', 'Helvetica', 'Menlo', 'Georgia'];
// Quill.register(Font, true);

const styles = () => ({
  paper: {
    display: 'flex',
    flex: 1,
    marginTop: '0.5em',
    marginBottom: '0.5em',
    flexDirection: 'column',
    overflow: 'hidden',
    marginRight: '24px',
    marginLeft: '24px',
    maxWidth: '750px',
  },
});

const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'background',
];

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // [{ font: Font.whitelist }],
    ['bold', 'italic', 'underline'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    // ['link'],
    [{ color: [] }],
    [{ background: [] }],
  ],
};

const TextEditor = ({
  classes,
  userTagData,
  userNote,
  summarizedContent,
}) => {
  const [currentNoteContent, setCurrentNoteContent] =
    useState('');
  const [noteOpened, setNoteOpened] = useState(null);
  const [currentTimeout, setCurrentTimeout] =
    useState(null);
  const timeoutRef = useRef(currentTimeout);

  const onUpdateContentCompleted = (newData) => {
    const { data } = newData.user_note[0].note;
    if (summarizedContent || data) {
      setCurrentNoteContent(summarizedContent || data);
    }
  };

  const { loading, error } = useQuery(GET_NOTE_CONTENT, {
    variables: { id: userNote.id },
    onCompleted: (data) => {
      onUpdateContentCompleted(data);
    },
  });

  const [updateNoteContent] = useMutation(
    UPDATE_NOTE_CONTENT
  );
  const [updateTitle] = useMutation(UPDATE_NOTE_TITLE);
  const [addTagToItem] = useMutation(INSERT_USER_NOTE_TAG);
  const [deleteTagToItem] = useMutation(
    DELETE_USER_NOTE_TAG
  );

  const onUpdateTitle = (title) => {
    updateTitle({
      variables: {
        id: userNote.note.id,
        title,
      },
      optimisticResponse: optimisticUIForNoteTitle(
        userNote.note.id,
        title
      ),
    });
  };

  const onAddTagToItem = (tagId) => {
    addTagToItem({
      variables: { userNoteId: userNote.id, tagId },
      optimisticResponse: optimisticUIForNoteTagAdd(
        userNote.id,
        userTagData.find((e) => e.id === tagId)
      ),
      update(
        cache,
        { data: { insert_user_note_tag: returnData } }
      ) {
        updateCacheForNoteTag(cache, returnData);
      },
    });
  };

  const onDeleteTagToItem = (id) => {
    deleteTagToItem({
      variables: { id },
      update(
        cache,
        { data: { delete_user_note_tag: returnData } }
      ) {
        updateCacheForNoteTag(cache, returnData, false);
      },
    });
  };

  const onTagSelected = (tagId, userNoteTagId) => {
    const { user_note_tags: userNoteTags } = userNote;
    const tagIsMarked = userNoteTags.find(
      (e) => e.tag.id === tagId
    );
    if (tagIsMarked) {
      onDeleteTagToItem(userNoteTagId);
    } else {
      onAddTagToItem(tagId);
    }
  };

  useEffect(() => {
    if (summarizedContent) {
      setCurrentNoteContent((prevState) =>
        prevState && prevState !== '<p><br></p>'
          ? `${prevState}</br>${summarizedContent}`
          : summarizedContent
      );
    }
  }, [summarizedContent]);

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  const resetTimeout = () => {
    clearTimeout(currentTimeout);
    setCurrentTimeout(null);
  };

  const saveNotes = (value) => {
    const newDate = new Date();
    updateNoteContent({
      variables: {
        noteId: userNote.note.id,
        content: value,
        opened: newDate,
      },
      update(cache, { data: { update_note: returnData } }) {
        updateCacheForNoteContent(
          cache,
          returnData,
          userNote.id
        );
      },
    });
    setNoteOpened(newDate);
    resetTimeout();
  };

  const onNoteChange = (value) => {
    if (
      typeof currentNoteContent === 'string' &&
      currentNoteContent !== value
    ) {
      setCurrentNoteContent(value);
      if (currentTimeout) {
        resetTimeout();
      }
      const newId = setTimeout(() => {
        saveNotes(value);
      }, 3000);
      timeoutRef.current = newId;
      setCurrentTimeout(newId);
    }
  };
  return (
    <div className={stylesScss.container}>
      <Error message={error && error.message} />
      <Paper classes={{ root: classes.paper }}>
        <Loading loading={loading}>
          <PageHeader
            userTagData={userTagData}
            tags={userNote && userNote.user_note_tags}
            item={{
              title: userNote && userNote.note.title,
              opened: noteOpened || userNote.note.opened,
              id: userNote && userNote.note.id,
            }}
            onTagSelected={onTagSelected}
            onChangeItemName={onUpdateTitle}
            // onRemoveItem={onRemoveItem}
            // saveNotes={saveNotes}
            moreMenuItems={[
              { title: 'Taggar' },
              { title: 'Ändra namn' },
              // { title: 'Radera' },
            ]}
          />
          <ReactQuill
            className={stylesScss.quillContainer}
            value={currentNoteContent}
            onChange={onNoteChange}
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder="Börja anteckna..."
          />
        </Loading>
      </Paper>
    </div>
  );
};

TextEditor.propTypes = {
  classes: objectOf(shape).isRequired,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  summarizedContent: string,
};

TextEditor.defaultProps = {
  userTagData: null,
  summarizedContent: null,
};

export default withStyles(styles)(TextEditor);
