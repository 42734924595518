import { Navigate, Outlet } from 'react-router-dom';
import { getUserIdFromCookie } from '../utils/jwtHelpers';

const ProtectedRoute = () =>
  getUserIdFromCookie() ? (
    <Outlet />
  ) : (
    <Navigate replace to="/" />
  );

export default ProtectedRoute;
