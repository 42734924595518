import { gql } from 'apollo-boost';
import { UserNoteParts } from '../fragments';

export default gql`
  mutation insert_note {
    insert_user_note(
      objects: {
        note: { data: { title: "Namnlöst dokument" } }
      }
    ) {
      returning {
        ...UserNoteParts
      }
    }
  }
  ${UserNoteParts}
`;
