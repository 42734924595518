import { createRoot } from 'react-dom/client';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import AppRouter from './routers/AppRouter';
import './styles/base.module.scss';
import muiTheme from './styles/muiTheme';
import client from './apollo/client';
import ErrorProvider from './contexts/ErrorContext';
import OrganisationProvider from './contexts/OrganisationContext';
import SessionContextProvider from './contexts/SessionContext';
import StudyDeskContextProvider from './contexts/StudyDeskContext';

const container = document.getElementById('root');
const root = createRoot(container);

const theme = createTheme(muiTheme);

root.render(
  <MuiThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <OrganisationProvider>
        <ErrorProvider>
          <SessionContextProvider>
            <StudyDeskContextProvider>
              <AppRouter />
            </StudyDeskContextProvider>
          </SessionContextProvider>
        </ErrorProvider>
      </OrganisationProvider>
    </ApolloProvider>
  </MuiThemeProvider>
);
