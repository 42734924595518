import React, { useMemo, useState, useEffect } from 'react';
import { objectOf, shape, oneOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Drag from '@material-ui/icons/DragIndicator';
import Bulleted from '@material-ui/icons/FormatListBulleted';
import MenuItem from '@material-ui/core/MenuItem';
import {
  useLazyQuery,
  useQuery,
} from '@apollo/react-hooks';
import stylesScss from './FastBookNav.module.scss';
import ThumbnailList from '../ThumbnailList';
import TooltipButton from '../../Buttons/TooltipButton';
import DefaultTextField from '../../Inputs/DefaultTextInput';
import Loading from '../../Helpers/Loading';
import TextButton from '../../Buttons/TextButton';
import {
  GET_PAGES_BY_TEXT_SEARCH,
  GET_CHAPTERS,
} from '../../../graphql/queries';
import styles from './styles';
import FastNavChaptersSection from '../FastNavChaptersSection';
import FastNavDropdown from '../FastNavDropdown';
import useStudyDeskContext from '../../../hooks/useStudyDeskContext';
import useError from '../../../hooks/useError';
import { LEFT, RIGHT } from '../../../utils/constants';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { Box, Typography } from '@material-ui/core';

const INDEX_PAGE_KEY = 'index_page';
const CONTENT_PAGE_KEY = 'table_of_content_page';

const FastBookNav = ({ classes, paneSide }) => {
  const {
    onUpdateCurrentPage,
    leftPane,
    rightPane,
    onUpdateSearchData,
    currentSearchData,
  } = useStudyDeskContext();

  const {
    searchValue: currentSearchString,
    pages: searchDataPages,
  } = currentSearchData ?? {};

  const { handleError } = useError();

  const {
    numberOfPages,
    bookId,
    startPageOffset,
    currentPage,
  } = useMemo(() => {
    if (!paneSide) return {};

    const paneContent =
      paneSide === LEFT
        ? leftPane.content
        : rightPane.content;

    return {
      currentPage: paneContent.current_page,
      numberOfPages: paneContent.book.page_amount,
      bookId: paneContent.book.id,
      startPageOffset: paneContent.book.start_page_offset,
    };
  }, [paneSide, leftPane, rightPane]);

  const [valueSearch, setValueSearch] = useState(
    currentSearchString ?? ''
  );

  const onGetPagesCompleted = (data) => {
    onUpdateSearchData('pages', data.page);
    if (data?.page?.length === 0) return;
    onUpdateSearchData(
      'searchValue',
      valueSearch || currentSearchString
    );
    if (!valueSearch) {
      setValueSearch(currentSearchString);
    }
  };
  const [getPages, { loading: searchLoading }] =
    useLazyQuery(GET_PAGES_BY_TEXT_SEARCH, {
      fetchPolicy: 'cache-and-network',
      variables: { bookId, pageOffset: startPageOffset },
      onError: (error) => {
        onUpdateSearchData('pages', undefined);
        handleError(error.message);
      },
      onCompleted: onGetPagesCompleted,
    });

  const { data: chapterData } = useQuery(GET_CHAPTERS, {
    variables: { bookId },
  });

  useEffect(() => {
    if (
      !currentSearchString ||
      currentSearchString === -1 ||
      searchDataPages ||
      valueSearch
    )
      return;
    getPages({
      variables: {
        searchString: `%${currentSearchString}%`,
      },
    });
  }, [
    currentSearchString,
    searchDataPages,
    getPages,
    valueSearch,
  ]);

  const handleOnSearchClicked = () => {
    if (!isSearchValid) return;

    getPages({
      variables: { searchString: `%${valueSearch}%` },
    });
  };

  const handleNavToPage = (pageNumber) => {
    onUpdateCurrentPage(paneSide, pageNumber);
  };

  const handleNavToType = (key) => {
    const pageNumber =
      paneSide === LEFT
        ? leftPane.content.book[key]
        : rightPane.content.book[key];
    onUpdateCurrentPage(
      paneSide,
      pageNumber ? parseInt(pageNumber) : 1
    );
  };

  const handleSearchChange = ({ target }) => {
    setValueSearch(target.value);
    if (!target.value) {
      onUpdateSearchData('pages', undefined);
      onUpdateSearchData('searchValue', '');
    }
  };

  const isSearchValid =
    valueSearch?.length > 1 && !searchLoading;

  const handleSearchNavBtnClick = (isPrev) => {
    const index = searchDataPages?.findIndex(
      ({ page_number: pageNumber }) =>
        pageNumber === currentPage
    );
    if (
      (index === 0 && isPrev) ||
      (index === searchDataPages?.length - 1 && !isPrev)
    ) {
      return;
    }

    let newIndex = index;
    if (isPrev) {
      newIndex -= 1;
    } else {
      newIndex += 1;
    }

    onUpdateCurrentPage(
      paneSide,
      searchDataPages[newIndex].page_number
    );
  };

  const buttonsContainer = () => (
    <div className={stylesScss.buttonsContainer}>
      <DefaultTextField
        label="Sök i boken"
        type="search"
        name="search"
        value={valueSearch}
        onChange={handleSearchChange}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleOnSearchClicked();
          }
        }}
        className={classes.textFieldSearch}
        icon={<SearchIcon className={classes.searchIcon} />}
        InputProps={{ classes: { root: classes.input } }}
      />
      <TextButton
        className={classes.buttonSearch}
        variant="contained"
        color="primary"
        disabled={!isSearchValid}
        onClick={handleOnSearchClicked}
      >
        Sök
      </TextButton>
      <FastNavDropdown
        label="Sidonummer"
        value={currentPage}
        onChange={({ target }) => {
          handleNavToPage(target.value);
        }}
      >
        {Array.from(Array(numberOfPages).keys()).map(
          (value) => (
            <MenuItem key={value} value={value + 1}>
              {value + 1 - startPageOffset}
            </MenuItem>
          )
        )}
      </FastNavDropdown>
      {chapterData?.chapter?.length > 0 && currentPage && (
        <FastNavChaptersSection
          chapters={chapterData?.chapter}
          onChange={({ target }) => {
            handleNavToPage(target.value);
          }}
          currentPage={currentPage}
          pageOffset={startPageOffset}
        />
      )}
      <div className={classes.containerNavActions}>
        <TooltipButton
          onClick={() => handleNavToType(CONTENT_PAGE_KEY)}
          tooltipText="Innehållsförteckning"
          tooltipProps={{ placement: 'top' }}
          buttonClasses={{ root: classes.leftButton }}
          tooltipClasses={{ tooltip: classes.tooltip }}
        >
          <Bulleted className={classes.icon} />
        </TooltipButton>
        <TooltipButton
          onClick={() => handleNavToType(INDEX_PAGE_KEY)}
          tooltipText="Index"
          tooltipProps={{ placement: 'top' }}
          buttonClasses={{ root: classes.rightButton }}
          tooltipClasses={{ tooltip: classes.tooltip }}
        >
          <Drag className={classes.icon} />
        </TooltipButton>
      </div>
      {searchDataPages?.length > 0 && (
        <>
          <span
            className={classes.label}
          >{`Söknavigering:`}</span>
          <div className={classes.containerSearchNav}>
            <TooltipButton
              onClick={() => handleSearchNavBtnClick(true)}
              tooltipText="Föregående träff"
              tooltipProps={{ placement: 'top' }}
              buttonClasses={{ root: classes.leftButton }}
              tooltipClasses={{ tooltip: classes.tooltip }}
            >
              <ArrowLeft className={classes.icon} />
            </TooltipButton>
            <TooltipButton
              onClick={() => handleSearchNavBtnClick()}
              tooltipText="Nästa träff"
              tooltipProps={{ placement: 'top' }}
              buttonClasses={{ root: classes.rightButton }}
              tooltipClasses={{ tooltip: classes.tooltip }}
            >
              <ArrowRight className={classes.icon} />
            </TooltipButton>
          </div>
        </>
      )}
      {/*       <Button classes={{ root: classes.lastButton }}>
        Hitta bokmärken
      </Button> */}
    </div>
  );

  const renderNoMatchView = () => (
    <Box pt={2} px={2}>
      <Typography>Sökningen gav inga träffar...</Typography>
    </Box>
  );

  return (
    <div className={stylesScss.container}>
      {buttonsContainer()}
      <div className={stylesScss.separator} />
      <div className={stylesScss.contentContainer}>
        <Loading
          loading={searchLoading}
          msg="Söker sidor"
          className={classes.loading}
        >
          {searchDataPages?.length > 0 && (
            <ThumbnailList
              pageList={searchDataPages}
              navToPage={handleNavToPage}
              startPageOffset={startPageOffset}
              currentPageNumber={currentPage}
            />
          )}
          {searchDataPages?.length === 0 &&
            renderNoMatchView()}
        </Loading>
      </div>
    </div>
  );
};

FastBookNav.propTypes = {
  classes: objectOf(shape),
  paneSide: oneOf([LEFT, RIGHT]).isRequired,
};

FastBookNav.defaultProps = {
  classes: null,
};

export default withStyles(styles)(FastBookNav);
