import { Select, withStyles } from '@material-ui/core';
import { objectOf, shape, string } from 'prop-types';
import React from 'react';
import styles from './styles';

const FastNavDropdown = ({ classes, label, ...props }) => {
  return (
    <div className={classes.containerSelect}>
      <span className={classes.title}>{`${label}:`}</span>
      <Select
        name={label}
        disableUnderline
        classes={{ root: classes.select }}
        fullWidth
        MenuProps={{
          classes: {
            list: classes.selectMenu,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...props}
      />
    </div>
  );
};

FastNavDropdown.propTypes = {
  classes: objectOf(shape).isRequired,
  label: string,
};

export default withStyles(styles)(FastNavDropdown);
