import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    margin: 'auto 0',
  },
  text: {
    marginTop: '1em',
  },
});

const Loading = ({
  loading,
  msg,
  children,
  className,
  style,
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <div
        className={[classes.wrapper, className].join(' ')}
        style={style}
      >
        <CircularProgress />
        <p className={classes.text}>{msg}</p>
      </div>
    );
  }
  return children;
};

Loading.propTypes = {
  loading: PropTypes.bool,
  msg: PropTypes.string,
};

Loading.defaultProps = {
  loading: false,
  msg: 'Laddar..',
};

export default Loading;
