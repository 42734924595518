import { gql } from 'apollo-boost';

export default gql`
  mutation deleteNote($id: uuid!) {
    delete_note(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
