import { updateCacheForUploadAdd } from './apolloHelpers';
import { FETCH_CONFIG, ENDPOINTS } from '../config';

const postData = async ({
  url,
  file,
  userId,
  setLoading,
  setError,
}) => {
  setLoading(true);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('userId', userId);
  formData.append('pdf_form', 'PDF_FORM');

  try {
    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {},
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData,
    });

    return result;
  } catch (e) {
    setLoading(false);
    setError('Kunde inte ladda upp filen!');
    return false;
  }
};

export const fileUpload = async (
  file,
  userId,
  setLoading,
  setError
) => {
  if (file) {
    const { name, size } = file;

    if (size > 5000000) {
      setError('Filen är för stor!');
    } else {
      const res = await postData({
        url: `${process.env.REACT_APP_BACKEND_ADDRESS}/${ENDPOINTS.UPLOAD}`,
        file,
        userId,
        setLoading,
        setError,
      });
      if (!res || res.status !== 200) {
        setLoading(false);
        setError('Kunde inte ladda upp filen!');
      }
      const resJson = await res.json();

      const newUpload = {
        id: resJson.id,
        upload: {
          id: resJson.upload_id,
          title: name,
          url: resJson.url,
          opened: Date.now(),
          created: Date.now(),
          __typename: 'upload',
        },
        __typename: 'user_upload',
        user_upload_tags: [],
      };
      updateCacheForUploadAdd(newUpload);
      setLoading(false);
      return newUpload;
    }
  }
};

export const removeUpload = async (body) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/${ENDPOINTS.REMOVE_UPLOAD}`,
      {
        method: 'POST',
        ...FETCH_CONFIG,
        body: JSON.stringify(body),
      }
    );
    return await res.json();
  } catch (error) {
    return false;
  }
};

export const createPayment = async (body) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/${ENDPOINTS.CREATE_PAYMENT}`,
      {
        method: 'POST',
        ...FETCH_CONFIG,
        body: JSON.stringify(body),
      }
    );
    return await res.json();
  } catch (error) {
    return {
      message:
        'Kunde inte starta betalning... Vänligen försök igen',
    };
  }
};
