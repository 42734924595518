import { gql } from 'apollo-boost';
import { NoteParts } from '../fragments';

export default gql`
  query getNote($id: uuid!) {
    user_note(where: { id: { _eq: $id } }) {
      note {
        data
      }
    }
  }
  ${NoteParts}
`;
