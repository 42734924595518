import React, { useEffect, useState } from 'react';
import { objectOf, shape } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BookContainer from '../../components/Containers/BookContainer';
import NotesContainer from '../../components/Containers/NotesContainer';
import UploadsContainer from '../../components/Containers/UploadsContainer';
import DashboardToolbar from '../../components/DashboardToolbar';
import Divider from '../../components/Divider';
import variables from '../../styles/variables.module.scss';
import stylesSCSS from './Dashboard.module.scss';
import { NOTE, BOOK } from '../../utils/constants';
import Error from '../../components/Helpers/Error/Error';
import Loading from '../../components/Helpers/Loading/Loading';
import { fileUpload } from '../../utils/backendHelper';
import GA from '../../utils/gaHelper';
import { getUserIdFromCookie } from '../../utils/jwtHelpers';
import useQueryGetUserItems from '../../hooks/useQueryGetUserItems';
import useOrganisation from '../../hooks/useOrganisation';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ classes }) => {
  const navigate = useNavigate();

  useEffect(() => {
    GA.pageview();
  }, []);
  const [searchInput, setSearchInput] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [isUploadLoading, setIsUploadLoading] =
    useState(false);

  const {
    userData: {
      userBooks,
      userNotes,
      userUploads,
      userTags,
    },
    isLoading: loadingUserItems,
  } = useQueryGetUserItems();
  const { refetch, userOrganisations } = useOrganisation();

  useEffect(() => {
    if (userOrganisations && userOrganisations.length > 0)
      return;
    refetch();
  }, [refetch, userOrganisations]);

  const error = uploadError;

  const uploadFile = (target) => {
    const userId = getUserIdFromCookie();
    const file = target.files[0];
    fileUpload(
      file,
      userId,
      setIsUploadLoading,
      setUploadError
    );
  };

  const onSearchChange = ({ target }) => {
    setSearchInput(target.value.toLowerCase());
  };

  const onItemSelected = (id, type, currentPage) => {
    let queryParams = `left_type=${type}&left_id=${id}`;
    if (type === BOOK) {
      queryParams += `&left_page=${currentPage}`;
    }
    navigate(`/study-desk?${queryParams}`);
  };

  const onTagSelected = (selectedTagId) => {
    if (selectedTags.includes(selectedTagId)) {
      setSelectedTags(
        selectedTags.filter((tag) => tag !== selectedTagId)
      );
    } else {
      setSelectedTags([...selectedTags, selectedTagId]);
    }
  };

  const goToStudyDesk = () => {
    // Note is created in study desk
    navigate(`/study-desk?left_type=${NOTE}`);
  };

  return (
    <Grid
      container
      className={stylesSCSS.dashboardContainer}
      direction="column"
    >
      <Error
        message={error}
        msgType="error"
        onClose={() => setUploadError('')}
      />
      <Grid item xs id="library">
        <DashboardToolbar
          userTagData={userTags}
          setSearchInput={onSearchChange}
          searchInput={searchInput}
          onTagSelected={onTagSelected}
          selectedTags={selectedTags}
        />
        <Loading
          loading={loadingUserItems}
          className={stylesSCSS.loadingBook}
        >
          <BookContainer
            searchFilter={searchInput}
            selectedTags={selectedTags}
            userTagData={userTags}
            onItemSelected={onItemSelected}
            books={userBooks}
          />
        </Loading>
        <Divider />
        <Grid
          item
          xs
          className={stylesSCSS.pdfNotesContainer}
        >
          <Loading
            loading={loadingUserItems}
            className={stylesSCSS.loadingNotes}
          >
            <NotesContainer
              searchFilter={searchInput}
              userTagData={userTags}
              selectedTags={selectedTags}
              onItemSelected={onItemSelected}
              notes={userNotes}
            />
          </Loading>
          <Divider vertical />
          <Loading
            loading={loadingUserItems || isUploadLoading}
            className={stylesSCSS.loadingUploads}
          >
            <UploadsContainer
              userTagData={userTags}
              searchFilter={searchInput}
              selectedTags={selectedTags}
              onItemSelected={onItemSelected}
              uploads={userUploads}
            />
          </Loading>
        </Grid>
        <Divider />
        <Grid item className={classes.bottomContainer}>
          <Grid
            item
            className={classes.leftBottomContainer}
          >
            <Button
              variant="contained"
              classes={{ root: classes.button }}
              onClick={() => {
                goToStudyDesk(NOTE);
              }}
            >
              Skapa nytt
            </Button>
          </Grid>
          <Grid
            item
            className={classes.rightBottomContainer}
          >
            <input
              type="file"
              style={{ display: 'none' }}
              id="buttonUpload"
              name="Uppladdning"
              onChange={(e) => uploadFile(e.target)}
              multiple={false}
              accept="application/pdf"
            />
            <Button
              variant="contained"
              component="label"
              htmlFor="buttonUpload"
              classes={{ root: classes.button }}
            >
              Ladda upp
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  bottomContainer: {
    display: 'flex',
    height: variables.dashboardButtonContainer,
  },
  leftBottomContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightBottomContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    background: variables.colorExtraLightGrey,
    color: variables.colorGrey,
    width: 140,
  },
});

Dashboard.propTypes = {
  classes: objectOf(shape),
};

Dashboard.defaultProps = {
  classes: null,
};

export default withStyles(styles)(Dashboard);
