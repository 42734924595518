import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { string, objectOf, shape, func } from 'prop-types';
import {
  BOOK,
  NOTE,
  UPLOAD,
} from '../../../utils/constants';
import variables from '../../../styles/variables.module.scss';
import { Box } from '@material-ui/core';
import MyNotesIcon from '../../../assets/Icons/MyNotesIcon';
import MyDocsIcon from '../../../assets/Icons/MyDocsIcon';
import { MenuBook } from '@material-ui/icons';

const styles = (theme) => ({
  cardRoot: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    width: 145,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tooltip: {
    fontSize: '0.8em',
    marginright: '8px',
    marginLeft: 0,
  },
  listItemText: {
    paddingTop: '32px',
    width: '100%',
  },
  containerIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

const GridItem = ({ classes, item, type, onPress }) => {
  const renderIcon = (type, cover) => {
    switch (type) {
      case BOOK:
        return cover ? (
          <img
            src={cover}
            alt="Book cover"
            style={{
              width: 100,
            }}
          />
        ) : (
          <MenuBook
            style={{
              width: 100,
              fontSize: 60,
              color: variables.colorGrey,
            }}
          />
        );
      case NOTE:
        return (
          <MyNotesIcon
            fill={variables.colorGrey}
            width={80}
            style={{ marginLeft: 28 }}
          />
        );
      case UPLOAD:
        return (
          <MyDocsIcon
            fill={variables.colorGrey}
            width={70}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Tooltip
      key={item.id}
      classes={{ tooltip: classes.tooltip }}
      title={item.title}
      placement="bottom-start"
      disableFocusListener
    >
      <Card
        onClick={onPress}
        classes={{ root: classes.cardRoot }}
      >
        <Box className={classes.containerIcon}>
          {renderIcon(type, type === BOOK && item.cover)}
        </Box>
        <Typography
          align="center"
          noWrap
          classes={{ root: classes.listItemText }}
        >
          {item.title}
        </Typography>
      </Card>
    </Tooltip>
  );
};

GridItem.propTypes = {
  classes: objectOf(shape),
  item: shape(),
  type: string,
  onPress: func,
};

GridItem.defaultProps = {
  classes: null,
  item: null,
  type: null,
  onPress: null,
};

export default withStyles(styles, { withTheme: true })(
  GridItem
);
