import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from 'apollo-boost';
import {
  COOKIE_KEY,
  getCookieValue,
} from '../utils/cookieHelper';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_ADDRESS,
});
const cache = new InMemoryCache();

const authLink = new ApolloLink((operation, forward) => {
  const token = getCookieValue(COOKIE_KEY.TOKEN);

  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  resolvers: {},
});
