import variables from '../../../styles/variables.module.scss';

const {
  summaryButtonsColor,
  colorWhite,
  colorHighlightCyan,
  colorHighlightYellow,
  colorHighlightRed,
} = variables;

const style = () => ({
  button: {
    backgroundColor: summaryButtonsColor,
    color: colorWhite,
    textTransform: 'none',
    width: '100%',
    marginBottom: '14px',
    boxShadow: 'none',
  },
  highlightButton: {
    color: colorWhite,
    textTransform: 'none',
    boxShadow: 'none',
  },
  lastHighlightButton: {
    color: colorWhite,
    textTransform: 'none',
    width: '100%',
    boxShadow: 'none',
  },
  summarizeLatestButton: {
    backgroundColor: summaryButtonsColor,
    color: colorWhite,
    textTransform: 'none',
    width: '100%',
    boxShadow: 'none',
  },
  summarizeAllButton: {
    backgroundColor: summaryButtonsColor,
    color: colorWhite,
    textTransform: 'none',
    width: '100%',
    boxShadow: 'none',
    marginTop: 12,
  },
  cyanColor: {
    backgroundColor: colorHighlightCyan,
  },
  yellowColor: {
    backgroundColor: colorHighlightYellow,
  },
  redColor: {
    backgroundColor: colorHighlightRed,
  },
  saveIcon: {
    color: summaryButtonsColor,
    width: '0.8em',
    height: '0.8em',
  },
  gradient: {
    backgroundImage: `linear-gradient(-90deg, ${colorHighlightRed}, ${colorHighlightYellow} , ${colorHighlightCyan})`,
  },
  checkboxContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: '0px',
    marginRight: '0px',
    paddingBottom: '1em',
  },
  checkboxControl: {
    display: 'flex',
  },
  checkbox: {
    padding: '0px',
    marginRight: '4px',
  },
});

export default style;
