import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { string, arrayOf, func, shape } from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import styles from './UploadsContainer.module.scss';
import Divider from '../../Divider';
import ListItem from '../../Items/ListItem';
import UploadIcon from '../../../assets/Icons/UploadIcon';
import variables from '../../../styles/variables.module.scss';
import { UPLOAD } from '../../../utils/constants';
import {
  UPDATE_UPLOAD_TITLE,
  DELETE_UPLOAD,
  INSERT_USER_UPLOAD_TAG,
  DELETE_USER_UPLOAD_TAG,
} from '../../../graphql/mutations';
import {
  updateCacheForUploadTag,
  updateCacheForUploadDelete,
  optimisticUIForUploadTitle,
  optimisticUIForUploadTagAdd,
} from '../../../utils/apolloHelpers';
import { removeUpload } from '../../../utils/backendHelper';
import { getUserIdFromCookie } from '../../../utils/jwtHelpers';

const UploadsContainer = ({
  searchFilter,
  userTagData,
  selectedTags,
  onItemSelected,
  uploads,
}) => {
  const [updateTitle] = useMutation(UPDATE_UPLOAD_TITLE);
  const [deleteItem] = useMutation(DELETE_UPLOAD);
  const [addTagToItem] = useMutation(
    INSERT_USER_UPLOAD_TAG
  );
  const [deleteTagToItem] = useMutation(
    DELETE_USER_UPLOAD_TAG
  );

  const onUpdateItemTitle = (title, id) => {
    updateTitle({
      variables: { id, title },
      optimisticResponse: optimisticUIForUploadTitle(
        id,
        title
      ),
    });
  };

  const onDeleteItem = async (uploadItem) => {
    deleteItem({
      variables: { id: uploadItem.id },
      update(
        cache,
        { data: { delete_upload: returnData } }
      ) {
        updateCacheForUploadDelete(cache, returnData);
      },
    });
    const userId = getUserIdFromCookie();
    const res = await removeUpload({
      title: uploadItem.title,
      userId,
      url: uploadItem.url,
    });
    if (!res) {
      alert('Kunde inte ta bort fil från storage...');
    }
  };

  const onAddTagToItem = (userUploadId, tagId) => {
    addTagToItem({
      variables: { userUploadId, tagId },
      update(
        cache,
        { data: { insert_user_upload_tag: returnData } }
      ) {
        updateCacheForUploadTag(cache, returnData);
      },
      optimisticResponse: optimisticUIForUploadTagAdd(
        userUploadId,
        userTagData.find((e) => e.id === tagId)
      ),
    });
  };

  const onDeleteTagToItem = (id) => {
    deleteTagToItem({
      variables: { id },
      update(
        cache,
        { data: { delete_user_upload_tag: returnData } }
      ) {
        updateCacheForUploadTag(cache, returnData, false);
      },
    });
  };

  const onTagSelected =
    (userUploadId) => (tagId, userUploadTagId) => {
      const upload = uploads.find(
        (e) => e.id === userUploadId
      );
      const tagIsMarked = upload.user_upload_tags.find(
        (e) => e.tag.id === tagId
      );
      if (tagIsMarked) {
        onDeleteTagToItem(userUploadTagId);
      } else {
        onAddTagToItem(userUploadId, tagId);
      }
    };

  const filterHelper = (e) => {
    let found = false;
    e.user_upload_tags.forEach((x) => {
      if (selectedTags.includes(x.tag.id)) {
        found = true;
      }
    });
    return found;
  };

  const filteredUploads = uploads
    ? uploads.filter(
        (e) =>
          e.upload.title
            .toLowerCase()
            .includes(searchFilter) &&
          (selectedTags.length > 0 ? filterHelper(e) : true)
      )
    : null;

  const emptyPlaceholder = () => (
    <div className={styles.emptyPlaceholderContainer}>
      <h2 className={styles.emptyPlaceholderText}>
        Inget övrigt material hittades
      </h2>
    </div>
  );

  return (
    <Grid item xs={6}>
      <div className={styles.container}>
        <h2 className={styles.headerStyle}>
          Övrigt material
        </h2>
        {!uploads || (uploads && uploads.length === 0) ? (
          emptyPlaceholder()
        ) : (
          <div className={styles.listItems}>
            {filteredUploads.map((item, index) => (
              <Fragment key={item.upload.id}>
                <ListItem
                  item={{ ...item.upload }}
                  tags={item.user_upload_tags}
                  userTagData={userTagData}
                  listIcon={
                    <UploadIcon
                      style={{ marginRight: '14px' }}
                      fill={variables.colorSecondary}
                      width="30"
                      height="39"
                    />
                  }
                  onChangeItemName={onUpdateItemTitle}
                  onTagSelected={onTagSelected(item.id)}
                  onRemoveItem={() => {
                    onDeleteItem(item.upload);
                  }}
                  onClick={() => {
                    onItemSelected(item.id, UPLOAD);
                  }}
                />
                {uploads &&
                  uploads &&
                  index !== uploads.length - 1 && (
                    <Divider />
                  )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </Grid>
  );
};

UploadsContainer.propTypes = {
  searchFilter: string.isRequired,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  onItemSelected: func.isRequired,
  selectedTags: arrayOf(string).isRequired,
};

UploadsContainer.defaultProps = {
  userTagData: null,
};

export default UploadsContainer;
