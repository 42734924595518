import { gql } from 'apollo-boost';

export default gql`
  mutation deleteNoteTag($id: uuid!) {
    delete_user_note_tag(where: { id: { _eq: $id } }) {
      returning {
        user_note_id
        id
      }
    }
  }
`;
