import { gql } from 'apollo-boost';

export default gql`
  mutation insertTag($tagName: String!) {
    insert_tag(objects: [{ name: $tagName }]) {
      returning {
        id
        name
      }
    }
  }
`;
