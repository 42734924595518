import gql from 'graphql-tag';

export default gql`
  mutation InsertUserBook($bookId: uuid!) {
    __typename
    insertUserBook: insert_user_book(
      objects: { book_id: $bookId }
    ) {
      returning {
        book {
          cover
          title
        }
      }
    }
  }
`;
