import React, { useMemo, useState } from 'react';
import { func, string, oneOf } from 'prop-types';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
// import Bookmark from '@material-ui/icons/Bookmark';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import Inbox from '@material-ui/icons/Inbox';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import variables from '../../../styles/variables.module.scss';
import stylesSCSS from './VerticalMenu.module.scss';
import FastBookNav from '../FastBookNav';
import SummarizeNav from '../SummarizeNav';
import Icons from '../../../styles/Icons';
import {
  BOOK,
  LEFT,
  LIBRARY,
  RIGHT,
} from '../../../utils/constants';
import useStudyDeskContext from '../../../hooks/useStudyDeskContext';
import { makeStyles } from '@material-ui/core';

const VERTICAL_MENU_DIMENSIONS = { width: 55, height: 55 };

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: VERTICAL_MENU_DIMENSIONS.width,
    minHeight: VERTICAL_MENU_DIMENSIONS.height,
    borderRadius: 0,
    '&:hover': {
      opacity: 0.5,
    },
  },
  iconProps: {
    color: variables.colorWhite,
  },
  bookmarkOn: {
    color: variables.colorPrimary,
  },
  verticalMenu: {
    backgroundColor: variables.colorDarkNav,
    width: VERTICAL_MENU_DIMENSIONS.width,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tooltip: {
    fontSize: '0.8em',
    marginLeft: '8px',
    marginRight: 0,
  },
  drawer: {
    zIndex: 1,
    position: 'absolute',
    top: variables.appBarHeight,
    bottom: 0,
  },
}));

const Verticalmenu = ({
  drawerAnchor,
  copyTextToTextEditor,
  paneSide,
}) => {
  const classes = useStyles();
  const [isFastNavOpen, setIsFastNavOpen] = useState(false);
  const [isSummarizeOpen, setIsSummarizeOpen] =
    useState(false);
  const {
    leftPane,
    rightPane,
    onTogglePane,
    onToggleLibraryPane,
  } = useStudyDeskContext();

  const {
    isLibraryOpen,
    isMenuDisabled,
    isOtherPaneOpen,
    userBook,
  } = useMemo(() => {
    if (!paneSide) return {};
    const pane = paneSide === LEFT ? leftPane : rightPane;
    const otherPane =
      paneSide === LEFT ? rightPane : leftPane;

    return {
      isLibraryOpen: pane.type === LIBRARY,
      isMenuDisabled: pane.type !== BOOK,
      isOtherPaneOpen: !!otherPane,
      userBook:
        pane.type === BOOK ? pane.content : undefined,
    };
  }, [paneSide, leftPane, rightPane]);
  // const [bookmarked, toggleBookmark] = useState(false);

  const toggleSummarizeNav = (event) => {
    if (
      !userBook ||
      (event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift'))
    ) {
      return;
    }
    if (!isSummarizeOpen && isFastNavOpen) {
      setIsFastNavOpen(false);
    }
    setIsSummarizeOpen(!isSummarizeOpen);
  };

  const toggleFastNav = (event) => {
    if (
      !userBook ||
      (event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift'))
    ) {
      return;
    }
    if (!isFastNavOpen && isSummarizeOpen) {
      setIsSummarizeOpen(false);
    }
    setIsFastNavOpen(!isFastNavOpen);
  };

  const handleToggleLibraryPane = () => {
    if (isFastNavOpen) {
      setIsFastNavOpen(false);
    }
    if (isSummarizeOpen) {
      setIsSummarizeOpen(false);
    }
    onToggleLibraryPane(paneSide);
  };

  const PlusMinusIcon = isOtherPaneOpen ? Remove : Add;

  return (
    <>
      <Box className={classes.verticalMenu}>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title="Snabbnavigering"
          placement="right"
          disableFocusListener
        >
          <div>
            <Button
              style={{
                backgroundColor: isFastNavOpen
                  ? variables.colorPrimary
                  : variables.colorDarkNav,
              }}
              mini="true"
              onClick={(event) => toggleFastNav(event)}
              classes={{ root: classes.button }}
              disabled={isMenuDisabled}
            >
              <Search
                style={{
                  opacity: isMenuDisabled ? 0.5 : 1,
                }}
                classes={{ root: classes.iconProps }}
              />
            </Button>
          </div>
        </Tooltip>
        {/* TODO: Add bookmark functionality */}
        {/* <div className={stylesSCSS.divider} />
      <div>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title="Lägg bokmärke"
          placement="right"
          disableFocusListener
        >
          <div>
            <Button
              mini="true"
              onClick={() => toggleBookmark(!bookmarked)}
              classes={{ root: classes.button }}
              disabled={isMenuDisabled}
            >
              <Bookmark
                style={{ opacity: isMenuDisabled ? 0.5 : 1 }}
                classes={{ root: bookmarked ? classes.bookmarkOn : classes.iconProps }}
              />
            </Button>
          </div>
        </Tooltip>
      </div> */}
        <div className={stylesSCSS.divider} />
        <div>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Summera"
            placement="right"
            disableFocusListener
          >
            <div>
              <Button
                style={{
                  backgroundColor: isSummarizeOpen
                    ? variables.colorPrimary
                    : variables.colorDarkNav,
                }}
                mini="true"
                onClick={(event) =>
                  toggleSummarizeNav(event)
                }
                classes={{ root: classes.button }}
                disabled={isMenuDisabled}
              >
                <Inbox
                  style={{
                    opacity: isMenuDisabled ? 0.5 : 1,
                  }}
                  classes={{ root: classes.iconProps }}
                />
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className={stylesSCSS.divider} />
        <div>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Mitt bibliotek"
            placement="right"
            disableFocusListener
          >
            <div>
              <Button
                style={{
                  backgroundColor: isLibraryOpen
                    ? variables.colorPrimary
                    : variables.colorDarkNav,
                }}
                onClick={handleToggleLibraryPane}
                mini="true"
                classes={{ root: classes.button }}
              >
                <img
                  src={Icons.ICON_FAST_LIBRARY}
                  alt="Books"
                  classes={{ root: classes.iconProps }}
                />
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className={stylesSCSS.divider} />
        <div>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Öppna/Stäng panel"
            placement="right"
            disableFocusListener
          >
            <div>
              <Button
                onClick={() => onTogglePane(paneSide)}
                mini="true"
                classes={{ root: classes.button }}
              >
                <PlusMinusIcon
                  classes={{ root: classes.iconProps }}
                />
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className={stylesSCSS.divider} />
      </Box>
      {(isFastNavOpen || isSummarizeOpen) && (
        <Paper
          elevation={4}
          className={classes.drawer}
          style={{
            [paneSide === LEFT ? 'left' : 'right']:
              variables.verticalMenuWidth,
          }}
        >
          {isFastNavOpen ? (
            <FastBookNav paneSide={paneSide} />
          ) : (
            <SummarizeNav
              paneSide={paneSide}
              copyTextToTextEditor={copyTextToTextEditor}
              bookId={userBook && userBook.book.id}
              startPageOffset={
                userBook && userBook.book.start_page_offset
              }
            />
          )}
        </Paper>
      )}
    </>
  );
};

Verticalmenu.propTypes = {
  drawerAnchor: string,
  copyTextToTextEditor: func,
  paneSide: oneOf([LEFT, RIGHT]).isRequired,
};

Verticalmenu.defaultProps = {
  drawerAnchor: null,
  copyTextToTextEditor: null,
};

export default Verticalmenu;
