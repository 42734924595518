import { gql } from 'apollo-boost';

export default gql`
  fragment AnnotationParts on annotation {
    id
    content
    class_name
    element_id
    is_summarized
    comments {
      element_id
      content
    }
  }
`;
