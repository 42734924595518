import { MenuItem, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  arrayOf,
  func,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import FastNavDropdown from '../FastNavDropdown';
import styles from './styles';

const FastNavChaptersSection = ({
  classes,
  onChange,
  chapters,
  currentPage,
  pageOffset,
}) => {
  const getCurrentValue = () => {
    const foundIndex = chapters.findIndex(
      (chapter) => currentPage < chapter.page.page_number
    );
    if (foundIndex === 0) {
      return chapters[foundIndex].page.page_number;
    }
    if (foundIndex === -1) {
      return chapters[chapters.length - 1].page.page_number;
    }
    return chapters[foundIndex - 1].page.page_number;
  };

  return (
    <FastNavDropdown
      label="Kapitel"
      value={getCurrentValue()}
      onChange={onChange}
    >
      {chapters.map((item) => (
        <MenuItem
          key={item.id}
          value={item.page.page_number}
        >
          <Tooltip
            title={item.title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div className={classes.menuItem}>
              <span style={{ flex: 1 }}>
                {`Kapitel ${item.chapter_number}`}
              </span>
              <span style={{ paddingRight: 20 }}>
                {item.page.page_number - pageOffset}
              </span>
            </div>
          </Tooltip>
        </MenuItem>
      ))}
    </FastNavDropdown>
  );
};

FastNavChaptersSection.propTypes = {
  classes: objectOf(shape).isRequired,
  onChange: func,
  currentPage: oneOfType([string, number]),
  pageOffset: number,
  chapters: arrayOf(
    shape({
      title: string,
      chapter_number: number,
      page: shape({
        page_number: number,
      }),
    })
  ),
};

FastNavChaptersSection.defaultProps = {
  chapters: [],
};

export default withStyles(styles)(FastNavChaptersSection);
