import { gql } from 'apollo-boost';

export default gql`
  mutation deleteAnnotationByClassName(
    $pageId: uuid!
    $className: String!
  ) {
    delete_annotation_by_classname(
      where: {
        page_id: { _eq: $pageId }
        class_name: { _eq: $className }
      }
    ) {
      returning {
        id
        class_name
        page {
          page_number
        }
      }
    }
  }
`;
