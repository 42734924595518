import { gql } from 'apollo-boost';

export default gql`
  query getAnnotationsByClassName(
    $bookId: uuid!
    $className: String
    $pageOffset: Int
  ) {
    annotation_by_classname(
      where: {
        page: {
          book_id: { _eq: $bookId }
          _and: { page_number: { _gt: $pageOffset } }
        }
        class_name: { _eq: $className, _is_null: false }
      }
      order_by: { page: { page_number: asc } }
    ) @connection(key: "annotationByClassName") {
      id
      class_name
      page {
        page_number
      }
    }
  }
`;
