import React from 'react';
import { string, node } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const DefaultTextInput = ({
  variant,
  icon,
  iconPosition,
  ...props
}) => (
  <TextField
    variant={variant}
    {...props}
    InputProps={{
      ...props.InputProps,
      startAdornment: icon && (
        <InputAdornment position={iconPosition}>
          {icon}
        </InputAdornment>
      ),
    }}
  />
);

DefaultTextInput.propTypes = {
  variant: string,
  icon: node,
  iconPosition: string,
};

DefaultTextInput.defaultProps = {
  variant: 'outlined',
  icon: null,
  iconPosition: 'start',
};

export default DefaultTextInput;
