import React from 'react';
import {
  objectOf,
  shape,
  string,
  func,
  bool,
} from 'prop-types';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import variables from '../../styles/variables.module.scss';

const DefaultCheckBox = ({
  themeColor,
  checked,
  onChange,
  value,
  classes,
}) => {
  const theme = createTheme({
    palette: {
      primary: { main: themeColor },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        classes={classes}
        color="primary"
        value={value}
      />
    </MuiThemeProvider>
  );
};

DefaultCheckBox.propTypes = {
  classes: objectOf(shape),
  themeColor: string,
  onChange: func,
  checked: bool,
  value: string,
};

DefaultCheckBox.defaultProps = {
  classes: null,
  themeColor: variables.colorPrimary,
  onChange: null,
  checked: false,
  value: null,
};

export default DefaultCheckBox;
