import { number, oneOf } from 'prop-types';
import React from 'react';

const VERTICAL = 'vertical';
const HORIZONTAL = 'horizontal';

const Spacer = ({
  orientation = VERTICAL,
  spacing = 1,
}) => (
  <div
    style={
      orientation === VERTICAL
        ? {
            height: 16 * spacing,
          }
        : {
            width: 16 * spacing,
          }
    }
  />
);

Spacer.propTypes = {
  orientation: oneOf([VERTICAL, HORIZONTAL]),
  spacing: number,
};

export default Spacer;
