export const FETCH_CONFIG = {
  method: 'POST',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
  redirect: 'follow',
  referrer: 'no-referrer',
};
export const ENDPOINTS = {
  REMOVE_UPLOAD: 'v1/remove-upload',
  UPDATE_PASSWORD: 'v1/updatePass',
  UPLOAD: 'v1/upload',
  AUTHENTICATE: 'v1/auth',
  CREATE_PAYMENT: 'v1/payment/create-payment',
};
