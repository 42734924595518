import React from 'react';
import { bool } from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dividerHorizontal: {
    width: '100%',
    height: '1px',
    background: '#F2F2F2',
  },
  dividerVertical: {
    width: '1px',
    background: '#F2F2F2',
  },
});

const Divider = ({ vertical }) => {
  const classes = useStyles();
  return (
    <div
      className={
        vertical
          ? classes.dividerVertical
          : classes.dividerHorizontal
      }
    />
  );
};

Divider.propTypes = {
  vertical: bool,
};

Divider.defaultProps = {
  vertical: false,
};

export default Divider;
