import variables from '../../../styles/variables.module.scss';

const magicNumber = 716; // width of the exported pdf excluding print margins

const style = () => ({
  mainWrapper: {
    backgroundColor: variables.colorExtraLightGrey,
    height: '100%',
  },
  bookContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  paperLoading: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
    width: magicNumber,
  },
  paper: {
    margin: '1em 0',
    backgroundColor: variables.colorWhite,
    overflow: 'hidden',
    maxWidth: magicNumber,
  },
  content: {
    overflowY: 'scroll',
    height: 'calc(100vh - 168px)',
    maxHeight: 1100,
    userSelect: 'text',
    '-moz-user-select': 'text',
    '-webkit-user-select': 'text',
    '-ms-user-select': 'text',
    '& img': {
      pointerEvents: 'none',
      userSelect: 'none',
      '-moz-user-select': 'none',
      '-webkit-user-select': 'none',
      '-ms-user-select': 'none',
    },
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '0 !important',
    },
  },
  containerPageButton: {
    alignSelf: 'center',
  },
  cyanButton: {
    backgroundColor: `${variables.colorHighlightCyan} !important`,
  },
  yellowButton: {
    backgroundColor: `${variables.colorHighlightYellow} !important`,
  },
  redButton: {
    backgroundColor: `${variables.colorHighlightRed} !important`,
  },
  commentButton: {
    backgroundColor: `${variables.colorGrey} !important`,
  },
  commentIcon: {
    color: variables.colorWhite,
  },
  deleteButton: {
    backgroundColor: `${variables.colorGrey} !important`,
  },
  deleteIcon: {
    color: variables.colorWhite,
  },
  socialMediaButton: {
    backgroundColor: `${variables.colorGrey} !important`,
  },
});

export default style;
