import { ENDPOINTS, FETCH_CONFIG } from '../config';
import {
  COOKIE_KEY,
  getCookieValue,
  resetTokenCookie,
  setCookie,
} from './cookieHelper';

export const getUserIdFromCookie = () => {
  let token = getCookieValue(COOKIE_KEY.TOKEN);
  if (token) {
    token = token.toString().replace('token=', '');
    token = token.split('.'); // split the jwt into it's parts
    token = JSON.parse(atob(token[1]))[
      'https://hasura.io/jwt/claims'
    ]['x-hasura-user-id']; // Find where the userId is stored.
    return token;
  }
  return null;
};

export const checkToken = async () => {
  let token = getCookieValue(COOKIE_KEY.TOKEN);

  if (token) {
    const userId = getUserIdFromCookie();

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}/${ENDPOINTS.AUTHENTICATE}`,
      {
        ...FETCH_CONFIG,
        headers: {
          ...FETCH_CONFIG.headers,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId,
        }),
      }
    );
    const json = await res.json();
    if (json.validJWT && json.token) {
      setCookie(
        COOKIE_KEY.TOKEN,
        `${json.token};max-age=86400`
      );
    } else {
      resetTokenCookie();
    }
  }
};
