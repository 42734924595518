import { gql } from 'apollo-boost';

export default gql`
  mutation insertBookTag(
    $userBookId: uuid!
    $tagId: uuid!
  ) {
    insert_user_book_tag(
      objects: [
        { user_book_id: $userBookId, tag_id: $tagId }
      ]
    ) {
      returning {
        user_book_id
        user_book {
          user_book_tags {
            id
            tag {
              id
              name
            }
          }
        }
      }
    }
  }
`;
