import styles from '../components/Desk/BookTool/BookTool.module.scss';

const { cyan, yellow, red, comment, lastComment } = styles;

const classes = [cyan, yellow, red, comment, lastComment];

const isHighlightNode = (nodeType) => {
  switch (nodeType) {
    case cyan:
    case yellow:
    case red:
    case comment:
    case lastComment:
      return true;
    default:
      return false;
  }
};

const isColorNode = (className) => {
  switch (className) {
    case cyan:
    case yellow:
    case red:
      return true;
    default:
      return false;
  }
};

const isCommentNode = (className) => className === comment;
const isLastComment = (className) =>
  className === lastComment;
const getElementByClassName = (className) =>
  document.getElementsByClassName(className)[0];

const containsHighlightNode = (className, selection) =>
  getElementByClassName(className) &&
  selection.containsNode(
    getElementByClassName(className),
    true
  );

const containsNode = (selection) => {
  const isNode = classes.find((item) =>
    containsHighlightNode(item, selection)
  );
  return !!isNode;
};

const returnObj = {
  isHighlightNode,
  containsNode,
  isColorNode,
  isCommentNode,
  isLastComment,
  cyan,
  yellow,
  red,
  comment,
  lastComment,
};

export default returnObj;
