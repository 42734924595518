import { gql } from 'apollo-boost';
import { OrganisationParts } from '../fragments';

export default gql`
  query getOrganisationByPublicTag(
    $where: organisation_bool_exp!
  ) {
    organisation(where: $where) {
      ...OrganisationParts
    }
  }
  ${OrganisationParts}
`;
