import React from 'react';

export default React.createContext({
  leftPane: null,
  rightPane: null,
  prevLeftPane: null,
  prevRightPane: null,
  updatePane: (paneType, data) => undefined,
  updatePrevPane: (paneType, data) => undefined,
  onUpdateCurrentPage: (paneSide, pageNumber) => undefined,
  onTogglePane: (paneSide) => undefined,
  onToggleLibraryPane: (paneSide) => undefined,
  resetStates: () => undefined,
  onLibraryItemSelected: (id, paneSide, type, userData) =>
    undefined,
  currentSearchData: { pages: undefined, searchValue: '' },
  onUpdateSearchData: (key, value) => undefined,
});
