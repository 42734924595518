import note from './note';
import upload from './upload';
import tag from './tag';
import book from './book';
import page from './page';
import annotation from './annotation';
import userBook from './userBook';
import userNote from './userNote';
import userUpload from './userUpload';
import publicTag from './publicTag';
import organisation from './organisation';

export const NoteParts = note;
export const UploadParts = upload;
export const TagParts = tag;
export const BookParts = book;
export const PageParts = page;
export const AnnotationParts = annotation;
export const UserBookParts = userBook;
export const UserNoteParts = userNote;
export const UserUploadParts = userUpload;
export const PublicTagParts = publicTag;
export const OrganisationParts = organisation;
