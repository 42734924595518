import { makeStyles } from '@material-ui/core';
import variables from '../../../styles/variables.module.scss';

export default makeStyles(() => ({
  container: {
    height: 100,
  },
  buttonDownload: {
    color: variables.colorWhite,
  },
  cover: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  containerCover: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
