import { gql } from 'apollo-boost';

export default gql`
  mutation insertNoteTag(
    $userNoteId: uuid!
    $tagId: uuid!
  ) {
    insert_user_note_tag(
      objects: [
        { user_note_id: $userNoteId, tag_id: $tagId }
      ]
    ) {
      returning {
        user_note_id
        userNote {
          user_note_tags {
            id
            tag {
              id
              name
            }
          }
        }
      }
    }
  }
`;
