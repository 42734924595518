export const COOKIE_KEY = Object.freeze({
  TOKEN: 'token',
  ORGANISATION: 'org',
  COOKIE: 'cookie',
  SESSION_ID: 'sess',
});

export const getCookieValue = (key) => {
  const b = document.cookie.match(
    `(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`
  );
  return b?.pop();
};

export const setCookie = (key, value) => {
  document.cookie = `${key}=${value}`;
};

export const resetTokenCookie = () => {
  setCookie(
    COOKIE_KEY.TOKEN,
    '; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  );
};

export const setTokenCookie = (token) => {
  setCookie(COOKIE_KEY.TOKEN, `${token};max-age=86400`);
};

export const resetCookie = (key) => {
  document.cookie = `${key}=; Max-Age=-99999999;`;
};
