import { gql } from 'apollo-boost';

export default gql`
  fragment BookParts on book {
    id
    title
    cover
    author
    page_amount
    index_page
    table_of_content_page
    start_page_offset
    styling
    price
    type
  }
`;
