import { gql } from 'apollo-boost';

export default gql`
  fragment NoteParts on note {
    id
    title
    created
    opened
  }
`;
