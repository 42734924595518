import client from '../apollo/client';
import {
  GET_USER_ITEMS,
  GET_NOTE_CONTENT,
  GET_ANNOTATIONS_BY_CLASS_NAME,
  GET_PAGES,
} from '../graphql/queries';

// ////////////////////////////
// - HELPERS
// ////////////////////////////
const addBookTag = (data, books) => {
  const tagsArray =
    data.returning[0].user_book.user_book_tags;
  const userBookId = data.returning[0].user_book_id;
  return books.map((e) => {
    if (e.id !== userBookId) return e;
    const arr = e.user_book_tags.concat(tagsArray);
    const distinct = [...new Set(arr.map((el) => el.id))];
    return {
      ...e,
      user_book_tags: arr.filter((el) =>
        distinct.includes(el.id)
      ),
    };
  });
};

const deleteBookTag = (data, books) => {
  const { user_book_id: userBookId, id: userBookTagId } =
    data.returning[0];

  return books.map((e) => {
    if (e.id !== userBookId) return e;
    return {
      ...e,
      user_book_tags: e.user_book_tags.filter(
        (el) => el.id !== userBookTagId
      ),
    };
  });
};

const addNoteTag = (data, notes) => {
  const tagsArray =
    data.returning[0].userNote.user_note_tags;
  const userNoteId = data.returning[0].user_note_id;
  return notes.map((e) => {
    if (e.id !== userNoteId) return e;
    const arr = e.user_note_tags.concat(tagsArray);
    const distinct = [...new Set(arr.map((el) => el.id))];
    return {
      ...e,
      user_note_tags: arr.filter((el) =>
        distinct.includes(el.id)
      ),
    };
  });
};

const deleteNoteTag = (data, notes) => {
  const { user_note_id: userNoteId, id: userNoteTagId } =
    data.returning[0];
  return notes.map((e) => {
    if (e.id !== userNoteId) return e;
    return {
      ...e,
      user_note_tags: e.user_note_tags.filter(
        (el) => el.id !== userNoteTagId
      ),
    };
  });
};

const addUploadTag = (data, uploads) => {
  const tagsArray =
    data.returning[0].user_upload.user_upload_tags;
  const userUploadId = data.returning[0].user_upload_id;
  return uploads.map((e) => {
    if (e.id !== userUploadId) return e;
    const arr = e.user_upload_tags.concat(tagsArray);
    const distinct = [...new Set(arr.map((el) => el.id))];
    return {
      ...e,
      user_upload_tags: arr.filter((el) =>
        distinct.includes(el.id)
      ),
    };
  });
};

const deleteUploadTag = (data, uploads) => {
  const {
    user_upload_id: userUploadId,
    id: userUploadTagId,
  } = data.returning[0];
  return uploads.map((e) => {
    if (e.id !== userUploadId) return e;
    return {
      ...e,
      user_upload_tags: e.user_upload_tags.filter(
        (el) => el.id !== userUploadTagId
      ),
    };
  });
};

const addAnnotationByClassName = (
  data,
  annotationByClassName
) => {
  const newItem = data.returning[0];
  return annotationByClassName.concat(newItem);
};

const deleteAnnotationByClassName = (
  data,
  annotationByClassName
) => {
  const { class_name: className } = data.returning[0];
  return annotationByClassName.filter(
    (e) => e.class_name !== className
  );
};

const addAnnotation = (data, page, currentPageId) => {
  const newAnnotation = data.returning[0];
  return page.map((e) => {
    if (e.id !== currentPageId) return e;
    return {
      ...e,
      annotations: [...e.annotations, newAnnotation],
    };
  });
};

const deleteAnnotation = (data, page, currentPageId) => {
  const deletedAnnotation = data.returning[0];
  return page.map((e) => {
    if (e.id !== currentPageId) return e;
    return {
      ...e,
      annotations: e.annotations.filter(
        (el) => el.id !== deletedAnnotation.id
      ),
    };
  });
};

const writeToCacheForUserItems = (
  cache,
  itemKey,
  user,
  list
) => {
  cache.writeQuery({
    query: GET_USER_ITEMS,
    data: {
      user: [
        {
          ...user[0],
          [itemKey]: list,
        },
      ],
    },
  });
};

const getUserItemsFromCache = (cache) => {
  const { user } = cache.readQuery({
    query: GET_USER_ITEMS,
  });
  return user;
};

// ////////////////////////////
// - MAIN FUNCTIONS
// ////////////////////////////
export const updateCacheForBookTag = (
  cache,
  data,
  isAdd = true
) => {
  const user = getUserItemsFromCache(cache);
  const userBooks = user[0].userBooks;
  let newList;
  if (isAdd) {
    newList = addBookTag(data, userBooks);
  } else {
    newList = deleteBookTag(data, userBooks);
  }

  writeToCacheForUserItems(
    cache,
    'userBooks',
    user,
    newList
  );
};

export const updateCacheForNoteTag = (
  cache,
  data,
  isAdd = true
) => {
  const user = getUserItemsFromCache(cache);
  const userNotes = user[0].userNotes;
  let newList;
  if (isAdd) {
    newList = addNoteTag(data, userNotes);
  } else {
    newList = deleteNoteTag(data, userNotes);
  }
  writeToCacheForUserItems(
    cache,
    'userNotes',
    user,
    newList
  );
};

export const updateCacheForUploadTag = (
  cache,
  data,
  isAdd = true
) => {
  const user = getUserItemsFromCache(cache);
  const userUploads = user[0].userUploads;
  let newList;
  if (isAdd) {
    newList = addUploadTag(data, userUploads);
  } else {
    newList = deleteUploadTag(data, userUploads);
  }
  writeToCacheForUserItems(
    cache,
    'userUploads',
    user,
    newList
  );
};

export const updateCacheForNoteDelete = (cache, data) => {
  const user = getUserItemsFromCache(cache);
  const notes = user[0].userNotes;
  const deletedNote = data.returning[0];
  const newList = notes.filter(
    (e) => e.note.id !== deletedNote.id
  );
  writeToCacheForUserItems(
    cache,
    'userNotes',
    user,
    newList
  );
};

export const updateCacheForNoteAdd = (cache, data) => {
  const user = getUserItemsFromCache(cache);
  const notes = user[0].userNotes;
  const newNote = data.returning[0];
  const newList = [...notes, newNote];
  writeToCacheForUserItems(
    cache,
    'userNotes',
    user,
    newList
  );
};

export const updateCacheForUploadDelete = (cache, data) => {
  const user = getUserItemsFromCache(cache);
  const uploads = user[0].userUploads;
  const deletedUpload = data.returning[0];
  const newList = uploads.filter(
    (e) => e.upload.id !== deletedUpload.id
  );
  writeToCacheForUserItems(
    cache,
    'userUploads',
    user,
    newList
  );
};

export const updateCacheForUploadAdd = (data) => {
  const user = getUserItemsFromCache(client);
  const userUploads = user[0].userUploads;
  const newList = [...userUploads, data];
  writeToCacheForUserItems(
    client,
    'userUploads',
    user,
    newList
  );
};

export const updateCacheForUserTag = (
  cache,
  data,
  isAdd = true
) => {
  const user = getUserItemsFromCache(cache);
  const { userTags } = user[0];
  let newList;
  if (isAdd) {
    const tag = data.returning[0];
    newList = [...userTags, tag];
  } else {
    const { id } = data.returning[0];
    newList = userTags.filter((e) => e.id !== id);
  }
  writeToCacheForUserItems(
    cache,
    'userTags',
    user,
    newList
  );
};

export const updateCacheForNoteContent = (
  cache,
  data,
  userNoteId
) => {
  const { user_note: userNote } = cache.readQuery({
    query: GET_NOTE_CONTENT,
    variables: {
      id: userNoteId,
    },
  });
  const newNoteContent = data.returning[0].data;
  cache.writeQuery({
    query: GET_NOTE_CONTENT,
    variables: {
      id: userNoteId,
    },
    data: {
      user_note: [
        {
          ...userNote[0],
          note: {
            ...userNote[0].note,
            data: newNoteContent,
          },
        },
      ],
    },
  });
};

export const updateCacheForAnnotationByClassName = (
  cache,
  data,
  bookId,
  isAdd = true
) => {
  try {
    const {
      annotation_by_classname: annotationByClassName,
    } = cache.readQuery({
      query: GET_ANNOTATIONS_BY_CLASS_NAME,
      variables: { bookId },
    });
    let newList;
    if (isAdd) {
      newList = addAnnotationByClassName(
        data,
        annotationByClassName
      );
    } else {
      newList = deleteAnnotationByClassName(
        data,
        annotationByClassName
      );
    }
    cache.writeQuery({
      query: GET_ANNOTATIONS_BY_CLASS_NAME,
      variables: { bookId },
      data: {
        annotation_by_classname: newList,
      },
    });
  } catch (e) {}
};

export const updateCacheForAnnotation = (
  cache,
  data,
  bookId,
  currentPageId,
  isAdd = true
) => {
  const queryOptions = {
    query: GET_PAGES,
    variables: {
      bookId,
    },
  };
  const { page } = cache.readQuery(queryOptions);
  let newList;
  if (isAdd) {
    newList = addAnnotation(data, page, currentPageId);
  } else {
    newList = deleteAnnotation(data, page, currentPageId);
  }
  cache.writeQuery({
    ...queryOptions,
    data: { page: newList },
  });
};

export const optimisticUIForNoteTitle = (id, title) => {
  return {
    __typename: 'Mutation',
    update_note: {
      __typename: 'note_mutation_response',
      returning: {
        __typename: 'note',
        id,
        title,
      },
    },
  };
};

export const optimisticUIForUploadTitle = (id, title) => {
  return {
    __typename: 'Mutation',
    update_upload: {
      __typename: 'upload_mutation_response',
      returning: {
        __typename: 'upload',
        id,
        title,
      },
    },
  };
};

export const optimisticUIForUploadTagAdd = (id, tag) => {
  return {
    __typename: 'Mutation',
    insert_user_upload_tag: {
      __typename: 'user_upload_tag_mutation_response',
      returning: [
        {
          __typename: 'user_upload_tag',
          user_upload_id: id,
          user_upload: {
            __typename: 'user_upload',
            user_upload_tags: [
              {
                __typename: 'user_upload_tag',
                id: '-1',
                tag: {
                  __typename: 'tag',
                  ...tag,
                },
              },
            ],
          },
        },
      ],
    },
  };
};
export const optimisticUIForNoteTagAdd = (id, tag) => {
  return {
    __typename: 'Mutation',
    insert_user_note_tag: {
      __typename: 'user_note_tag_mutation_response',
      returning: [
        {
          __typename: 'user_note_tag',
          user_note_id: id,
          userNote: {
            __typename: 'userNote',
            user_note_tags: [
              {
                __typename: 'user_note_tag',
                id: '-1',
                tag: {
                  __typename: 'tag',
                  ...tag,
                },
              },
            ],
          },
        },
      ],
    },
  };
};
export const optimisticUIForBookTagAdd = (id, tag) => {
  return {
    __typename: 'Mutation',
    insert_user_book_tag: {
      __typename: 'user_book_tag_mutation_response',
      returning: [
        {
          __typename: 'user_book_tag',
          user_book_id: id,
          user_book: {
            __typename: 'user_book',
            user_book_tags: [
              {
                __typename: 'user_book_tag',
                id: '-1',
                tag: {
                  __typename: 'tag',
                  ...tag,
                },
              },
            ],
          },
        },
      ],
    },
  };
};
