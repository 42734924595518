import { gql } from 'apollo-boost';
import { AnnotationParts } from '../fragments';

export default gql`
  mutation insertAnnotation(
    $object: [annotation_insert_input!]!
  ) {
    insert_annotation(objects: $object) {
      returning {
        ...AnnotationParts
      }
    }
  }
  ${AnnotationParts}
`;
