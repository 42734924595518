import { makeStyles } from '@material-ui/core';
import variables from '../../styles/variables.module.scss';

const headerHeight = 82;

export default makeStyles((theme) => ({
  header: {
    backgroundColor: variables.colorLightGrey,
    padding: theme.spacing(2, 4),
    height: headerHeight,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchInput: {
    width: 230,
  },
  searchIcon: {
    fontSize: 14,
    color: variables.colorPrimary,
  },
  input: {
    height: 50,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  separator: {
    height: 2,
    width: '98%',
    display: 'block',
    backgroundColor: variables.colorExtraLightGrey,
    margin: theme.spacing(2, 'auto'),
  },
  containerInner: {
    display: 'flex',
    flexDirection: 'column',
    // height: `calc(100vh - ${variables.appBarHeight} - ${headerHeight}px)`,
  },
}));
