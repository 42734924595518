import { useMutation } from '@apollo/react-hooks';
import {
  Box,
  Container,
  TablePagination,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import DefaultTextInput from '../../components/Inputs/DefaultTextInput';
import {
  GET_BOOK_TAGS,
  GET_USER_ITEMS,
} from '../../graphql/queries';
import { INSERT_USER_BOOK } from '../../graphql/mutations';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';
import LibrarySelect from '../../components/LibrarySelect';
import Spacer from '../../components/shared/Spacer';
import LibraryListItem from '../../components/Items/LibraryListItem';
import useQueryGetLibraryData from '../../hooks/useQueryGetLibraryData';
import useError from '../../hooks/useError';
import {
  BOOK_TYPE,
  USER_TYPE,
} from '../../utils/constants';
import { createPayment } from '../../utils/backendHelper';

const rowsPerPageOptions = [
  10,
  15,
  20,
  40,
  { label: 'Alla', value: -1 },
];

const Library = () => {
  const { bookIds, bookTagsData, userType, publicTags } =
    useQueryGetLibraryData();

  const classes = useStyles();
  const { handleError, handleSuccess } = useError();

  const [searchValue, setSearchValue] = useState('');
  const [currentListItems, setCurrentListItems] = useState(
    []
  );
  const [selectedTagsByFilter, setSelectedTagsByFilter] =
    useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageOptions[0]
  );

  const [listItemDownloading, setListItemDownloading] =
    useState();

  useEffect(() => {
    if (bookTagsData) {
      setCurrentListItems(bookTagsData);
    }
  }, [bookTagsData]);

  const [insertUserBook] = useMutation(INSERT_USER_BOOK, {
    onCompleted: () => {
      setListItemDownloading();
      handleSuccess('Material tillagt i egna biblioteket');
    },
    onError: () => {
      setListItemDownloading();
      handleError(
        'Det gick inte att lägga till i egna biblioteket... Vänligen försök igen.'
      );
    },
  });

  const handleSearchChange = ({ target }) => {
    const { value } = target;
    setSearchValue(value);
    if (value.length > 1 && !!bookTagsData) {
      const searchVal = value.toLowerCase();
      setCurrentListItems(
        bookTagsData.filter(
          ({ book }) =>
            book.author
              ?.toLowerCase()
              .includes(searchVal) ||
            book.title?.toLowerCase().includes(searchVal) ||
            book.publisher
              ?.toLowerCase()
              .includes(searchVal)
        )
      );
    } else if (value.length <= 1 && !!bookTagsData) {
      setCurrentListItems(bookTagsData);
    }
    if (page !== 0) {
      setPage(0);
    }
  };

  const handleFilterChange = (type) => (event) => {
    setSelectedTagsByFilter((prevState) => ({
      ...prevState,
      [type]: event.target.value,
    }));
  };

  const handleListItemClick = async (book) => {
    if (
      userType === USER_TYPE.ORGANISATION_FREE &&
      book.price > 0
    ) {
      setListItemDownloading(book.id);
      const res = await createPayment({
        item: book,
      });
      if (res?.url) {
        window.location.href = res.url;
      }
      setListItemDownloading();
      return;
    }
    setListItemDownloading(book.id);
    insertUserBook({
      variables: { bookId: book.id },
      refetchQueries: [
        { query: GET_USER_ITEMS },
        { query: GET_BOOK_TAGS },
      ],
    });
  };

  const filteredItems = useMemo(() => {
    const selectedTagsFlatten = [].concat.apply(
      [],
      Object.values(selectedTagsByFilter)
    );

    if (selectedTagsFlatten.length === 0) {
      return currentListItems;
    }
    return currentListItems.filter((item) => {
      return selectedTagsFlatten.some((v) =>
        item.publicTagIds.includes(v)
      );
    });
  }, [currentListItems, selectedTagsByFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isDownloadButtonDisabled = (bookId) =>
    bookIds.some((id) => id === bookId);

  const getListItemBtnTitle = (book) => {
    if (
      userType === USER_TYPE.ORGANISATION_FREE &&
      book.price > 0
    ) {
      return `${
        book.type === BOOK_TYPE.RENTAL
          ? 'Hyr 1 år'
          : 'Köp nu'
      } - ${book.price} kr`;
    }
    return 'Ladda hem';
  };

  if (!bookIds || !bookTagsData || !publicTags) return null;

  return (
    <>
      <Box className={classes.header}>
        <Box display="flex">
          {publicTags?.map((tag) => (
            <React.Fragment key={tag.id}>
              <LibrarySelect
                value={selectedTagsByFilter[tag.id] ?? []}
                onChange={handleFilterChange(tag.id)}
                data={tag}
              />
              <Spacer orientation="horizontal" />
            </React.Fragment>
          ))}
        </Box>
        <DefaultTextInput
          label="Sök"
          type="search"
          name="search"
          className={classes.searchInput}
          onChange={handleSearchChange}
          value={searchValue}
          icon={
            <SearchIcon className={classes.searchIcon} />
          }
          InputProps={{ classes: { root: classes.input } }}
        />
      </Box>
      <Container>
        <Box className={classes.containerInner}>
          <Spacer spacing={2} />
          <Box flex="1">
            {(rowsPerPage > 0
              ? filteredItems.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredItems
            ).map((item, index) => (
              <React.Fragment key={item.book.id}>
                <LibraryListItem
                  item={item.book}
                  loading={
                    listItemDownloading === item.book.id
                  }
                  ButtonProps={{
                    title: getListItemBtnTitle(item.book),
                    onClick: () =>
                      handleListItemClick(item.book),
                    disabled: isDownloadButtonDisabled(
                      item.book.id
                    ),
                  }}
                />
                {index !== currentListItems.length - 1 && (
                  <div className={classes.separator} />
                )}
              </React.Fragment>
            ))}
          </Box>
          <TablePagination
            component="div"
            count={filteredItems.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            colSpan={3}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </Box>
      </Container>
    </>
  );
};

export default Library;
