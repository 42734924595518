import { useLazyQuery } from '@apollo/react-hooks';
import { useEffect, useMemo } from 'react';
import { ENDPOINTS, FETCH_CONFIG } from '../config';
import {
  GET_PUBLIC_TAGS,
  GET_USER_ITEMS,
} from '../graphql/queries';
import { getUserIdFromCookie } from '../utils/jwtHelpers';
import useError from './useError';
import useQueryGetBookTags from './useQueryGetBookTags';
import { setTokenCookie } from '../utils/cookieHelper';
import useOrganisation from './useOrganisation';
import useAuth from './useAuth';

const NO_ORG_PARENT_TAG_ID =
  'ec568976-2c4a-43a3-9701-98a9fca41b34';

const useQueryGetLibraryData = () => {
  const { handleError } = useError();
  const { signOut } = useAuth();

  const {
    currentOrganisation,
    isLoading: isLoadingOrganisation,
  } = useOrganisation();

  const [getPublicTags, { data: publicTags }] =
    useLazyQuery(GET_PUBLIC_TAGS, {
      onError: () => {
        handleError(
          'Kunde inte hämta information om taggar...'
        );
      },
    });

  const [getBookTags, { data: bookTagsData }] =
    useQueryGetBookTags();

  const [getUserItems, { data: userItems }] = useLazyQuery(
    GET_USER_ITEMS,
    {
      onError: () => {
        handleError(
          'Kunde inte hämta information om dina böcker...'
        );
      },
    }
  );

  useEffect(() => {
    if (userItems?.user?.length === 0) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userItems]);

  const filteredBooksWithTags = useMemo(() => {
    if (!publicTags || !bookTagsData) {
      return undefined;
    }

    const rec = (bookTagIds, publicTag) => {
      if (publicTag.publicTags?.length > 0) {
        const res = rec(bookTagIds, publicTag.publicTags);

        return (
          res ||
          publicTag.publicTags.some((tag) =>
            bookTagIds.includes(tag.id)
          )
        );
      }
      return false;
    };

    return bookTagsData.filter((bookData) => {
      const bookPublicTagIds = bookData.publicTagIds;

      return (
        bookPublicTagIds.includes(
          currentOrganisation?.publicTag?.id ??
            NO_ORG_PARENT_TAG_ID
        ) ||
        publicTags.data.some(
          (tag) =>
            !tag.publicTags || rec(bookPublicTagIds, tag)
        )
      );
    });
  }, [publicTags, bookTagsData, currentOrganisation]);

  useEffect(() => {
    if (isLoadingOrganisation) {
      return;
    }
    // FIXME: temporary fix for loading. Can be fixed by commenting out TODO in src/contexts/OrganisationContext/index.js
    // But loading will be stuck for FREE users
    if (!currentOrganisation) {
      setTimeout(() => {
        getPublicTags({
          variables: {
            parentTagId:
              currentOrganisation?.publicTag?.id ??
              NO_ORG_PARENT_TAG_ID,
          },
        });
      }, 300);
    } else {
      getPublicTags({
        variables: {
          parentTagId:
            currentOrganisation?.publicTag?.id ??
            NO_ORG_PARENT_TAG_ID,
        },
      });
    }
  }, [
    currentOrganisation,
    publicTags,
    getPublicTags,
    isLoadingOrganisation,
  ]);

  useEffect(() => {
    const getJWT = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/${ENDPOINTS.AUTHENTICATE}`,
        { ...FETCH_CONFIG }
      );
      const json = await res.json();
      if (res.status === 200) {
        setTokenCookie(json.token);
        getBookTags();
      } else if (json.error) {
        handleError(json.message);
      }
    };

    if (getUserIdFromCookie()) {
      getBookTags();
      getUserItems();
    } else {
      getJWT();
    }
  }, [getBookTags, getUserItems, handleError]);

  const hasUserItems = userItems?.user?.length > 0;

  return {
    bookTagsData: filteredBooksWithTags,
    bookIds:
      hasUserItems &&
      userItems?.user[0].userBooks.map(
        (userBook) => userBook.book.id
      ),
    userType: hasUserItems && userItems?.user[0].role,
    publicTags: publicTags?.data,
  };
};

export default useQueryGetLibraryData;
