import {
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { arrayOf, bool, func, string } from 'prop-types';
import React from 'react';
import { organisationModel } from '../../models';
import Spacer from '../shared/Spacer';
import useStyles from './style';

const OrganisationSelect = ({
  data,
  value,
  onChange,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      variant="outlined"
      className={classes.select}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      inputProps={{
        classes: {
          icon: disabled
            ? classes.iconArrowDisabled
            : classes.iconArrow,
        },
      }}
      renderValue={(value) => {
        const item = data.find((it) => it.id === value);
        return (
          <div className={classes.containerCurrent}>
            {item.logo ? (
              <>
                <img
                  height={30}
                  src={item.logo}
                  alt={item.name}
                />
                <Spacer orientation="horizontal" />
              </>
            ) : (
              <Typography className={classes.nameTypo}>
                {item.name}
              </Typography>
            )}
          </div>
        );
      }}
    >
      {data?.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

OrganisationSelect.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  data: arrayOf(organisationModel),
  disabled: bool,
};

OrganisationSelect.defaultProps = {
  data: undefined,
  disabled: false,
};

export default OrganisationSelect;
