import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import Book from '../../components/Desk/Book';
import TextEditor from '../../components/Desk/TextEditor/TextEditor';
import VerticalMenu from '../../components/Desk/VerticalMenu';
import FastLibrary from '../../components/Desk/FastLibrary';
import PdfReader from '../../components/Desk/PdfReader';
import cssStyles from './StudyDesk.module.scss';
import {
  BOOK,
  NOTE,
  LEFT,
  RIGHT,
  LIBRARY,
  UPLOAD,
} from '../../utils/constants';
import {
  GET_NON_SUMMARIZED_ANNOTATIONS,
  GET_ALL_SUMMARIZED_ANNOTATIONS,
} from '../../graphql/queries';
import {
  INSERT_NOTE,
  UPDATE_ANNOTATION_IS_SUMMARIZED,
} from '../../graphql/mutations';
import Error from '../../components/Helpers/Error';
import { updateCacheForNoteAdd } from '../../utils/apolloHelpers';
import GA from '../../utils/gaHelper';
import useQueryGetUserItems from '../../hooks/useQueryGetUserItems';
import { updateSearchParams } from '../../utils/windowHelpers';
import useStudyDeskContext from '../../hooks/useStudyDeskContext';

const StudyDesk = () => {
  useEffect(() => {
    GA.pageview();
  }, []);

  const {
    leftPane,
    rightPane,
    updatePane,
    resetStates,
    onLibraryItemSelected,
  } = useStudyDeskContext();

  useEffect(() => {
    return resetStates;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [summarizedContent, setSummarizedContent] =
    useState(null);
  const [summarizedPane, setSummarizedPane] =
    useState(null);
  const [isFirstInit, setIsFirstInit] = useState(true);

  const [insertNote] = useMutation(INSERT_NOTE);
  const [updateAnnotationIsSummarized] = useMutation(
    UPDATE_ANNOTATION_IS_SUMMARIZED
  );

  const addNote = (paneSide, summarizedText) => {
    insertNote({
      update(
        cache,
        { data: { insert_user_note: returnData } }
      ) {
        updateCacheForNoteAdd(cache, returnData);
        const newNote = returnData.returning[0];
        updatePane(paneSide, {
          type: NOTE,
          content: newNote,
        });
        if (summarizedText) {
          setSummarizedContent(summarizedText);
        }
      },
    });
  };

  const initPane = (id, type, paneSide, dataUserItems) => {
    let key = 'userNotes';
    if (type === BOOK) {
      key = 'userBooks';
    } else if (type === UPLOAD) {
      key = 'userUploads';
    }

    if (type === NOTE && !id) {
      addNote(paneSide);
      return;
    }

    const userItems = dataUserItems[key].find(
      (e) => e.book?.id === id || e.id === id
    );
    if (!userItems) {
      updatePane(paneSide, { type: LIBRARY });
      return;
    }
    updatePane(paneSide, { type, content: userItems });
  };

  useEffect(() => {
    updateSearchParams(leftPane, rightPane);
  }, [leftPane, rightPane]);

  const handleGetUserItemsCompleted = (data) => {
    if (!isFirstInit) return;
    setIsFirstInit(false);

    const urlParams = new URLSearchParams(
      window.location.search
    );

    const leftId = urlParams.get('left_id');
    const leftType = urlParams.get('left_type');

    const rightId = urlParams.get('right_id');
    const rightType = urlParams.get('right_type');

    if (leftType) {
      initPane(leftId, leftType, LEFT, data);
    }
    if (rightId && rightType) {
      initPane(rightId, rightType, RIGHT, data);
    } else if (!leftId || !leftType) {
      updatePane(LEFT, { type: LIBRARY });
    }
  };

  const { userData } = useQueryGetUserItems({
    onCompleted: handleGetUserItemsCompleted,
  });

  const setAnnotationIsSummarized = (annotationsByPage) => {
    const annotationIds = annotationsByPage.reduce(
      (acc, annotations) => {
        GA.summarizeEvent({
          bookTitle: annotations[0].page.book.title,
          pageNumber: annotations[0].page.page_number,
          amount: annotations.length,
        });
        return acc.concat(
          annotations.map((annotation) => annotation.id)
        );
      },
      []
    );
    if (annotationIds.length > 0) {
      updateAnnotationIsSummarized({
        variables: { annotationIds },
      });
    }
  };

  const summarizeText = (data) => {
    if (!summarizedPane) return;
    const filtered = data.page
      .filter((e) => e.annotations.length > 0)
      .map((el) => el.annotations);

    const sum = filtered
      .flat()
      .map((el) => {
        let value = el.value.trim();
        if (el.comments.length > 0) {
          value = `${value} [${el.comments[0].content}]`;
        }
        return value;
      })
      .join('</br>');

    if (summarizedPane === RIGHT) {
      if (!leftPane || leftPane.type !== NOTE) {
        if (sum) {
          addNote(LEFT, sum);
        }
      } else {
        setSummarizedContent(sum);
      }
    } else if (!rightPane || rightPane.type !== NOTE) {
      if (sum) {
        addNote(RIGHT, sum);
      }
    } else {
      setSummarizedContent(sum);
    }
    setSummarizedPane(null);
    setAnnotationIsSummarized(filtered);
  };

  const [
    getAllNonSummarizedAnnotations,
    { error: nonSummarizedAnnotationsError },
  ] = useLazyQuery(GET_NON_SUMMARIZED_ANNOTATIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => summarizeText(data),
  });

  const [
    getAllSummarizedAnnotations,
    { error: allSummarizedAnnotationsError },
  ] = useLazyQuery(GET_ALL_SUMMARIZED_ANNOTATIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => summarizeText(data),
  });

  const error =
    nonSummarizedAnnotationsError &&
    nonSummarizedAnnotationsError.message &&
    allSummarizedAnnotationsError &&
    allSummarizedAnnotationsError.message;

  const pickedFromFastLib = (id, paneSide, type) => {
    if (summarizedContent) {
      setSummarizedContent(null);
    }

    onLibraryItemSelected(id, paneSide, type, userData);
  };

  const copySummarizedToTextEditor = (
    colorClassName,
    summarizeAll,
    paneSide
  ) => {
    setSummarizedPane(paneSide);
    const paneInfo =
      paneSide === RIGHT ? rightPane : leftPane;
    const queryVariables = {
      variables: {
        bookId: paneInfo.content.book.id,
        colorClassName,
      },
    };

    if (summarizeAll) {
      getAllSummarizedAnnotations(queryVariables);
    } else {
      getAllNonSummarizedAnnotations(queryVariables);
    }
    setSummarizedContent(null);
  };

  const renderPane = (paneSide) => {
    const paneInfo =
      paneSide === RIGHT ? rightPane : leftPane;

    switch (paneInfo.type) {
      case NOTE:
        return (
          <TextEditor
            userTagData={userData.userTags}
            userNote={paneInfo.content}
            summarizedContent={summarizedContent}
          />
        );
      case BOOK:
        return (
          <Book
            userTagData={userData.userTags}
            paneSide={paneSide}
          />
        );
      case UPLOAD:
        return (
          <PdfReader
            userTagData={userData.userTags}
            userUpload={paneInfo.content}
          />
        );
      case LIBRARY:
        return (
          <FastLibrary
            pickedFromFastLib={pickedFromFastLib}
            paneSide={paneSide}
            items={userData}
            loading={userData.loading}
            addNote={addNote}
          />
        );
      default:
        return null;
    }
  };

  const renderMenu = (paneSide) => (
    <VerticalMenu
      paneSide={paneSide}
      drawerAnchor={paneSide.toLowerCase()}
      copyTextToTextEditor={(values, summarizeAll) => {
        copySummarizedToTextEditor(
          values,
          summarizeAll,
          paneSide
        );
      }}
    />
  );

  const getPaneSize = (pane, oppositePane) => {
    if (oppositePane) {
      if (pane.type === BOOK) return 7;
      if (oppositePane.type === BOOK) return 5;
    }
    return 12;
  };

  return (
    <div className={cssStyles.container}>
      <Error message={error} />
      <Grid
        container
        direction="row"
        justifyContent="center"
      >
        {leftPane?.type && (
          <Grid
            item
            className={cssStyles.containerLeft}
            xs={getPaneSize(leftPane, rightPane)}
          >
            <Grid
              container
              wrap="nowrap"
              style={{ height: '100%' }}
            >
              <Grid item>{renderMenu(LEFT)}</Grid>
              <Grid item xs>
                {renderPane(LEFT)}
              </Grid>
            </Grid>
          </Grid>
        )}
        {rightPane?.type && (
          <Grid
            item
            className={cssStyles.containerRight}
            xs={getPaneSize(rightPane, leftPane)}
          >
            <Grid
              container
              style={{ height: '100%' }}
              wrap="nowrap"
            >
              <Grid item xs>
                {renderPane(RIGHT)}
              </Grid>
              <Grid item>{renderMenu(RIGHT)}</Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default StudyDesk;
