import { gql } from 'apollo-boost';

export default gql`
  mutation updateCurrentPage(
    $id: uuid!
    $currentPage: Int!
  ) {
    update_user_book(
      where: { id: { _eq: $id } }
      _set: { current_page: $currentPage }
    ) {
      returning {
        id
        current_page
      }
    }
  }
`;
