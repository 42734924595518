const styles = () => ({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  tooltip: {
    fontSize: 14,
    marginLeft: '8px',
    marginRight: 0,
  },
});

export default styles;
