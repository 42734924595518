import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  objectOf,
  shape,
  func,
  bool,
  number,
  string,
  arrayOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import AddComment from '@material-ui/icons/AddComment';
import Delete from '@material-ui/icons/Delete';
import rangy from 'rangy/lib/rangy-core';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-serializer';
import { useMutation } from '@apollo/react-hooks';
import stylesScss from './BookTool.module.scss';
import Icons from '../../../styles/Icons';
import {
  INSERT_ANNOTATION,
  DELETE_ANNOTATION,
  UPDATE_ANNOTATION_CLASS_NAME,
  INSERT_ANNOTATION_BY_CLASS_NAME,
  DELETE_ANNOTATION_BY_CLASS_NAME,
  UPDATE_ANNOTATION_BY_CLASS_NAME,
} from '../../../graphql/mutations';
import { LEFT, RIGHT } from '../../../utils/constants';
import muiStyles from './BookToolMuiStyles';
import {
  updateCacheForAnnotationByClassName,
  updateCacheForAnnotation,
} from '../../../utils/apolloHelpers';
import GA from '../../../utils/gaHelper';

const styles = () => muiStyles;

const BookTool = forwardRef(
  (
    {
      classes,
      isBookToolVisible,
      closeBookTool,
      openCommentTool,
      textCoordinates,
      markedText,
      currentTarget,
      highlightID,
      hasHighlight,
      isEditMode,
      canUserSearch,
      showTrashIcon,
      currentPage,
      annotations,
      book,
      paneSide,
    },
    ref
  ) => {
    const [commentId, setCommentId] = useState(null);
    const [prevClassName, setPrevClassName] =
      useState(null);

    const { synonyms, wikipedia, google } = useMemo(() => {
      return {
        synonyms: `https://www.synonymer.se/sv-syn/${markedText}`,
        wikipedia: `https://sv.wikipedia.org/wiki/${markedText}`,
        google: `https://www.google.com/search?q=${markedText}`,
      };
    }, [markedText]);

    const colors = useMemo(
      () => [
        {
          selected: classes.cyanButton,
          style: stylesScss.cyan,
        },
        {
          selected: classes.yellowButton,
          style: stylesScss.yellow,
        },
        {
          selected: classes.redButton,
          style: stylesScss.red,
        },
      ],
      [classes]
    );

    useEffect(() => {
      rangy.init();
    }, []);

    const [insertAnnotationByClassName] = useMutation(
      INSERT_ANNOTATION_BY_CLASS_NAME,
      {
        update(
          cache,
          {
            data: {
              insert_annotation_by_classname: returnData,
            },
          }
        ) {
          updateCacheForAnnotationByClassName(
            cache,
            returnData,
            book.book.id
          );
        },
      }
    );

    const [deleteAnnotationByClassName] = useMutation(
      DELETE_ANNOTATION_BY_CLASS_NAME,
      {
        update(
          cache,
          {
            data: {
              delete_annotation_by_classname: returnData,
            },
          }
        ) {
          updateCacheForAnnotationByClassName(
            cache,
            returnData,
            book.book.id,
            false
          );
        },
      }
    );

    const onDeleteAnnotationByClassName = (className) => {
      const onlyOneClassName =
        annotations.filter(
          (e) => e.class_name === className
        ).length === 1;

      if (onlyOneClassName) {
        deleteAnnotationByClassName({
          variables: {
            pageId: currentPage.id,
            className,
          },
        });
      }
    };

    const onUpdateAnnotationClassNameCompleted = ({
      update_annotation: returnData,
    }) => {
      const className = returnData.returning[0].class_name;

      if (
        annotations.filter(
          (e) => e.class_name === prevClassName
        ).length === 1
      ) {
        if (
          annotations.some(
            (e) => e.class_name === className
          )
        ) {
          onDeleteAnnotationByClassName(prevClassName);
        } else {
          updateAnnotationByClassName({
            variables: {
              pageId: currentPage.id,
              newClassName: className,
              oldClassName: prevClassName,
            },
          });
        }
      } else if (
        !annotations.some((e) => e.class_name === className)
      ) {
        insertAnnotationByClassName({
          variables: {
            pageId: currentPage.id,
            className,
          },
        });
      }
      setPrevClassName();
    };

    const onInsertAnnotationCompleted = ({
      insert_annotation: returnData,
    }) => {
      const className = returnData.returning[0].class_name;
      const classNameExists = annotations.some(
        (e) => e.class_name === className
      );
      if (!classNameExists) {
        insertAnnotationByClassName({
          variables: {
            pageId: currentPage.id,
            className,
          },
        });
      }
    };

    const [insertAnnotation] = useMutation(
      INSERT_ANNOTATION,
      { onCompleted: onInsertAnnotationCompleted }
    );

    const onDeleteAnnotationCompleted = ({
      delete_annotation: returnData,
    }) => {
      const className = returnData.returning[0].class_name;
      onDeleteAnnotationByClassName(className);
    };

    const [deleteAnnotation] = useMutation(
      DELETE_ANNOTATION,
      {
        onCompleted: onDeleteAnnotationCompleted,
      }
    );

    const [updateAnnotationClassName] = useMutation(
      UPDATE_ANNOTATION_CLASS_NAME,
      { onCompleted: onUpdateAnnotationClassNameCompleted }
    );
    const [updateAnnotationByClassName] = useMutation(
      UPDATE_ANNOTATION_BY_CLASS_NAME
    );

    const setLastCommentIcon = (id) => {
      const coms = Object.values(
        document.getElementsByClassName(stylesScss.comment)
      ).filter((node) => node.id === id);
      const lastComment = coms[coms.length - 1];
      if (coms.length > 0) {
        lastComment.setAttribute(
          'class',
          `${stylesScss.lastComment}`
        );
      }
    };

    const setAnnotations = useCallback(() => {
      const highlighter = rangy.createHighlighter();
      annotations.forEach((item) => {
        highlighter.addClassApplier(
          rangy.createClassApplier(item.class_name, {
            elementProperties: {
              id: item.element_id,
            },
          })
        );
        let { content } = item;
        if (paneSide === RIGHT) {
          content = content.replace(LEFT, RIGHT);
        }
        highlighter.deserialize(content);
        setLastCommentIcon(item.element_id);
      });
    }, [annotations, paneSide]);

    useEffect(() => {
      if (!annotations || !paneSide) {
        return;
      }
      setTimeout(() => {
        setAnnotations();
      }, 200);
    }, [annotations, paneSide, setAnnotations]);

    const addAnnotation = (annotation, currentComment) => {
      const {
        className: class_name,
        elementId: element_id,
        content,
        value,
        position,
      } = annotation;
      const object = {
        page_id: currentPage.id,
        class_name,
        element_id,
        content,
        value,
        position,
      };
      if (annotation.className.includes('comment')) {
        insertAnnotation({
          variables: {
            object: {
              ...object,
              comments: {
                data: { content: currentComment },
              },
            },
          },
          update(
            cache,
            { data: { insert_annotation: returnData } }
          ) {
            updateCacheForAnnotation(
              cache,
              returnData,
              book.book.id,
              currentPage.id
            );
          },
        });
      } else {
        insertAnnotation({
          variables: { object },
          update(
            cache,
            { data: { insert_annotation: returnData } }
          ) {
            updateCacheForAnnotation(
              cache,
              returnData,
              book.book.id,
              currentPage.id
            );
          },
        });
      }
    };

    const onDeleteAnnotation = (elementId) => {
      deleteAnnotation({
        variables: { elementId },
        update(
          cache,
          { data: { delete_annotation: returnData } }
        ) {
          updateCacheForAnnotation(
            cache,
            returnData,
            book.book.id,
            currentPage.id,
            false
          );
        },
      });
    };

    const onUpdateAnnotationClassName = (
      elementId,
      className
    ) => {
      const annotation = annotations.find(
        (e) => e.element_id === elementId
      );
      if (!annotation) return;
      setPrevClassName(annotation.class_name);
      const content = annotation.content.replace(
        annotation.class_name,
        className
      );
      updateAnnotationClassName({
        variables: {
          elementId,
          className,
          content,
        },
      });
    };

    const saveSelection = () => {
      if (window.getSelection) {
        const sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          return sel.getRangeAt(0);
        }
      } else if (
        document.selection &&
        document.selection.createRange
      ) {
        return document.selection.createRange();
      }
      return null;
    };

    const restoreSelection = (range) => {
      if (range) {
        if (window.getSelection) {
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (document.selection && range.select) {
          range.select();
        }
      }
    };

    const selectionRange = saveSelection();

    const setHighlight = (classColor, currentComment) => {
      GA.addAnnotationEvent(classColor, book);

      const id = `_${Math.random()
        .toString(36)
        .substr(2, 9)}`;
      const idForEachClass =
        classColor === stylesScss.comment ? commentId : id;
      const highlighter = rangy.createHighlighter();
      highlighter.addClassApplier(
        rangy.createClassApplier(classColor, {
          elementProperties: {
            id: idForEachClass,
          },
        })
      );
      highlighter.highlightSelection(classColor, {
        containerElementId: paneSide,
      });
      const selection = window.getSelection();
      let serializedHighlight =
        highlighter.serialize(selection);
      serializedHighlight = serializedHighlight.replace(
        RIGHT,
        LEFT
      );

      const el = document.getElementById(idForEachClass);
      const position = el.getBoundingClientRect().top;

      const annotation = {
        elementId: idForEachClass,
        className: classColor,
        content: serializedHighlight,
        value: selection.toString(),
        position,
      };
      addAnnotation(annotation, currentComment);
      selection.removeAllRanges();
    };

    const removeHighlightById = (highlights) => {
      onDeleteAnnotation(highlightID);
      Object.values(highlights).map((node) => {
        if (node.id === highlightID) {
          node.removeAttribute('id');
          node.removeAttribute('class');
          node.replaceWith(node.innerHTML);
        }
        return null;
      });
    };

    const removeHighlight = () => {
      GA.removeAnnotationEvent(
        currentTarget.className,
        book
      );

      const allCyanHighlights =
        document.getElementsByClassName(stylesScss.cyan);
      const allYellowHighlights =
        document.getElementsByClassName(stylesScss.yellow);
      const allRedHighlights =
        document.getElementsByClassName(stylesScss.red);

      const { cyan, yellow, red } = stylesScss;

      switch (currentTarget.className) {
        case cyan:
          removeHighlightById(allCyanHighlights);
          break;
        case yellow:
          removeHighlightById(allYellowHighlights);
          break;
        case red:
          removeHighlightById(allRedHighlights);
          break;
        default:
      }
      closeBookTool();
    };

    const changeColorById = (highlights, classColor) => {
      onUpdateAnnotationClassName(highlightID, classColor);
      Object.values(highlights).map((node) => {
        if (node.id === highlightID) {
          node.setAttribute('class', classColor);
        }
        return null;
      });
    };

    const changeHighlightColor = (classColor) => {
      GA.addAnnotationEvent(classColor, book);
      GA.removeAnnotationEvent(
        currentTarget.className,
        book
      );

      changeColorById(
        document.getElementsByClassName(
          currentTarget.className
        ),
        classColor
      );
    };

    const renderLinkButtons = () => (
      <div className={stylesScss.linkButtonContainer}>
        <MenuItem
          disabled={/\s/.test(markedText) || !markedText}
          onClick={() => window.open(synonyms, '_blank')}
          classes={{ root: classes.synonymButton }}
          style={{
            marginLeft: hasHighlight ? '0px' : '8px',
          }}
        >
          <img alt="synonymer" src={Icons.LOGO_SYNONYMER} />
        </MenuItem>
        <MenuItem
          disabled={!markedText}
          onClick={() => window.open(wikipedia, '_blank')}
          classes={{ root: classes.rightButtons }}
        >
          <img alt="wikipedia" src={Icons.LOGO_WIKIPEDIA} />
        </MenuItem>
        <MenuItem
          disabled={!markedText}
          onClick={() => window.open(google, '_blank')}
          classes={{ root: classes.rightButtons }}
        >
          <img alt="google" src={Icons.LOGO_GOOGLE} />
        </MenuItem>
      </div>
    );

    useImperativeHandle(ref, () => ({
      saveComment(currentComment) {
        setHighlight(stylesScss.comment, currentComment);
        setLastCommentIcon(commentId);
      },
      removeComment() {
        onDeleteAnnotation(highlightID);
      },
      setAnnotations() {
        setAnnotations();
      },
    }));

    return (
      <Popover
        open={isBookToolVisible}
        onClose={closeBookTool}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: textCoordinates.y || 0,
          left: textCoordinates.x || 0,
        }}
      >
        <div className={stylesScss.popupContentContainer}>
          {!hasHighlight && (
            <div
              className={stylesScss.colorButtonContainer}
            >
              {colors.map((color) => (
                <MenuItem
                  key={color.selected}
                  component="button"
                  selected
                  classes={{
                    selected: color.selected,
                    root: classes.minHeightButton,
                  }}
                  onClick={() => {
                    if (isEditMode) {
                      changeHighlightColor(color.style);
                    } else {
                      restoreSelection(selectionRange);
                      setHighlight(color.style);
                    }
                    closeBookTool();
                  }}
                />
              ))}
            </div>
          )}
          {!isEditMode && (
            <MenuItem
              component="button"
              classes={{ root: classes.rightButtons }}
              onClick={() => {
                const id = `_${Math.random()
                  .toString(36)
                  .substr(2, 9)}`;
                setCommentId(id);
                closeBookTool(false);
                openCommentTool(id);
                restoreSelection(selectionRange);
              }}
            >
              <AddComment
                classes={{ root: classes.commentIcon }}
              />
            </MenuItem>
          )}
          {!hasHighlight && showTrashIcon && (
            <MenuItem
              onClick={() => removeHighlight()}
              classes={{ root: classes.rightButtons }}
            >
              <Delete
                classes={{ root: classes.deleteIcon }}
              />
            </MenuItem>
          )}
          {canUserSearch && renderLinkButtons()}
        </div>
      </Popover>
    );
  }
);

BookTool.propTypes = {
  classes: objectOf(shape).isRequired,
  isBookToolVisible: bool.isRequired,
  closeBookTool: func.isRequired,
  openCommentTool: func.isRequired,
  textCoordinates: shape({
    x: number,
    y: number,
  }).isRequired,
  markedText: string,
  currentTarget: shape(),
  highlightID: string,
  hasHighlight: bool,
  isEditMode: bool,
  canUserSearch: bool,
  showTrashIcon: bool,
  currentPage: shape({
    id: string,
    page_number: number,
    content: string,
  }),
  annotations: arrayOf(
    shape({
      id: string,
      content: string,
      class_name: string,
      element_id: string,
    })
  ),
  paneSide: string.isRequired,
  book: objectOf(shape).isRequired,
};

BookTool.defaultProps = {
  markedText: null,
  currentTarget: null,
  highlightID: null,
  hasHighlight: false,
  isEditMode: false,
  canUserSearch: true,
  showTrashIcon: false,
  currentPage: null,
  annotations: null,
};

export default withStyles(styles)(BookTool);
