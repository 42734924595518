import { gql } from 'apollo-boost';

export default gql`
  mutation updateNoteContent(
    $noteId: uuid!
    $content: String!
    $opened: timestamptz!
  ) {
    update_note(
      where: { id: { _eq: $noteId } }
      _set: { data: $content, opened: $opened }
    ) {
      returning {
        data
      }
    }
  }
`;
