import ICON_MORE from '../assets/Icons/icon-more.png';
import LOGO_GOOGLE from '../assets/Icons/popupMenuIcons/logo-google.png';
import LOGO_WIKIPEDIA from '../assets/Icons/popupMenuIcons/logo-wikipedia.png';
import LOGO_SYNONYMER from '../assets/Icons/popupMenuIcons/logo-synonymer.png';
import ICON_FAST_LIBRARY from '../assets/Icons/myBookz.svg';
import ICON_COMMENT from '../assets/Icons/icon-comment.png';

const Icons = {
  ICON_MORE,
  LOGO_GOOGLE,
  LOGO_WIKIPEDIA,
  LOGO_SYNONYMER,
  ICON_FAST_LIBRARY,
  ICON_COMMENT,
};

export default Icons;
