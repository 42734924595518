import React, { useState, Fragment } from 'react';
import { Popover } from '@material-ui/core';
import { string, func, arrayOf, shape } from 'prop-types';
import MoreButton from '../Buttons/MoreButton';
import ChangeTitleContainer from '../Containers/ChangeTitleContainer';
import AddTagContainer from '../Containers/AddTagContainer';
import RemoveItemContainer from '../Containers/RemoveItemContainer';
import {
  CHANGETITLE,
  ADDTAG,
  REMOVEITEM,
} from '../../utils/constants';

const MoreMenu = ({
  userTagData,
  onChangeItemName,
  onTagSelected,
  onRemoveItem,
  data,
  item,
  itemTags,
}) => {
  const [parentAnchorEl, setParentAnchorEl] =
    useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuPopUpName, setSubMenuPopUpName] =
    useState('');

  const handleClose = () => {
    setAnchorEl(null);
    setParentAnchorEl(null);
  };

  const handlePopUpClose = () => {
    setAnchorEl(null);
  };

  const handleMoreButtonClick = (event) => {
    setParentAnchorEl(event.currentTarget);
  };

  const renderChangeTitlePopUp = () => (
    <ChangeTitleContainer
      onChangeItemName={onChangeItemName}
      closeMenuItem={handleClose}
      item={item}
    />
  );

  const renderAddTagPopUp = () => (
    <AddTagContainer
      itemTags={itemTags}
      userTagData={userTagData}
      onTagSelected={onTagSelected}
    />
  );

  const renderRemoveItemContainer = () => (
    <RemoveItemContainer
      title={item.title}
      onRemoveItem={onRemoveItem}
      closeMenuItem={handlePopUpClose}
    />
  );

  const menuItemPopUpSwitcher = () => {
    switch (subMenuPopUpName) {
      case ADDTAG:
        return renderAddTagPopUp();
      case CHANGETITLE:
        return renderChangeTitlePopUp();
      case REMOVEITEM:
        return renderRemoveItemContainer();
      default:
        return null;
    }
  };

  const handleMenuItemClick = (menuItem, event) => {
    setAnchorEl(event.currentTarget);
    setSubMenuPopUpName(menuItem.title);
  };

  return (
    <Fragment>
      <MoreButton
        anchorEl={parentAnchorEl}
        data={data}
        handleMenuItemClick={handleMenuItemClick}
        handleClick={handleMoreButtonClick}
        handleClose={handleClose}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Boolean(anchorEl) && menuItemPopUpSwitcher()}
      </Popover>
    </Fragment>
  );
};

MoreMenu.propTypes = {
  item: shape({
    id: string,
    title: string,
  }).isRequired,
  onChangeItemName: func,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  onTagSelected: func,
  onRemoveItem: func,
};

MoreMenu.defaultProps = {
  onChangeItemName: null,
  userTagData: null,
  onTagSelected: null,
  onRemoveItem: null,
};

export default MoreMenu;
