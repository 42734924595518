import { gql } from 'apollo-boost';

export default gql`
  fragment UploadParts on upload {
    id
    title
    created
    opened
    url
  }
`;
