import insertNote from './insertNote';
import updateNoteTitle from './updateNoteTitle';
import updateUploadTitle from './updateUploadTitle';
import deleteNote from './deleteNote';
import deleteUpload from './deleteUpload';
import insertTag from './insertTag';
import deleteTag from './deleteTag';
import insertUserBookTag from './insertUserBookTag';
import insertUserNoteTag from './insertUserNoteTag';
import insertUserUploadTag from './insertUserUploadTag';
import deleteUserBookTag from './deleteUserBookTag';
import deleteUserNoteTag from './deleteUserNoteTag';
import deleteUserUploadTag from './deleteUserUploadTag';
import updateNoteContent from './updateNoteContent';
import updateCurrentPage from './updateCurrentPage';
import insertAnnotation from './insertAnnotation';
import deleteAnnotation from './deleteAnnotation';
import updateComment from './updateComment';
import updateAnnotationIsSummarized from './updateAnnotationIsSummarized';
import updateAnnotationClassName from './updateAnnotationClassName';
import insertAnnotationByClassName from './insertAnnotationByClassName';
import deleteAnnotationByClassName from './deleteAnnotationByClassName';
import insertUserBook from './insertUserBook';
import updateUserSessionId from './updateUserSessionId';
import updateAnnotationByClassName from './updateAnnotationByClassName';

export const INSERT_NOTE = insertNote;
export const UPDATE_NOTE_TITLE = updateNoteTitle;
export const UPDATE_UPLOAD_TITLE = updateUploadTitle;
export const DELETE_NOTE = deleteNote;
export const DELETE_UPLOAD = deleteUpload;
export const INSERT_TAG = insertTag;
export const DELETE_TAG = deleteTag;
export const INSERT_USER_BOOK_TAG = insertUserBookTag;
export const INSERT_USER_NOTE_TAG = insertUserNoteTag;
export const INSERT_USER_UPLOAD_TAG = insertUserUploadTag;
export const DELETE_USER_BOOK_TAG = deleteUserBookTag;
export const DELETE_USER_NOTE_TAG = deleteUserNoteTag;
export const DELETE_USER_UPLOAD_TAG = deleteUserUploadTag;
export const UPDATE_NOTE_CONTENT = updateNoteContent;
export const UPDATE_CURRENT_PAGE = updateCurrentPage;
export const INSERT_ANNOTATION = insertAnnotation;
export const DELETE_ANNOTATION = deleteAnnotation;
export const UPDATE_COMMENT = updateComment;
export const UPDATE_ANNOTATION_IS_SUMMARIZED =
  updateAnnotationIsSummarized;
export const UPDATE_ANNOTATION_CLASS_NAME =
  updateAnnotationClassName;
export const INSERT_ANNOTATION_BY_CLASS_NAME =
  insertAnnotationByClassName;
export const DELETE_ANNOTATION_BY_CLASS_NAME =
  deleteAnnotationByClassName;
export const INSERT_USER_BOOK = insertUserBook;
export const UPDATE_USER_SESSION_ID = updateUserSessionId;
export const UPDATE_ANNOTATION_BY_CLASS_NAME =
  updateAnnotationByClassName;
