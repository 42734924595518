import React from 'react';
import { shape, objectOf, func } from 'prop-types';
import { withStyles } from '@material-ui/core';
import variables from '../../../styles/variables.module.scss';
import { elementType } from 'prop-types';

const styles = () => ({
  icon: {
    color: variables.colorWhite,
    paddingTop: 8,
    paddingBottom: 8,
  },
  button: {
    backgroundColor: variables.colorLightGrey,
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: variables.colorPrimary,
    },
  },
});

const PageButton = ({ onClick, icon: Icon, classes }) => (
  <button onClick={onClick} className={classes.button}>
    <Icon
      color="primary"
      fontSize="small"
      className={classes.icon}
    />
  </button>
);

PageButton.propTypes = {
  icon: elementType.isRequired,
  onClick: func.isRequired,
  classes: objectOf(shape).isRequired,
};

export default withStyles(styles)(PageButton);
