import React from 'react';
import {
  string,
  node,
  arrayOf,
  func,
  shape,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import MoreMenu from '../../MoreMenu';
import styles from './style.module.scss';
import { formatDate } from '../../../utils/dateHelper';

const ListItem = ({
  listIcon,
  userTagData,
  onChangeItemName,
  selectedTags,
  onTagSelected,
  onRemoveItem,
  onClick,
  item,
  tags,
}) => {
  const renderIconAndTitle = () => (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item className={styles.containerIcon}>
        {listIcon}
      </Grid>
      <Grid item className={styles.containerTitle}>
        {item.title}
      </Grid>
    </Grid>
  );

  const renderColumnSection = (header, value) => (
    <Grid
      container
      direction="column"
      className={styles.columnContainer}
      onClick={onClick}
    >
      <Grid item className={styles.columnHeader}>
        {header}
      </Grid>
      <Grid item className={styles.columnText}>
        {value}
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      className={styles.container}
      alignItems="center"
      wrap="nowrap"
    >
      <Grid
        item
        xs={10}
        lg
        className={styles.containerInner}
        onClick={onClick}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          className={styles.containerInner}
        >
          <Grid
            item
            xs={6}
            lg={7}
            className={styles.containerIconTitle}
          >
            {renderIconAndTitle()}
          </Grid>
          <Grid item xs={6} lg={5}>
            <Grid container wrap="nowrap">
              {renderColumnSection(
                'Skapad',
                formatDate(item.created)
              )}
              {renderColumnSection(
                'Öppnad',
                formatDate(item.opened)
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} lg="auto">
        <MoreMenu
          item={item}
          itemTags={tags}
          userTagData={userTagData}
          onChangeItemName={onChangeItemName}
          onTagSelected={onTagSelected}
          onRemoveItem={onRemoveItem}
          data={[
            { title: 'Taggar' },
            { title: 'Ändra namn' },
            { title: 'Radera' },
          ]}
        />
      </Grid>
    </Grid>
  );
};
ListItem.propTypes = {
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ).isRequired,
  onChangeItemName: func.isRequired,
  selectedTags: arrayOf(string),
  onTagSelected: func,
  listIcon: node,
  onRemoveItem: func.isRequired,
  onClick: func.isRequired,
};

ListItem.defaultProps = {
  selectedTags: null,
  onTagSelected: null,
  listIcon: null,
};

export default ListItem;
