import { useApolloClient } from '@apollo/react-hooks';
import {
  COOKIE_KEY,
  resetCookie,
  resetTokenCookie,
} from '../utils/cookieHelper';

const useAuth = () => {
  const { cache } = useApolloClient();

  const signOut = async () => {
    try {
      await cache.reset();
      resetTokenCookie();
      resetCookie(COOKIE_KEY.SESSION_ID);
      resetCookie(COOKIE_KEY.ORGANISATION);
      window.localStorage.clear();
      window.location.replace('/');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Cache not cleared');
    }
  };

  return { signOut };
};

export default useAuth;
