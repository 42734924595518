import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import { UPDATE_CURRENT_PAGE } from '../../graphql/mutations';
import {
  BOOK,
  LEFT,
  LIBRARY,
  RIGHT,
  UPLOAD,
} from '../../utils/constants';
import StudyDeskContext from './context';
import useError from '../../hooks/useError';

export { StudyDeskContext };

const StudyDeskContextProvider = (props) => {
  const [rightPane, setRightPane] = useState(null);
  const [leftPane, setLeftPane] = useState(null);
  const [prevRightPane, setPrevRightPane] = useState(null);
  const [prevLeftPane, setPrevLeftPane] = useState(null);
  const [currentSearchData, setCurrentSearchData] =
    useState({
      searchValue: '',
      pages: undefined,
    });

  const { handleError } = useError();

  const [updateCurrentPage] = useMutation(
    UPDATE_CURRENT_PAGE,
    {
      onError: () => {
        handleError(
          'Kunde inte uppdatera aktuellt sidonummer...'
        );
      },
    }
  );

  const handleLibraryItemSelected = (
    id,
    paneSide,
    type,
    userData
  ) => {
    let key = 'userNotes';
    if (type === BOOK) {
      key = 'userBooks';
    } else if (type === UPLOAD) {
      key = 'userUploads';
    }

    const content = userData[key].find((e) => e.id === id);
    updatePane(paneSide, {
      type,
      content,
    });
  };

  const reset = () => {
    setRightPane(null);
    setLeftPane(null);
    setPrevLeftPane(null);
    setPrevRightPane(null);
    setCurrentSearchData({
      searchValue: '',
      pages: undefined,
    });
  };

  const handleUpdateCurrentPage = (
    paneSide,
    pageNumber
  ) => {
    const paneInfo =
      paneSide === RIGHT ? rightPane : leftPane;

    updateCurrentPage({
      variables: {
        id: paneInfo.content.id,
        currentPage: pageNumber,
      },
    });
    updatePane(paneSide, {
      type: BOOK,
      content: {
        ...paneInfo.content,
        current_page: pageNumber,
      },
    });
  };

  const updatePane = (paneType, data) => {
    if (paneType === LEFT) {
      setLeftPane(data);
      return;
    }
    setRightPane(data);
  };

  const updatePrevPane = (paneType, data) => {
    if (paneType === LEFT) {
      setPrevLeftPane(data);
      return;
    }
    setPrevRightPane(data);
  };

  const toggleLeftPane = () => {
    if (leftPane) {
      updatePrevPane(
        LEFT,
        leftPane.type !== LIBRARY ? leftPane : null
      );
      updatePane(LEFT, null);
    } else if (
      prevLeftPane &&
      !(
        rightPane.type === BOOK &&
        prevLeftPane.type === BOOK
      )
    ) {
      updatePane(LEFT, prevLeftPane);
    } else {
      updatePane(LEFT, { type: LIBRARY });
    }
  };

  const toggleRightPane = () => {
    if (rightPane) {
      updatePrevPane(
        RIGHT,
        rightPane.type !== LIBRARY ? rightPane : null
      );
      updatePane(RIGHT, null);
    } else if (
      prevRightPane &&
      !(
        leftPane.type === BOOK &&
        prevRightPane.type === BOOK
      )
    ) {
      updatePane(RIGHT, prevRightPane);
    } else {
      updatePane(RIGHT, { type: LIBRARY });
    }
  };

  const handleTogglePane = (paneSide) => {
    if (paneSide === LEFT) {
      if (rightPane) {
        toggleLeftPane();
      } else {
        toggleRightPane();
      }
    } else if (paneSide === RIGHT) {
      if (leftPane) {
        toggleRightPane();
      } else {
        toggleLeftPane();
      }
    }
  };

  const handleToggleLibraryPane = (paneSide) => {
    const thisPane =
      paneSide === LEFT ? leftPane : rightPane;
    const thisPrevPane =
      paneSide === LEFT ? prevLeftPane : prevRightPane;
    const otherPane =
      paneSide === LEFT ? rightPane : leftPane;

    if (
      thisPane.type === LIBRARY &&
      (otherPane || thisPrevPane)
    ) {
      // We only want to close the library if this panel || prev this panel exist
      // otherwise we get an empty study desk
      updatePane(paneSide, thisPrevPane);
    } else {
      updatePrevPane(paneSide, thisPane);
      updatePane(paneSide, LIBRARY);
      updatePane(paneSide, { type: LIBRARY });
    }
  };

  const handleUpdateSearchData = (key, data) => {
    setCurrentSearchData((prevState) => ({
      ...prevState,
      [key]: data,
    }));
  };

  const value = {
    onUpdateCurrentPage: handleUpdateCurrentPage,
    updatePane,
    updatePrevPane,
    rightPane,
    leftPane,
    prevRightPane,
    prevLeftPane,
    onTogglePane: handleTogglePane,
    onToggleLibraryPane: handleToggleLibraryPane,
    resetStates: reset,
    onLibraryItemSelected: handleLibraryItemSelected,
    onUpdateSearchData: handleUpdateSearchData,
    currentSearchData,
  };

  return (
    <StudyDeskContext.Provider value={value} {...props} />
  );
};

export default StudyDeskContextProvider;
