import React, { useState } from 'react';
import {
  objectOf,
  shape,
  func,
  number,
  string,
  oneOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { useLazyQuery } from '@apollo/react-hooks';
import stylesScss from './SummarizeNav.module.scss';
import variables from '../../../styles/variables.module.scss';
import ThumbnailList from '../ThumbnailList';
import DefaultCheckBox from '../../DefaultCheckBox/DefaultCheckBox';
import highlighterHelper from '../../../utils/highlightHelper';
import Loading from '../../Helpers/Loading';
import {
  ALL,
  YELLOW,
  RED,
  CYAN,
  COMMENT,
  RIGHT,
  LEFT,
} from '../../../utils/constants';
import { GET_ANNOTATIONS_BY_CLASS_NAME } from '../../../graphql/queries';
import styles from './style';
import useStudyDeskContext from '../../../hooks/useStudyDeskContext';

const {
  colorHighlightCyan,
  colorHighlightYellow,
  colorHighlightRed,
  colorLightGrey,
} = variables;

const SummarizeNav = ({
  classes,
  copyTextToTextEditor,
  bookId,
  startPageOffset,
  paneSide,
}) => {
  const { onUpdateCurrentPage } = useStudyDeskContext();

  const [
    getAnnotationsByClassName,
    { data, fetchMore, loading },
  ] = useLazyQuery(GET_ANNOTATIONS_BY_CLASS_NAME, {
    variables: {
      bookId,
      pageOffset: startPageOffset,
    },
  });

  const handleNavToPage = (pageNumber) => {
    onUpdateCurrentPage(paneSide, pageNumber);
  };

  const onFetchMoreAnnotations = (className) => {
    fetchMore({
      variables: {
        className,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const prevArr = prev.annotation_by_classname;
        const moreArr =
          fetchMoreResult.annotation_by_classname;

        const compareArrays = (arrayOne) => (arrayTwo) =>
          arrayOne.filter(
            (other) =>
              other.class_name === arrayTwo.class_name &&
              other.page.page_number ===
                arrayTwo.page.page_number
          ).length === 0;

        const filteredPrev = prevArr.filter(
          compareArrays(moreArr)
        );
        const filteredMore = moreArr.filter(
          compareArrays(prevArr)
        );

        const newClasses =
          filteredPrev
            .concat(filteredMore)
            .filter((x) => x.class_name === className)
            .length !== 0;

        if (newClasses) {
          return Object.assign({}, prev, {
            annotation_by_classname: [
              ...prev.annotation_by_classname,
              ...fetchMoreResult.annotation_by_classname,
            ],
          });
        }
      },
    });
  };

  const onGetAnnotationsByClassName = (name) => {
    if (name === ALL) {
      getAnnotationsByClassName();
    } else if (fetchMore) {
      onFetchMoreAnnotations(name);
    } else {
      getAnnotationsByClassName({
        variables: { className: name },
      });
    }
  };

  const [checkBoxState, setCheckBoxState] = useState({
    [ALL]: false,
    [CYAN]: false,
    [YELLOW]: false,
    [RED]: false,
    [COMMENT]: false,
  });

  const filteredCheckboxes = Object.keys(
    checkBoxState
  ).filter((e) => checkBoxState[e] === true);

  const filteredAnnotations =
    data &&
    data.annotation_by_classname.filter((x) =>
      filteredCheckboxes.includes(x.class_name)
    );

  const userPageData =
    data &&
    [
      ...new Set(
        filteredAnnotations.map(
          (item) => item.page.page_number
        )
      ),
    ].map((e) => ({ page_number: e }));

  const handelCheckBox = (name) => (event) => {
    if (name === ALL) {
      if (!checkBoxState[ALL]) {
        onGetAnnotationsByClassName(name);
      }
      setCheckBoxState({
        [ALL]: !checkBoxState[ALL],
        [CYAN]: !checkBoxState[ALL],
        [YELLOW]: !checkBoxState[ALL],
        [RED]: !checkBoxState[ALL],
        [COMMENT]: !checkBoxState[ALL],
      });
    } else if (checkBoxState[ALL]) {
      setCheckBoxState({
        ...checkBoxState,
        [name]: event.target.checked,
        [ALL]: false,
      });
    } else {
      onGetAnnotationsByClassName(name);
      setCheckBoxState({
        ...checkBoxState,
        [name]: event.target.checked,
      });
    }
  };

  const checkboxes = [
    {
      id: 1,
      themeColor: colorHighlightCyan,
      checked: checkBoxState[CYAN],
      value: CYAN,
      label: 'Blå',
      className: highlighterHelper.cyan,
    },
    {
      id: 2,
      themeColor: colorHighlightYellow,
      checked: checkBoxState[YELLOW],
      value: YELLOW,
      label: 'Gul',
      className: highlighterHelper.yellow,
    },
    {
      id: 3,
      themeColor: colorHighlightRed,
      checked: checkBoxState[RED],
      value: RED,
      label: 'Röd',
      className: highlighterHelper.red,
    },
    {
      id: 4,
      themeColor: colorLightGrey,
      checked: checkBoxState[COMMENT],
      value: COMMENT,
      label: 'Kommentar',
      className: highlighterHelper.comment,
      secondaryClassName: highlighterHelper.lastComment,
    },
  ];

  const summarizeCheckedBoxes = (summarizeAll) => {
    copyTextToTextEditor(
      checkboxes
        .filter((c) => c.checked)
        .map((c) => c.className),
      summarizeAll
    );
  };

  const CheckBoxes = () => (
    <>
      <FormControl
        component="fieldset"
        classes={{ root: classes.checkboxControl }}
      >
        <FormGroup>
          <FormControlLabel
            classes={{ root: classes.checkboxContainer }}
            label="Markera allt"
            control={
              <Checkbox
                checked={checkBoxState[ALL]}
                onChange={handelCheckBox(ALL)}
                classes={{ root: classes.checkbox }}
                color="secondary"
              />
            }
          />
        </FormGroup>
        <FormGroup row>
          {checkboxes.slice(0, 2).map((item) => (
            <FormControlLabel
              key={item.id}
              classes={{ root: classes.checkboxContainer }}
              label={item.label}
              control={
                <DefaultCheckBox
                  themeColor={item.themeColor}
                  checked={item.checked}
                  onChange={handelCheckBox(item.value)}
                  classes={{ root: classes.checkbox }}
                  value={item.value}
                />
              }
            />
          ))}
        </FormGroup>
        <FormGroup row>
          {checkboxes.slice(2, 4).map((item) => (
            <FormControlLabel
              key={item.id}
              classes={{ root: classes.checkboxContainer }}
              label={item.label}
              control={
                <DefaultCheckBox
                  themeColor={item.themeColor}
                  checked={item.checked}
                  onChange={handelCheckBox(item.value)}
                  classes={{ root: classes.checkbox }}
                  value={item.value}
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  );

  const Buttons = () => (
    <>
      <Button
        disabled={
          !Object.values(checkBoxState).find(
            (x) => x === true
          )
        }
        classes={{ root: classes.summarizeLatestButton }}
        onClick={() => summarizeCheckedBoxes(false)}
      >
        Summera senaste
      </Button>
      <Button
        disabled={
          !Object.values(checkBoxState).find(
            (x) => x === true
          )
        }
        classes={{ root: classes.summarizeAllButton }}
        onClick={() => summarizeCheckedBoxes(true)}
      >
        Summera allt
      </Button>
    </>
  );

  return (
    <div className={stylesScss.container}>
      <div className={stylesScss.buttonsContainer}>
        <CheckBoxes />
        <Buttons />
      </div>
      <div className={stylesScss.separator} />
      <div className={stylesScss.contentContainer}>
        <Loading
          loading={loading}
          className={stylesScss.loadingIndicator}
        >
          <ThumbnailList
            pageList={userPageData}
            checkBoxState={checkBoxState}
            navToPage={handleNavToPage}
            startPageOffset={startPageOffset}
          />
        </Loading>
      </div>
    </div>
  );
};

SummarizeNav.propTypes = {
  classes: objectOf(shape),
  copyTextToTextEditor: func,
  startPageOffset: number.isRequired,
  bookId: string.isRequired,
  paneSide: oneOf([LEFT, RIGHT]).isRequired,
};

SummarizeNav.defaultProps = {
  classes: null,
  copyTextToTextEditor: null,
};

export default withStyles(styles)(SummarizeNav);
