import { gql } from 'apollo-boost';
import {
  PublicTagParts,
  TagParts,
  UserBookParts,
  UserNoteParts,
  UserUploadParts,
} from '../fragments';

export default gql`
  query getUserItems {
    user {
      userTags: tags {
        ...TagParts
      }
      userBooks: user_books {
        ...UserBookParts
      }
      userNotes: user_notes {
        ...UserNoteParts
      }
      userUploads: user_uploads {
        ...UserUploadParts
      }
      userPublicTags: user_public_tags {
        data: public_tag {
          ...PublicTagParts
        }
      }
      role
    }
  }
  ${UserBookParts}
  ${UserNoteParts}
  ${UserUploadParts}
  ${TagParts}
  ${PublicTagParts}
`;
