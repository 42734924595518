import React from 'react';
import {
  string,
  objectOf,
  shape,
  arrayOf,
  func,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import stylesSCSS from './BookContainer.module.scss';
import BookItem from '../../Items/BookItem';
import variables from '../../../styles/variables.module.scss';
import { BOOK } from '../../../utils/constants';
import {
  INSERT_USER_BOOK_TAG,
  DELETE_USER_BOOK_TAG,
} from '../../../graphql/mutations';
import {
  updateCacheForBookTag,
  optimisticUIForBookTagAdd,
} from '../../../utils/apolloHelpers';

const styles = () => ({
  container: {
    backgroundColor: variables.colorWhite,
    height: variables.bookContainerHeight,
  },
});

const BookContainer = ({
  classes,
  searchFilter,
  selectedTags,
  userTagData,
  onItemSelected,
  books,
}) => {
  const [addTagToItem] = useMutation(INSERT_USER_BOOK_TAG);
  const [deleteTagToItem] = useMutation(
    DELETE_USER_BOOK_TAG
  );

  const onAddTagToItem = (userBookId, tagId) => {
    addTagToItem({
      variables: { userBookId, tagId },
      update(
        cache,
        { data: { insert_user_book_tag: returnData } }
      ) {
        updateCacheForBookTag(cache, returnData);
      },
      optimisticResponse: optimisticUIForBookTagAdd(
        userBookId,
        userTagData.find((e) => e.id === tagId)
      ),
    });
  };

  const onDeleteTagToItem = (id) => {
    deleteTagToItem({
      variables: { id },
      update(
        cache,
        { data: { delete_user_book_tag: returnData } }
      ) {
        updateCacheForBookTag(cache, returnData, false);
      },
    });
  };

  const onTagSelected =
    (userBookId) => (tagId, userBookTagId) => {
      const book = books.find((e) => e.id === userBookId);
      const tagIsMarked = book.user_book_tags.find(
        (e) => e.tag.id === tagId
      );
      if (tagIsMarked) {
        onDeleteTagToItem(userBookTagId);
      } else {
        onAddTagToItem(userBookId, tagId);
      }
    };

  const filterHelper = (e) => {
    let found = false;
    e.user_book_tags.forEach((x) => {
      if (selectedTags.includes(x.tag.id)) {
        found = true;
      }
    });
    return found;
  };

  const filteredBooks = books
    ? books.filter(
        (e) =>
          (e.book.title
            .toLowerCase()
            .includes(searchFilter) ||
            e.book.author
              .toLowerCase()
              .includes(searchFilter)) &&
          (selectedTags.length > 0 ? filterHelper(e) : true)
      )
    : null;

  const emptyPlaceholder = () => (
    <div className={stylesSCSS.emptyPlaceholderContainer}>
      <h2 className={stylesSCSS.emptyPlaceholderText}>
        Inga böcker hittades
      </h2>
    </div>
  );

  return (
    <Grid item xs className={classes.container}>
      <div className={stylesSCSS.headerContainer}>
        <h2 className={stylesSCSS.headerStyle}>Böcker</h2>
      </div>
      <section className={stylesSCSS.bookContainer}>
        {!books || (books && books.length === 0)
          ? emptyPlaceholder()
          : filteredBooks.map((item) => (
              <BookItem
                key={item.book.id}
                item={{ ...item.book }}
                tags={item.user_book_tags}
                userTagData={userTagData}
                onTagSelected={onTagSelected(item.id)}
                onItemSelected={() => {
                  onItemSelected(
                    item.book.id,
                    BOOK,
                    item.current_page
                  );
                }}
              />
            ))}
      </section>
    </Grid>
  );
};

BookContainer.propTypes = {
  classes: objectOf(shape),
  selectedTags: arrayOf(string).isRequired,
  searchFilter: string.isRequired,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  onItemSelected: func.isRequired,
};

BookContainer.defaultProps = {
  classes: null,
  userTagData: null,
};

export default withStyles(styles)(BookContainer);
