import { gql } from 'apollo-boost';

export default gql`
  mutation insertAnnotationByClassName(
    $pageId: uuid!
    $className: String!
  ) {
    insert_annotation_by_classname(
      objects: { page_id: $pageId, class_name: $className }
    ) {
      returning {
        id
        class_name
        page {
          page_number
        }
      }
    }
  }
`;
