const routes = Object.freeze({
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  STUDY_DESK: '/study-desk',
  LIBRARY: '/library',
  PAYMENT: '/payment',
  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_ERROR: '/payment-error',
});

export default routes;
