import { useLazyQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { GET_BOOK_TAGS } from '../graphql/queries';
import useError from './useError';

const useQueryGetBookTags = () => {
  const { handleError } = useError();

  const [getBookTags, { data }] = useLazyQuery(
    GET_BOOK_TAGS,
    {
      onError: () => {
        handleError(
          'Kunde inte hämta information om taggningsord... Vänligen försök igen'
        );
      },
    }
  );

  const newData = useMemo(
    () =>
      data?.bookTags.reduce((acc, bookTag) => {
        const index = acc.findIndex(
          (item) => item.book.id === bookTag.book.id
        );
        if (index === -1) {
          acc.push({
            book: bookTag.book,
            publicTagIds: [bookTag.publicTagId],
          });
        } else {
          const item = acc[index];
          item.publicTagIds.push(bookTag.publicTagId);
          acc[index] = item;
        }
        return acc;
      }, []),
    [data]
  );

  return [
    getBookTags,
    {
      data: newData,
    },
  ];
};

export default useQueryGetBookTags;
