import note from './note';
import pages from './pages';
import nonSummarizedAnnotations from './nonSummarizedAnnotations';
import allSummarizedAnnotations from './allSummarizedAnnotations';
import annotationsByClassName from './annotationsByClassName';
import userItems from './userItems';
import pageSearch from './pageSearch';
import chapter from './chapter';
import getBookTags from './getBookTags';
import getOrganisationByPublicTag from './getOrganisationByPublicTag';
import getUserSessionId from './getUserSessionId';
import getPublicTags from './getPublicTags';

export const GET_NOTE_CONTENT = note;
export const GET_PAGES = pages;
export const GET_NON_SUMMARIZED_ANNOTATIONS =
  nonSummarizedAnnotations;
export const GET_ALL_SUMMARIZED_ANNOTATIONS =
  allSummarizedAnnotations;
export const GET_ANNOTATIONS_BY_CLASS_NAME =
  annotationsByClassName;
export const GET_USER_ITEMS = userItems;
export const GET_PAGES_BY_TEXT_SEARCH = pageSearch;
export const GET_CHAPTERS = chapter;
export const GET_BOOK_TAGS = getBookTags;
export const GET_ORGANISATION_BY_PUBLIC_TAG =
  getOrganisationByPublicTag;
export const GET_USER_SESSION_ID = getUserSessionId;
export const GET_PUBLIC_TAGS = getPublicTags;
