import { gql } from 'apollo-boost';
import { NoteParts } from '../fragments';

export default gql`
  mutation updateNoteTitle($id: uuid!, $title: String!) {
    update_note(
      where: { id: { _eq: $id } }
      _set: { title: $title }
    ) {
      returning {
        ...NoteParts
      }
    }
  }
  ${NoteParts}
`;
