import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { func, shape, string } from 'prop-types';
import stylesScss from './ChangeTitleContainer.module.scss';

const ChangeTitleContainer = ({
  item,
  onChangeItemName,
  closeMenuItem,
}) => {
  const [newTitle, setNewTitle] = useState(item.title);

  const handelInputChange = ({ target }) => {
    setNewTitle(target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onChangeItemName(newTitle, item.id);
      closeMenuItem();
    }
  };

  return (
    <div className={stylesScss.container}>
      <div className={stylesScss.inputContainer}>
        <input
          id="tagInput"
          className={stylesScss.filterInput}
          type="text"
          value={newTitle}
          onChange={handelInputChange}
          onKeyPress={handleKeyDown}
          autoFocus
        />
        <div className={stylesScss.buttonContainer}>
          <Button
            onClick={() => {
              onChangeItemName(newTitle, item.id);
              closeMenuItem();
            }}
            className={stylesScss.saveButton}
          >
            Spara
          </Button>
        </div>
      </div>
    </div>
  );
};

ChangeTitleContainer.propTypes = {
  item: shape({
    id: string,
    title: string,
  }).isRequired,
  onChangeItemName: func.isRequired,
  closeMenuItem: func.isRequired,
};

export default ChangeTitleContainer;
