import { shape, string } from 'prop-types';

export const organisationModel = shape({
  zip_code: string,
  name: string,
  logo: string,
  id: string,
  color_scheme: string,
  city: string,
  address: string,
  abbreviation: string,
});
