import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  bool,
  func,
  number,
  shape,
  string,
} from 'prop-types';
import useStyles from './style';
import Spacer from '../../shared/Spacer';
import PageAmountIcon from '@material-ui/icons/DescriptionOutlined';

const LibraryListItem = ({
  item,
  loading,
  ButtonProps,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid
        item
        alignItems="flex-end"
        container
        xs
        spacing={2}
      >
        <Grid item xs={2}>
          <Box className={classes.containerCover}>
            {item.cover && (
              <img
                src={item.cover}
                className={classes.cover}
                alt={`${item.name} book cover`}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Grid item container>
            <Typography color="primary">
              {item.title}
            </Typography>
            <Spacer orientation="horizontal" />
            <Typography color="textSecondary">
              {item.author ?? item.publisher}
            </Typography>
          </Grid>
          <Spacer spacing={0.5} />
          <Grid item container alignItems="flex-end">
            <Typography
              variant="subtitle2"
              color="textSecondary"
            >
              <PageAmountIcon fontSize="inherit" />{' '}
              {`${item.pageAmount} sidor`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Button
          className={classes.buttonDownload}
          style={loading ? { pointerEvents: 'none' } : null}
          variant="contained"
          color="primary"
          size="large"
          {...ButtonProps}
        >
          {loading ? (
            <CircularProgress color="secondary" size={20} />
          ) : (
            ButtonProps.title
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

LibraryListItem.propTypes = {
  item: shape({
    author: string,
    id: string,
    pageAmount: number,
    publisher: string,
    title: string,
    cover: string,
  }),
  loading: bool,
  ButtonProps: shape({
    title: string,
    onClick: func,
    disabled: bool,
  }),
};

export default LibraryListItem;
