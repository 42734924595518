import React from 'react';

const MyNotesIcon = ({
  style = {},
  fill = '#000',
  width = '100%',
  className = '',
  viewBox = '0 0 32 32',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {/* <!-- Generator: Patrik Rikama - https://www.weappdevelopers.com/ --> */}
    <g
      fill="none"
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
    >
      <g
        stroke={fill}
        id="Group-5"
        transform="translate(1.000000, 1.000000)"
      >
        <g
          id="Group-4"
          transform="translate(3.000000, 1.000000)"
        >
          <path
            d="M0,3.52647059 C4.33888497,3.52647059 7.2785283,3.52647059 8.81893,3.52647059"
            id="Path-4"
            strokeWidth="1.6"
          />
          <path
            d="M0,6.96882353 L8.81893,6.96882353"
            id="Path-5"
            strokeWidth="1.6"
          />
          <path
            d="M0,10.5676471 L5.58495003,10.5676471"
            id="Path-6"
            strokeWidth="1.6"
          />
          <g
            id="Group-6"
            transform="translate(2.232880, 0.533149)"
          >
            <path
              d="M6.36047378,0.821524056 L8.52493144,14.8686139 L9.5331796,16.0508947 L10.0315827,14.8669886 L7.87381614,0.595890877 C7.57221971,0.246707959 7.29107487,0.111235533 7.00326401,0.131763338 C6.76854628,0.148504331 6.55558261,0.341051248 6.36047378,0.821524056 Z"
              id="Rectangle"
              strokeWidth="1.4"
              transform="translate(8.196896, 8.399527) rotate(45.000000) translate(-8.196896, -8.399527) "
            />
            <path
              d="M9.21626716,5.34037998 L9.9724138,6.38800092"
              id="Path-9"
              strokeWidth="2"
            />
          </g>
        </g>
        <path
          d="M14.9873234,8.92101673 L14.9873234,17.9090667 C15.0403274,18.7147615 14.9271139,19.2876138 14.6476827,19.6276236 C14.3682516,19.9676335 13.9226028,20.0748009 13.3107365,19.9491258 L1.84438256,19.9491258 C1.20533715,20.0748009 0.732355059,19.9676335 0.425436274,19.6276236 C0.118517488,19.2876138 -0.0223379876,18.7147615 0.00286984624,17.9090667 L0.00286984624,2.17197755 C-0.0223379876,1.40631073 0.118517488,0.843078904 0.425436274,0.48228207 C0.732355059,0.121485236 1.20533715,-0.0368766068 1.84438256,0.00719654139 L13.3107365,0.00719654139 C13.9226028,-0.0368766068 14.3682516,0.121485236 14.6476827,0.48228207 C14.9271139,0.843078904 15.0403274,1.40631073 14.9873234,2.17197755 C14.9873234,5.15685276 14.9873234,7.19945753 14.9873234,8.29979185"
          id="Path-10"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export default MyNotesIcon;
