import { node } from 'prop-types';
import React, { useEffect, useState } from 'react';
import useQueryGetUserOrganisation from '../../hooks/useQueryGetUserOrganisation';
import {
  COOKIE_KEY,
  getCookieValue,
  setCookie,
} from '../../utils/cookieHelper';
import OrganisationContext from './context';

export { OrganisationContext };

const Provider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentOrganisation, setCurrentOrganisation] =
    useState();

  const {
    userOrganisations,
    allOrganisations,
    getUserItems,
  } = useQueryGetUserOrganisation();

  const handleSetCurrentOrganisation = (orgId) => {
    setCurrentOrganisation(
      userOrganisations.find((org) => org.id === orgId)
    );
    setCookie(COOKIE_KEY.ORGANISATION, orgId);
  };

  useEffect(() => {
    if (currentOrganisation) {
      setIsLoading(false);
      return;
    }

    const currOrg = getCookieValue(COOKIE_KEY.ORGANISATION);
    if (
      currOrg &&
      !currentOrganisation &&
      userOrganisations?.length > 0 &&
      userOrganisations.some((org) => org.id === currOrg)
    ) {
      setCurrentOrganisation(
        userOrganisations.find((org) => org.id === currOrg)
      );
      return;
    }

    if (userOrganisations?.length > 0) {
      setCurrentOrganisation(userOrganisations[0]);
      return;
    }
    // TODO: Check this in future + FIXME in src/hooks/useQueryGetLibraryData.js on
    setIsLoading(false);
  }, [userOrganisations, currentOrganisation, isLoading]);

  return (
    <OrganisationContext.Provider
      value={{
        setCurrentOrganisation:
          handleSetCurrentOrganisation,
        currentOrganisation,
        userOrganisations,
        isLoading,
        allOrganisations,
        refetch: getUserItems,
      }}
    >
      {children}
    </OrganisationContext.Provider>
  );
};

Provider.propTypes = {
  children: node,
};

export default Provider;
