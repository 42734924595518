import { gql } from 'apollo-boost';

export default gql`
  mutation updateAnnotationByClassName(
    $pageId: uuid!
    $oldClassName: String!
    $newClassName: String!
  ) {
    update_annotation_by_classname(
      _set: { class_name: $newClassName }
      where: {
        class_name: { _eq: $oldClassName }
        _and: { page_id: { _eq: $pageId } }
      }
    ) {
      returning {
        id
      }
    }
  }
`;
