import React, { useEffect } from 'react';
import { node } from 'prop-types';
import SessionContext from './context';
import {
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import { GET_USER_SESSION_ID } from '../../graphql/queries';
import { getUserIdFromCookie } from '../../utils/jwtHelpers';
import {
  COOKIE_KEY,
  getCookieValue,
  setCookie,
} from '../../utils/cookieHelper';
import { v4 as uuidv4 } from 'uuid';
import { UPDATE_USER_SESSION_ID } from '../../graphql/mutations';
import useAuth from '../../hooks/useAuth';

export { SessionContext };

const SessionContextProvider = ({ children }) => {
  const { signOut } = useAuth();

  const [
    getSessionId,
    { data, called: isUserSessionCalled, stopPolling },
  ] = useLazyQuery(GET_USER_SESSION_ID, {
    pollInterval: 20000,
    fetchPolicy: 'network-only',
  });

  const onUpdateSessionIdCompleted = (data) => {
    if (
      data?.updateUser?.res &&
      !data?.updateUser?.res[0].sessionId
    )
      return;

    setCookie(
      COOKIE_KEY.SESSION_ID,
      data?.updateUser?.res[0].sessionId
    );
    if (!isUserSessionCalled) {
      const userId = getUserIdFromCookie();
      if (!userId) return;

      getSessionId({
        variables: {
          userId,
        },
      });
    }
  };

  const [updateSessionId] = useMutation(
    UPDATE_USER_SESSION_ID,
    {
      onCompleted: onUpdateSessionIdCompleted,
    }
  );

  useEffect(() => {
    const userId = getUserIdFromCookie();
    if (!userId) return;

    getSessionId({
      variables: {
        userId,
      },
    });
  }, [getSessionId]);

  useEffect(() => {
    if (!data?.user.sessionId) return;

    const sessionId = getCookieValue(COOKIE_KEY.SESSION_ID);
    if (sessionId && sessionId !== data?.user.sessionId) {
      alert(
        'Du har loggat in med ditt konto på en annan enhet... Du loggas nu ut.'
      );
      stopPolling();
      signOut();
      return;
    }
  }, [data, signOut, stopPolling]);

  const validateSession = () => {
    const sessionId = getCookieValue(COOKIE_KEY.SESSION_ID);

    if (!sessionId) {
      updateSessionId({
        variables: {
          sessionId: uuidv4(),
        },
      });
    }
  };

  const value = {
    validateSession,
  };

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: node,
};

export default SessionContextProvider;
