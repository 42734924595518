import variables from './variables.module.scss';

const theme = {
  palette: {
    primary: { main: variables.colorPrimary },
    secondary: { main: variables.colorSecondary },
  },
  typography: {
    fontFamily: 'Titillium',
    useNextVariants: true,
  },
  colors: {
    colorPrimary: '#30bfc5',
    colorSecondary: '#494c53',
    colorGreen: '#b9d538',
    colorDarkNav: '#535c5d',
    colorDarkAppBar: '#42464b',
    colorWhite: '#fff',
    colorBlack: '#000',
    colorMenuDivider: '#4d565a',
    colorExtraLightGrey: '#ecebec',
    colorLightGrey: '#c4c4c4',
    colorGrey: '#747474',
    colorDarkGrey: '#404040',
    summaryButtonsColor: '#6e767a',
    colorContainerLightGrey: '#d3d3d3',
    colorHighlightYellow: '#ffd21b',
    colorHighlightRed: '#ff7070',
    colorRed: '#ff0000',
    colorInputLabel: '#808080',
  },
  sizes: {
    appBarHeight: 64,
    verticalMenuWidth: 55,
    dashboardToolBarHeight: 70,
    bookContainerHeight: 354,
    dashboardButtonContainer: 70,
  },
};

export default theme;
