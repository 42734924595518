import ReactGA from 'react-ga';
import { CYAN, RED, YELLOW, COMMENT } from './constants';

const pageview = () => {
  if (typeof window !== 'undefined') {
    ReactGA.pageview(
      window.location.pathname + window.location.search
    );
  }
};

const getAnnotationType = (className) => {
  if (className.includes(CYAN)) return CYAN;
  if (className.includes(YELLOW)) return YELLOW;
  if (className.includes(RED)) return RED;
  if (className.includes(COMMENT)) return COMMENT;
};

const addAnnotationEvent = (className, book) => {
  ReactGA.event({
    category: `Annotation - Add`,
    action: getAnnotationType(className),
    label: book.book.title,
  });
  ReactGA.event({
    category: `Annotation - per page`,
    action: book.current_page,
    label: book.book.title,
  });
};

const removeAnnotationEvent = (className, book) => {
  ReactGA.event({
    category: `Annotation - Remove`,
    action: getAnnotationType(className),
    label: book.book.title,
    value: book.current_page,
  });
};

const visitBookPageEvent = (book) => {
  ReactGA.event({
    category: `Book page visit`,
    action: `Page: ${book.current_page}`,
    label: book.book.title,
  });
};

const summarizeEvent = ({
  bookTitle,
  pageNumber,
  amount,
}) => {
  ReactGA.event({
    category: 'Summarize - per page',
    action: pageNumber,
    label: bookTitle,
    value: amount,
  });
};

const helpers = {
  pageview,
  addAnnotationEvent,
  removeAnnotationEvent,
  visitBookPageEvent,
  summarizeEvent,
};

export default helpers;
