import { gql } from 'apollo-boost';

export default gql`
  fragment PageParts on page {
    id
    page_number
    content
    book_id
  }
`;
