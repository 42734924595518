import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { objectOf, oneOf } from 'prop-types';
import { func, shape, string } from 'prop-types';
import React from 'react';
import MyDocsIcon from '../../../assets/Icons/MyDocsIcon';
import MyNotesIcon from '../../../assets/Icons/MyNotesIcon';
import {
  BOOK,
  NOTE,
  UPLOAD,
} from '../../../utils/constants';
import variables from '../../../styles/variables.module.scss';
import { MenuBook } from '@material-ui/icons';

const styles = () => ({
  listItemTextContainer: {
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap !important',
    overflow: 'hidden !important',
    marginLeft: 8,
  },
  bookImg: {
    width: 60,
    minHeight: 80,
    height: '100%',
  },
  bookImgPlaceholder: {
    width: 55,
    height: '100%',
  },
});

const FastLibraryListItem = ({
  itemData,
  type,
  onClick,
  classes,
}) => {
  const getListItemIcon = (category, src) => {
    switch (category) {
      case BOOK:
        return src ? (
          <img
            src={src}
            alt="book cover"
            className={classes.bookImg}
          />
        ) : (
          <MenuBook
            className={classes.bookImgPlaceholder}
          />
        );
      case NOTE:
        return (
          <MyNotesIcon
            width={60}
            fill={variables.colorGrey}
          />
        );
      case UPLOAD:
        return (
          <MyDocsIcon
            width={50}
            fill={variables.colorGrey}
          />
        );
      default:
        return (
          <MyDocsIcon
            width={50}
            fill={variables.colorGrey}
          />
        );
    }
  };
  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon>
        {getListItemIcon(type, itemData.cover)}
      </ListItemIcon>
      <ListItemText
        classes={{
          root: classes.listItemTextContainer,
        }}
        primary={itemData.title ?? ''}
      />
    </ListItem>
  );
};

FastLibraryListItem.propTypes = {
  itemData: shape({
    cover: string,
    title: string,
  }),
  onClick: func,
  type: oneOf([BOOK, NOTE, UPLOAD]),
  classes: objectOf(shape),
};

export default withStyles(styles)(FastLibraryListItem);
