import { gql } from 'apollo-boost';
import { PublicTagParts } from '../fragments';

export default gql`
  query GetPublicTags($parentTagId: uuid!) {
    data: public_tag(
      where: { parent_tag: { _eq: $parentTagId } }
    ) {
      ...PublicTagParts
      publicTags: public_tags {
        ...PublicTagParts
        publicTags: public_tags {
          ...PublicTagParts
          publicTags: public_tags {
            ...PublicTagParts
            publicTags: public_tags {
              ...PublicTagParts
              publicTags: public_tags {
                ...PublicTagParts
                publicTags: public_tags {
                  ...PublicTagParts
                }
              }
            }
          }
        }
      }
    }
  }
  ${PublicTagParts}
`;
