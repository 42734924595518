import React from 'react';
import { func, string } from 'prop-types';
import { Button } from '@material-ui/core';
import stylesScss from './RemoveItemContainer.module.scss';

const RemoveItemContainer = ({
  title,
  onRemoveItem,
  closeMenuItem,
}) => (
  <div className={stylesScss.container}>
    <div className={stylesScss.headerContainer}>
      <p>Du håller på att radera följande:</p>
      <p className={stylesScss.title}>{title}</p>
    </div>
    <div className={stylesScss.buttonContainer}>
      <Button
        onClick={closeMenuItem}
        className={stylesScss.removeButton}
      >
        Avbryt
      </Button>
      <Button
        onClick={onRemoveItem}
        className={stylesScss.removeButton}
      >
        Fortsätt
      </Button>
    </div>
  </div>
);

RemoveItemContainer.propTypes = {
  title: string.isRequired,
  onRemoveItem: func.isRequired,
  closeMenuItem: func.isRequired,
};

export default RemoveItemContainer;
