import React, { useState } from 'react';
import {
  number,
  objectOf,
  shape,
  string,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import TextButton from '../Buttons/TextButton';
import ProfileButton from '../Buttons/ProfileButton';
import NavLogo from '../../assets/logo/EasyEducationNavLogo.svg';
import ProfileContainer from '../ProfileContainer';
import { Link, useLocation } from 'react-router-dom';
import useOrganisation from '../../hooks/useOrganisation';
import OrganisationSelect from '../OrganisationSelect';
import Spacer from '../shared/Spacer';
import useAuth from '../../hooks/useAuth';

const styles = withStyles((theme) => ({
  button: {
    color: theme.colors.colorWhite,
    textTransform: 'none',
    marginRight: '32px',
  },
  appBar: {
    backgroundColor: theme.colors.colorDarkAppBar,
  },
  containerLeft: {
    flex: 1,
    padding: 8,
    backgroundColor: theme.colors.colorDarkAppBar,
    display: 'flex',
  },
  logo: {
    display: 'block',
    cursor: 'pointer',
  },
  appBarContainer: {
    height: theme.sizes.appBarHeight,
    borderBottom: `1px solid ${theme.colors.colorExtraLightGrey}`,
    backgroundColor: theme.colors.colorDarkAppBar,
  },
}));

const NavigationBar = ({ elevation, classes }) => {
  const { signOut } = useAuth();
  const location = useLocation();

  const [profileOpen, setProfileOpen] = useState(false);
  const {
    userOrganisations,
    setCurrentOrganisation,
    currentOrganisation,
  } = useOrganisation();

  const onToggleProfile = () => {
    setProfileOpen(!profileOpen);
  };

  const isHidden =
    typeof window !== 'undefined' &&
    location.pathname === '/';

  return (
    <Grid item xs hidden={isHidden}>
      <AppBar
        position="relative"
        color="inherit"
        elevation={elevation}
        classes={{ root: classes.appBar }}
      >
        <Toolbar>
          <div className={classes.containerLeft}>
            <Link to="/dashboard">
              <img
                className={classes.logo}
                src={NavLogo}
                alt="EE_LOGO"
              />
            </Link>
            {!!currentOrganisation && (
              <>
                <Spacer
                  spacing={2}
                  orientation="horizontal"
                />
                <OrganisationSelect
                  disabled={
                    !userOrganisations ||
                    userOrganisations.length === 1
                  }
                  value={currentOrganisation.id}
                  onChange={(e) =>
                    setCurrentOrganisation(e.target.value)
                  }
                  data={userOrganisations}
                />
              </>
            )}
          </div>
          <TextButton
            component={Link}
            className={classes.button}
            to="/library"
          >
            Bibliotek
          </TextButton>
          <TextButton
            component={Link}
            className={classes.button}
            to="/dashboard"
          >
            Mitt bibliotek
          </TextButton>
          <ProfileButton
            onToggleProfile={onToggleProfile}
            onLogout={signOut}
          />
        </Toolbar>
        {profileOpen && (
          <ProfileContainer handleClose={onToggleProfile} />
        )}
      </AppBar>
    </Grid>
  );
};

NavigationBar.propTypes = {
  classes: objectOf(shape),
  elevation: number,
  location: shape({
    pathname: string,
  }),
};

NavigationBar.defaultProps = {
  classes: null,
  elevation: 0,
};

export default styles(NavigationBar);
