import React from 'react';

const MyDocsIcon = ({
  style = {},
  fill = '#000',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 32 32',
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {/* <!-- Drawn by Patrik Rikama https://www.weappdevelopers.com/ --> */}
    <title>Group 7</title>
    <desc>Created with Sketch.</desc>
    <g
      fill={fill}
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
    >
      <g
        fill="none"
        id="Artboard"
        transform="translate(-24.000000, -26.000000)"
        stroke={fill}
      >
        <g
          id="Group-7"
          transform="translate(26.000000, 28.000000)"
        >
          <path
            fill={fill}
            d="M3.66666667,19.74745 L10.4927167,19.74745"
            id="Path-6"
            strokeWidth="1.92"
          />
          <path
            fill={fill}
            d="M3.66666667,14.9814925 L10.4927167,14.9814925"
            id="Path-6"
            strokeWidth="1.92"
          />
          <path
            fill={fill}
            d="M3.66666667,10.2155351 L10.4927167,10.2155351"
            id="Path-6"
            strokeWidth="1.92"
          />
          <path
            fill="none"
            d="M18.3178397,14.2110394 L18.3178397,17.675604 L18.3178397,24.9202053 C18.3826224,25.880182 18.2442503,26.5627295 17.9027233,26.9678476 C17.5611964,27.3729657 17.0165146,27.5006545 16.268678,27.350914 L2.25424535,27.350914 C1.47318986,27.5006545 0.895100628,27.3729657 0.519977668,26.9678476 C0.144854708,26.5627295 -0.0273019849,25.880182 0.00350758985,24.9202053 L0.00350758985,6.16963097 C-0.0273019849,5.2573471 0.144854708,4.58626238 0.519977668,4.15637679 C0.895100628,3.7264912 1.47318986,3.53780475 2.25424535,3.59031743 L15.0464558,3.59031743 L18.3178397,7.36112033 C18.3178397,10.9175674 18.3178397,13.3513092 18.3178397,14.6623459"
            id="Path-10"
            strokeWidth="2.4"
          />
          <path
            fill="none"
            d="M3.66666667,2.59516289 C3.67649156,1.68430812 3.84981745,1.01322339 4.18664433,0.581908701 C4.52347122,0.150594011 5.0237991,-0.0409506409 5.68762798,0.00727474501 L18.7301709,0.00727474501 L22,3.79744168 L22,22.4198506 C21.8632458,22.9426221 21.6203565,23.3221088 21.2713323,23.5583107 C20.9223081,23.7945126 20.4671489,23.8874297 19.9058547,23.837062 L18.1221589,23.837062"
            id="Path-11"
            strokeWidth="2.4"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default MyDocsIcon;
