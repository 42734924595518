import React from 'react';
import {
  objectOf,
  shape,
  func,
  number,
  arrayOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PageThumbnailIcon from '../../../assets/Icons/PageThumbnailIcon';
import variables from '../../../styles/variables.module.scss';

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '240px',
    margin: '0 auto',
  },
  container: {
    flex: '0.8',
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '64px',
    paddingTop: '12px',
    justifyContent: 'space-between',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px',
    '&:hover': {
      opacity: 0.4,
    },
  },
  pageNum: {
    alignSelf: 'center',
    margin: '4px 0px 0px 0px',
  },
  thumbnail: {
    width: '44px',
  },
});

const ThumbnailList = ({
  classes,
  navToPage,
  pageList,
  startPageOffset,
  currentPageNumber,
}) => (
  <div className={classes.wrapper}>
    <div className={classes.container}>
      {pageList?.map(({ page_number: pageNumber }) => (
        <div
          className={classes.pageContainer}
          key={pageNumber}
          onClick={() => {
            navToPage(pageNumber);
          }}
          role="button"
        >
          <PageThumbnailIcon
            width={52}
            height={58}
            fill={
              currentPageNumber === pageNumber
                ? variables.colorPrimary
                : variables.colorWhite
            }
          />
          <p className={classes.pageNum}>
            {pageNumber - startPageOffset}
          </p>
        </div>
      ))}
    </div>
  </div>
);

ThumbnailList.propTypes = {
  classes: objectOf(shape),
  navToPage: func,
  startPageOffset: number,
  pageList: arrayOf(
    shape({
      page_number: number,
    })
  ),
  currentPageNumber: number,
};

ThumbnailList.defaultProps = {
  classes: null,
  navToPage: null,
  startPageOffset: null,
};

export default withStyles(styles)(ThumbnailList);
