import React, {
  useState,
  createRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  objectOf,
  shape,
  string,
  arrayOf,
  oneOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import rangy from 'rangy/lib/rangy-core';
import {
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import BookTool from '../BookTool';
import CommentTool from '../CommentTool';
import PageHeader from '../PageHeader';
import 'rangy/lib/rangy-selectionsaverestore';
import highlighterHelper from '../../../utils/highlightHelper';
import { GET_PAGES } from '../../../graphql/queries';
import {
  INSERT_USER_BOOK_TAG,
  DELETE_USER_BOOK_TAG,
} from '../../../graphql/mutations';
import Loading from '../../Helpers/Loading';
import Error from '../../Helpers/Error';
import {
  updateCacheForBookTag,
  optimisticUIForBookTagAdd,
} from '../../../utils/apolloHelpers';
import PageButton from '../../Buttons/PageButton';
import styles from './style';
import GA from '../../../utils/gaHelper';
import { Box } from '@material-ui/core';
import useStudyDeskContext from '../../../hooks/useStudyDeskContext';
import { LEFT, RIGHT } from '../../../utils/constants';
import Mark from 'mark.js';

const baseCss =
  'https://firebasestorage.googleapis.com/v0/b/easyeducation-f9cd0.appspot.com/o/parser-config%2Fbase.min.css?alt=media&token=e278e55e-df4c-405f-99ae-1f695d1f533c';

const errorPage = `
    <h3>Oj då, denna sidan kunde tyvärr inte laddas in. Vänligen försök igen eller kontakta oss.</h3>
`;

const pageOffset = 5; // How many pages left until fetching more
const limit = 20;

const Book = ({ classes, userTagData, paneSide }) => {
  const {
    onUpdateCurrentPage,
    currentSearchData,
    onUpdateSearchData,
    leftPane,
    rightPane,
  } = useStudyDeskContext();

  const {
    searchValue: currentSearchString,
    pages: searchDataPages,
  } = currentSearchData ?? {};

  const book = useMemo(() => {
    if (!paneSide) return {};

    const pane = paneSide === LEFT ? leftPane : rightPane;

    return pane.content;
  }, [leftPane, rightPane, paneSide]);

  const [isBookToolVisible, setIsBookToolVisible] =
    useState(false);
  const [isCommentToolVisible, setIsCommentToolVisible] =
    useState(false);
  const [markedText, setMarkedText] = useState(null);
  const [textCoordinates, setTextCoordinates] = useState(
    {}
  );
  const [target, saveTarget] = useState();
  const [highlightID, saveHighlightID] = useState(null);
  const [hasHighlight, setHasHighlight] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [canUserSearch, setCanUserSearch] = useState(true);
  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [morePagesLoading, setMorePagesLoading] =
    useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [pagesLoading, setPagesLoading] = useState(true);

  const pageWrapperId = `pageWrapper-${paneSide}`;

  rangy.init();
  const childRef = createRef();

  const [
    getPages,
    { data: pagesData, error, loading, fetchMore },
  ] = useLazyQuery(GET_PAGES);

  const highlightSearchValue = useCallback(() => {
    const pageWrapper =
      document.getElementById(pageWrapperId);
    const instance = new Mark(pageWrapper);
    instance.mark(currentSearchString, {
      acrossElements: true,
    });
  }, [currentSearchString, pageWrapperId]);

  /* Handle highlighting search word. I.e. highlights the word
   * that the user has entered in the fast nav search input */
  useEffect(() => {
    if (!currentSearchString || !book || !pagesData) return;

    const page = pagesData.page.find(
      (e) => e.page_number === book.current_page
    );
    const pageElement =
      document.getElementById(pageWrapperId);

    if (!pageElement || !page) return;

    if (currentSearchString === -1) {
      onUpdateSearchData('searchValue', '');
      onUpdateSearchData('pages', undefined);

      pageElement.innerHTML = page?.content ?? errorPage;
      return;
    }

    highlightSearchValue();
    childRef.current?.setAnnotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSearchString,
    pageWrapperId,
    book,
    pagesData,
    onUpdateSearchData,
  ]);

  useEffect(() => {
    setPagesLoading(loading);
  }, [loading]);

  const [addTagToItem] = useMutation(INSERT_USER_BOOK_TAG);
  const [deleteTagToItem] = useMutation(
    DELETE_USER_BOOK_TAG
  );

  const onAddTagToItem = (tagId) => {
    const userBookId = book.id;
    addTagToItem({
      variables: { userBookId, tagId },
      optimisticResponse: optimisticUIForBookTagAdd(
        userBookId,
        userTagData.find((e) => e.id === tagId)
      ),
      update(
        cache,
        { data: { insert_user_book_tag: returnData } }
      ) {
        updateCacheForBookTag(cache, returnData);
      },
    });
  };

  const onDeleteTagToItem = (id) => {
    deleteTagToItem({
      variables: { id },
      update(
        cache,
        { data: { delete_user_book_tag: returnData } }
      ) {
        updateCacheForBookTag(cache, returnData, false);
      },
    });
  };

  const onTagSelected = (tagId, userBookTagId) => {
    const tagIsMarked = book.user_book_tags.find(
      (e) => e.tag.id === tagId
    );
    if (tagIsMarked) {
      onDeleteTagToItem(userBookTagId);
    } else {
      onAddTagToItem(tagId);
    }
  };

  const skipQuery = (page) =>
    pagesData &&
    pagesData.page.filter((e) => e.page_number === page)
      .length > 0;

  const fetchMoreFromFastNav = () => {
    setMorePagesLoading(true);
    setPagesLoading(true);
    fetchMore({
      variables: {
        offset:
          book.current_page - limit > 0
            ? book.current_page - limit
            : 0,
        limit: limit * 2,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setMorePagesLoading(false);
        setPagesLoading(false);
        if (!fetchMoreResult) return prev;
        const obj = Object.assign({}, prev, {
          page: [...prev.page, ...fetchMoreResult.page],
        });
        obj.page.sort((a, b) => {
          if (a.page_number < b.page_number) return -1;
          if (a.page_number > b.page_number) return 1;
          return 0;
        });
        const unique = [];
        const map = new Map();
        obj.page.forEach((item) => {
          if (!map.has(item.id)) {
            map.set(item.id, true);
            unique.push(item);
          }
        });
        obj.page = unique;
        return obj;
      },
    });
  };

  const renderPage = useCallback(() => {
    GA.visitBookPageEvent(book);
    if (!pagesData?.page) {
      return;
    }
    const page = pagesData.page.find(
      (e) => e.page_number === book.current_page
    );

    if (!page) return;

    const pageWrapper =
      document.getElementById(pageWrapperId);
    pageWrapper.innerHTML = page?.content ?? errorPage;

    if (
      searchDataPages?.some(
        (p) => p.page_number === page.page_number
      ) &&
      page?.content &&
      currentSearchString
    ) {
      highlightSearchValue();
    }
    childRef.current?.setAnnotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    book,
    pagesData,
    pageWrapperId,
    currentSearchString,
    searchDataPages,
  ]);

  useEffect(() => {
    // The query is called here because to first check if pagesData exists
    // and when page has been selected from fast nav
    if (
      !skipQuery(book.current_page) &&
      !morePagesLoading
    ) {
      if (fetchMore) {
        fetchMoreFromFastNav();
      } else {
        getPages({
          variables: {
            bookId: book.book.id,
            offset:
              book.current_page - limit > 0
                ? book.current_page - limit
                : 0,
            limit: limit * 2,
          },
        });
      }
    }
    if (pagesData) {
      renderPage();
    }
  }, [book?.current_page]);

  useEffect(() => {
    if (!!pagesData && !pagesLoading) {
      renderPage();
    }
  }, [pagesData, pagesLoading, renderPage]);

  const fetchMorePages = (offset) => {
    setMorePagesLoading(true);
    fetchMore({
      variables: {
        offset,
        limit,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setMorePagesLoading(false);
        if (!fetchMoreResult) return prev;
        const obj = Object.assign({}, prev, {
          page: [...prev.page, ...fetchMoreResult.page],
        });
        obj.page.sort((a, b) => {
          if (a.page_number < b.page_number) return -1;
          if (a.page_number > b.page_number) return 1;
          return 0;
        });
        return obj;
      },
    });
  };

  const scroll = () => {
    const el = document.getElementById(pageWrapperId);
    if (el) {
      el.scrollIntoView();
    }
  };

  const onLeftClick = () => {
    scroll();
    if (!morePagesLoading && book.current_page > 1) {
      const nextPage = book.current_page - pageOffset;

      if (!skipQuery(nextPage)) {
        if (nextPage - limit < 0) {
          fetchMorePages(0);
        } else {
          fetchMorePages(nextPage - limit);
        }
      }
      onUpdateCurrentPage(paneSide, book.current_page - 1);
    }
  };

  const onRightClick = () => {
    scroll();
    if (
      !morePagesLoading &&
      book.current_page < book.book.page_amount
    ) {
      const nextPage = book.current_page + pageOffset;

      if (!skipQuery(nextPage)) {
        if (nextPage + limit > book.book.page_amount) {
          fetchMorePages(
            nextPage - book.book.page_amount - 1
          );
        }
        fetchMorePages(nextPage - 1);
      }
      onUpdateCurrentPage(paneSide, book.current_page + 1);
    }
  };

  const openBookTool = () => {
    setIsBookToolVisible(true);
  };

  const closeBookTool = (resetCoordinates = true) => {
    if (resetCoordinates) {
      setTextCoordinates({});
    }
    setIsBookToolVisible(false);
  };

  const openCommentTool = () => {
    setIsCommentToolVisible(true);
  };

  const getSelectedText = () => {
    let text = '';
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (
      document.selection &&
      document.selection.type !== 'Control'
    ) {
      text = document.selection.createRange();
    }
    setMarkedText(text.trim());
  };

  const showCoords = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    setTextCoordinates({ x, y });
  };

  const removeCommentHighlights = () => {
    const allCommentNodes = document.getElementsByClassName(
      highlighterHelper.comment
    );
    const allLastCommentNodes =
      document.getElementsByClassName(
        highlighterHelper.lastComment
      );
    Object.values([
      ...allCommentNodes,
      ...allLastCommentNodes,
    ]).map((node) => {
      if (node.id === highlightID) {
        node.removeAttribute('id');
        node.removeAttribute('class');
        node.replaceWith(node.innerHTML);
      }
      return null;
    });
  };

  const saveSelection = () => {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    } else if (
      document.selection &&
      document.selection.createRange
    ) {
      return document.selection.createRange();
    }
    return null;
  };

  const restoreSelection = (range) => {
    if (range) {
      if (window.getSelection) {
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (document.selection && range.select) {
        range.select();
      }
    }
  };

  const selectionRange = saveSelection();

  const currentPage = useMemo(
    () =>
      pagesData?.page?.find(
        (e) => e.page_number === book.current_page
      ),
    [pagesData, book]
  );

  const getComments = () => {
    if (!currentPage?.annotations) {
      return null;
    }
    const commentsArray = [];
    currentPage.annotations.forEach((e) =>
      commentsArray.push(...e.comments)
    );
    return commentsArray;
  };

  const onMouseDown = () => {
    setMouseDown(true);
    window.getSelection().removeAllRanges();
  };

  const pdf = () => (
    <Paper
      classes={{ root: classes.paper }}
      className={pagesLoading ? classes.paperLoading : null}
    >
      <Error message={error && error.message} />
      <PageHeader
        item={{
          title: book.book.title,
          author: book.book.author,
        }}
        tags={book.user_book_tags}
        userTagData={userTagData}
        onTagSelected={onTagSelected}
      />
      <Loading loading={pagesLoading}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onMouseDown={onMouseDown}
          onMouseUp={(e) => {
            if (!mouseDown) {
              return;
            }
            setMouseDown(false);
            getSelectedText();
            showCoords(e);
            const selection = window.getSelection();

            const isSelection =
              selection.toString().length > 0;
            const { anchorNode, focusNode } =
              window.getSelection();
            const { className } = e.target;

            saveHighlightID(e.target.id);
            saveTarget(e.target);

            const isNode = (type) =>
              highlighterHelper.isHighlightNode(
                type.parentElement.className
              );
            const containsNode = () =>
              highlighterHelper.containsNode(selection);
            const isColorNode =
              highlighterHelper.isColorNode(className);
            const isCommentNode =
              highlighterHelper.isCommentNode(className);
            const isLastCommentNode =
              highlighterHelper.isLastComment(className);
            if (isSelection) {
              setCanUserSearch(true);
              setShowTrashIcon(false);
              if (
                isNode(anchorNode) ||
                isNode(focusNode) ||
                containsNode()
              ) {
                setHasHighlight(true);
                setIsEditMode(true);
              } else {
                setHasHighlight(false);
                setIsEditMode(false);
              }
              openBookTool();
            } else {
              setCanUserSearch(false);
              setShowTrashIcon(true);
              setHasHighlight(false);
              if (isColorNode) {
                openBookTool();
                setIsEditMode(true);
              } else if (
                isCommentNode ||
                isLastCommentNode
              ) {
                openCommentTool();
              }
            }
          }}
        >
          <link rel="stylesheet" href={baseCss} />
          <link rel="stylesheet" href={book.book.styling} />
          <div id={paneSide} className={classes.content}>
            <div id={pageWrapperId} />
          </div>
        </div>
      </Loading>
    </Paper>
  );

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.bookContainer}>
        <BookTool
          paneSide={paneSide}
          isBookToolVisible={isBookToolVisible}
          closeBookTool={closeBookTool}
          openCommentTool={openCommentTool}
          textCoordinates={textCoordinates}
          markedText={markedText}
          currentTarget={target}
          highlightID={highlightID}
          hasHighlight={hasHighlight}
          isEditMode={isEditMode}
          canUserSearch={canUserSearch}
          showTrashIcon={showTrashIcon}
          ref={childRef}
          currentPage={currentPage}
          annotations={currentPage?.annotations}
          book={book}
        />
        <CommentTool
          comments={getComments()}
          isCommentToolVisible={isCommentToolVisible}
          setIsCommentToolVisible={() =>
            setIsCommentToolVisible(false)
          }
          textCoordinates={textCoordinates}
          setTextCoordinates={setTextCoordinates}
          onDeleteComment={() => {
            removeCommentHighlights();
            childRef.current.removeComment();
          }}
          highlightID={highlightID}
          saveComment={(currentComment) => {
            restoreSelection(selectionRange);
            childRef.current.saveComment(currentComment);
            setIsCommentToolVisible(false);
          }}
        />
        {!pagesLoading && (
          <Box className={classes.containerPageButton}>
            <PageButton
              onClick={onLeftClick}
              icon={ArrowLeft}
            />
          </Box>
        )}
        {pdf()}
        {!pagesLoading && (
          <Box className={classes.containerPageButton}>
            <PageButton
              onClick={onRightClick}
              icon={ArrowRight}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

Book.propTypes = {
  classes: objectOf(shape).isRequired,
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  paneSide: oneOf([LEFT, RIGHT]),
};

Book.defaultProps = {
  userTagData: null,
};

export default withStyles(styles)(Book);
