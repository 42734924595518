import React from 'react';
import { string, node } from 'prop-types';
import Button from '@material-ui/core/Button';

const TextButton = ({
  variant,
  size,
  children,
  ...props
}) => (
  <Button variant={variant} size={size} {...props}>
    {children}
  </Button>
);

TextButton.propTypes = {
  variant: string,
  size: string,
  children: node,
};

TextButton.defaultProps = {
  variant: null,
  size: 'large',
  children: null,
};

export default TextButton;
