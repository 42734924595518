import { useQuery } from '@apollo/react-hooks';
import { func } from 'prop-types';
import { useMemo, useState } from 'react';
import { GET_USER_ITEMS } from '../graphql/queries';
import useAuth from './useAuth';
import useError from './useError';
import useQueryGetLibraryData from './useQueryGetLibraryData';

const initialData = {
  userBooks: [],
  userNotes: [],
  userUploads: [],
  userTags: [],
  userPublicTags: [],
};

const useQueryGetUserItems = ({ onCompleted } = {}) => {
  const { signOut } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const { handleError } = useError();
  const { data: dataUserItems, loading: loadingUserItems } =
    useQuery(GET_USER_ITEMS, {
      onError: () =>
        handleError('Kunde inte hämta användardata...'),
    });

  const { bookTagsData } = useQueryGetLibraryData();

  const userData = useMemo(() => {
    if (dataUserItems?.user?.length === 0) {
      signOut();
    }
    if (loadingUserItems || !bookTagsData) {
      return initialData;
    }

    const {
      userBooks,
      userNotes,
      userUploads,
      userTags,
      userPublicTags,
    } = dataUserItems.user[0];

    const data = {
      userBooks: userBooks.filter((userBook) =>
        bookTagsData.some(
          (bookTag) => bookTag.book.id === userBook.book.id
        )
      ),
      userNotes,
      userUploads,
      userTags,
      userPublicTags,
    };

    if (onCompleted) {
      onCompleted(data);
    }
    setIsLoading(false);
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUserItems, bookTagsData, loadingUserItems]);

  return {
    userData,
    isLoading,
  };
};

useQueryGetUserItems.propTypes = {
  onCompleted: func,
};

export default useQueryGetUserItems;
