import gql from 'graphql-tag';
import { BookParts } from '../fragments';

export default gql`
  query GetBookTags {
    bookTags: book_tag {
      bookId: book_id
      id
      publicTagId: public_tag_id
      book {
        ...BookParts
      }
    }
  }
  ${BookParts}
`;
