import { useLazyQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import {
  GET_ORGANISATION_BY_PUBLIC_TAG,
  GET_USER_ITEMS,
} from '../graphql/queries';
import { USER_TYPE } from '../utils/constants';
import { getUserIdFromCookie } from '../utils/jwtHelpers';
import useError from './useError';

const useQueryGetUserOrganisation = () => {
  const { handleError } = useError();
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState();

  const [userOrganisations, setUserOrganisations] =
    useState([]);

  const [allOrganisations, setAllOrganisations] = useState(
    []
  );

  const stop = () => {
    setIsLoading(false);
    return;
  };

  const onUserItemsQueryCompleted = (data) => {
    const { userPublicTags, role } = data.user[0];
    if (!userPublicTags) {
      stop();
    }
    setUserType(role);
    getUserOrganisations({
      variables: {
        where:
          role === USER_TYPE.FREE
            ? {}
            : {
                _or: userPublicTags.map((tag) => ({
                  public_tag: { id: { _eq: tag.data.id } },
                })),
              },
      },
    });
  };

  const onGetUserOrgCompleted = ({
    organisation: userOrgs,
  }) => {
    if (!userOrgs) {
      stop();
    }
    if (userType === USER_TYPE.FREE) {
      setAllOrganisations(userOrgs);
    } else {
      setUserOrganisations((prevState) =>
        prevState.concat(userOrgs)
      );
    }
    setIsLoading(false);
  };

  const [getUserItems] = useLazyQuery(GET_USER_ITEMS, {
    onError: () => {
      handleError(
        'Kunde inte hämta användarinformation...'
      );
      stop();
    },
    onCompleted: onUserItemsQueryCompleted,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const userId = getUserIdFromCookie();
    if (!userId) {
      stop();
    }
    getUserItems();
  }, [getUserItems]);

  const [getUserOrganisations] = useLazyQuery(
    GET_ORGANISATION_BY_PUBLIC_TAG,
    {
      onError: () => {
        handleError('Kunde inte användarorganisationer...');
        stop();
      },
      onCompleted: onGetUserOrgCompleted,
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    getUserItems,
    loading: isLoading,
    userOrganisations,
    allOrganisations,
  };
};

export default useQueryGetUserOrganisation;
