import { gql } from 'apollo-boost';
import { UploadParts } from '../fragments';

export default gql`
  mutation updateUploadTitle($id: uuid!, $title: String!) {
    update_upload(
      where: { id: { _eq: $id } }
      _set: { title: $title }
    ) {
      returning {
        ...UploadParts
      }
    }
  }
  ${UploadParts}
`;
