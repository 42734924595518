import React, { useState } from 'react';
import {
  objectOf,
  shape,
  func,
  number,
  bool,
  string,
  arrayOf,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Popover from '@material-ui/core/Popover';
import { useMutation } from '@apollo/react-hooks';
import variables from '../../../styles/variables.module.scss';
import stylesScss from './CommentTool.module.scss';
import { UPDATE_COMMENT } from '../../../graphql/mutations';

const styles = () => ({
  iconProps: {
    color: variables.colorWhite,
    backgroundColor: variables.colorLightGrey,
  },
  commentStyle: {
    width: '15em',
  },
  addCommentButton: {
    flex: 1,
    background: variables.colorExtraLightGrey,
    color: variables.colorGrey,
    marginTop: '1em',
    textTransform: 'none',
  },
  deleteButton: {
    flex: 1,
    marginLeft: '12px',
    background: variables.colorExtraLightGrey,
    color: variables.colorGrey,
    marginTop: '1em',
    textTransform: 'none',
  },
});

const CommentTool = ({
  classes,
  isCommentToolVisible,
  setIsCommentToolVisible,
  textCoordinates,
  setTextCoordinates,
  onDeleteComment,
  highlightID,
  saveComment,
  comments,
}) => {
  const currentSavedComment =
    comments &&
    comments.find((e) => e.element_id === highlightID);
  const [currentComment, setCurrentComment] = useState('');
  const [updateComment] = useMutation(UPDATE_COMMENT);

  const closeCommentTool = () => {
    setIsCommentToolVisible();
    setCurrentComment('');
    setTextCoordinates({});
  };

  const deleteComment = () => {
    onDeleteComment();
    closeCommentTool();
  };

  return (
    <Popover
      open={Boolean(isCommentToolVisible)}
      onClose={() => {
        setCurrentComment('');
        closeCommentTool();
      }}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: textCoordinates.y || 0,
        left: textCoordinates.x || 0,
      }}
    >
      <div className={stylesScss.commentContainer}>
        <Input
          classes={{ root: classes.commentStyle }}
          placeholder="Lägg till din kommentar här"
          autoFocus
          fullWidth
          rows={10}
          multiline
          defaultValue={
            (currentSavedComment &&
              currentSavedComment.content) ||
            currentComment
          }
          onChange={(event) =>
            setCurrentComment(event.target.value)
          }
        />
        <div className={stylesScss.buttonContainers}>
          <Button
            variant="contained"
            onClick={deleteComment}
            className={classes.addCommentButton}
          >
            Radera
          </Button>
          <Button
            disabled={!currentComment}
            variant="contained"
            onClick={() => {
              const foundComment = comments.find(
                (e) => e.element_id === highlightID
              );
              if (foundComment) {
                updateComment({
                  variables: {
                    content: currentComment,
                    elementId: foundComment.element_id,
                  },
                });
              } else {
                saveComment(currentComment);
              }
              closeCommentTool();
            }}
            className={classes.deleteButton}
          >
            Spara
          </Button>
        </div>
      </div>
    </Popover>
  );
};

CommentTool.propTypes = {
  classes: objectOf(shape).isRequired,
  isCommentToolVisible: bool.isRequired,
  textCoordinates: shape({
    x: number,
    y: number,
  }).isRequired,
  setTextCoordinates: func.isRequired,
  setIsCommentToolVisible: func.isRequired,
  onDeleteComment: func,
  highlightID: string,
  saveComment: func,
  comments: arrayOf(
    shape({
      elemnent_id: string,
      content: string,
    })
  ),
};

CommentTool.defaultProps = {
  onDeleteComment: null,
  highlightID: null,
  saveComment: null,
  comments: null,
};

export default withStyles(styles)(CommentTool);
