import React from 'react';
import variables from '../../../styles/variables.module.scss';

const UploadIcon = ({
  style,
  fill = variables.colorGrey,
  width = '64',
  height = '64',
  className,
  viewBox = '0 0 512 512',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
  >
    <g>
      <g id="_x31_003_x2C__file_x2C__text_x2C__data_x2C__report">
        <g>
          <path
            fill={fill}
            d="m416.01 111.001c0-2.953-1.279-5.607-3.315-7.438-79.79-79.729-74.486-74.617-75.64-75.499-.382-.294-.893-.637-1.364-.889-.673-.364-1.807-.793-2.748-.981-1.45-.289 11.394-.162-226.952-.193-5.523 0-10 4.477-10 10v440c0 5.523 4.477 10 10 10h300c5.522 0 10-4.477 10-10-.001-.113.019-364.853.019-365zm-34.162-10h-40.858v-40.857zm-265.858 365v-420h205v65c0 5.522 4.477 10 10 10h65v345z"
            data-original="#000000"
            className="active-path"
            data-old_color="#000000"
          />
          <path
            fill={fill}
            d="m155.99 176.001h100c5.523 0 10-4.478 10-10 0-5.523-4.478-10-10-10h-100c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10z"
            data-original="#000000"
            className="active-path"
            data-old_color="#000000"
          />
          <path
            fill={fill}
            d="m355.99 196.001h-200c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10h200c5.522 0 10-4.478 10-10 0-5.523-4.477-10-10-10z"
            data-original="#000000"
            className="active-path"
            data-old_color="#000000"
          />
          <path
            fill={fill}
            d="m355.99 236.001h-200c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10h200c5.522 0 10-4.477 10-10s-4.477-10-10-10z"
            data-original="#000000"
            className="active-path"
            data-old_color="#000000"
          />
          <path
            fill={fill}
            d="m355.99 276.001h-200c-5.523 0-10 4.478-10 10 0 5.523 4.477 10 10 10h200c5.522 0 10-4.477 10-10s-4.477-10-10-10z"
            data-original="#000000"
            className="active-path"
            data-old_color="#000000"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default UploadIcon;
