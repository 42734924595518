import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
} from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { objectOf, shape } from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import variables from '../../styles/variables.module.scss';
import TextButton from '../../components/Buttons/TextButton';
import eeLogo from '../../assets/logo/EasyEducationNavLogoPrimary.svg';
import DefaultTextInput from '../../components/Inputs/DefaultTextInput';
import { validateEmail } from '../../utils/validator';
import Error from '../../components/Helpers/Error/Error';
import Loading from '../../components/Helpers/Loading/Loading';
import { ENDPOINTS, FETCH_CONFIG } from '../../config';
import { getUserIdFromCookie } from '../../utils/jwtHelpers';
import {
  COOKIE_KEY,
  getCookieValue,
  setTokenCookie,
} from '../../utils/cookieHelper';
import useSession from '../../hooks/useSession';
import { useNavigate } from 'react-router-dom';
import bgImage from '../../assets/backgrounds/bakgrund1920x1080blue.jpg';

const styles = () => ({
  containerInner: {
    minHeight: 400,
    padding: '2em',
    flex: 1,
  },
  loginButton: {
    color: variables.colorWhite,
  },
  container: {
    background: `url(${bgImage})`,
    height: '100vh',
    backgroundSize: 'cover',
  },
  logo: {
    margin: '0 8px',
  },
  singingLoading: {
    display: 'flex',
    flex: 1,
  },
});

const LoginPage = ({ classes }) => {
  const navigate = useNavigate();

  const { validateSession } = useSession();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] =
    useState(false);
  const [isPasswordReseted, setIsPasswordReseted] =
    useState(false);
  const [loginError, setLoginError] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [allowingCookies, setAllowingCookies] = useState(
    getCookieValue(COOKIE_KEY.COOKIE) === 'true'
  );

  const client = useApolloClient();

  const setJWTCookie = (token) => {
    if (allowingCookies && token) {
      setTokenCookie(token);
      client.writeData({
        data: { userId: getUserIdFromCookie() },
      });
    }
  };

  const handleContinue = useCallback(() => {
    validateSession();
    navigate('/dashboard');
  }, [validateSession, navigate]);

  useEffect(() => {
    if (getUserIdFromCookie()) {
      handleContinue();
    }
  }, [handleContinue]);

  const inputs = [
    {
      id: 'outlined-email-input',
      classes: classes.textField,
      label: 'E-postadress',
      type: 'email',
      name: 'email',
      autoComplete: 'email',
      value: email,
      onChange: (e) => setEmail(e.target.value),
      autoFocus: true,
    },
    !isForgotPassword && {
      id: 'outlined-password-input',
      classes: classes.passTextField,
      label: 'Lösenord',
      type: 'password',
      autoComplete: 'current-password',
      value: password,
      onChange: (e) => setPassword(e.target.value),
      autoFocus: false,
    },
  ].filter(Boolean);

  const onSubmit = async () => {
    if (isForgotPassword) {
      setIsForgotPassword(false);
      setIsPasswordReseted(true);
    } else {
      try {
        setIsSigningIn(true);
        if (allowingCookies) {
          const res = await fetch(
            `${process.env.REACT_APP_BACKEND_ADDRESS}/${ENDPOINTS.AUTHENTICATE}`,
            {
              ...FETCH_CONFIG,
              body: JSON.stringify({
                email,
                password,
              }),
            }
          );
          const json = await res.json();
          if (res.status === 200) {
            setIsSigningIn(false);
            setJWTCookie(json.token);
            handleContinue();
          } else {
            setIsSigningIn(false);
            setLoginError(json.message);
          }
        } else {
          setIsSigningIn(false);
          setLoginError(
            'Du måste acceptera cookies för att kunna logga in.'
          );
        }
      } catch (e) {
        setIsSigningIn(false);
        setLoginError(
          'E-postadress eller lösenord är felaktigt!'
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Grid
      container
      className={classes.container}
      justifyContent="center"
      alignItems="center"
    >
      <CookieConsent
        location="bottom"
        buttonText="Acceptera cookies"
        cookieName="cookie"
        buttonStyle={{
          backgroundColor: variables.colorPrimary,
          color: variables.colorWhite,
          padding: '14px',
          fontSize: '14px',
          borderRadius: '5px',
        }}
        onAccept={() => {
          setAllowingCookies(true);
        }}
        // extraCookieOptions={}
        // expires={365} // default
      >
        {
          'Vi använder cookies för att personifiera innehåll och annonser, för att tillhandahålla sociala mediafunktioner och för att analysera vår trafik. Genom att klicka på "Acceptera cookies" stänga denna banderoll eller fortsätter att använda vår webbplats accepterar du vår användning av cookies.'
        }
      </CookieConsent>

      <Error
        message={loginError && loginError}
        msgType="error"
        onClose={() => setLoginError('')}
      />
      <Grid item xs={8} md={6} lg={3}>
        <Paper>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            className={classes.containerInner}
          >
            <Grid item>
              <img
                className={classes.logo}
                src={eeLogo}
                alt="EE_LOGO"
              />
            </Grid>
            <Loading
              loading={isSigningIn}
              className={classes.signingLoading}
            >
              <Grid item>
                {!isPasswordReseted ? (
                  <Fragment>
                    {inputs.map((item) => (
                      <DefaultTextInput
                        id={item.id}
                        key={item.id}
                        classes={{ root: item.classes }}
                        style={{ marginBottom: 16 }}
                        label={item.label}
                        type={item.type}
                        autoComplete={item.autoComplete}
                        value={item.value}
                        onChange={(e) => item.onChange(e)}
                        autoFocus={item.autoFocus}
                        fullWidth
                        onKeyPress={handleKeyDown}
                      />
                    ))}
                    <TextButton
                      color="primary"
                      variant="contained"
                      style={{
                        color: variables.colorWhite,
                        marginTop: 8,
                        height: 40,
                      }}
                      fullWidth
                      onClick={onSubmit}
                      disabled={
                        !password || !validateEmail(email)
                      }
                    >
                      {isForgotPassword
                        ? 'Återställ'
                        : 'Logga in'}
                    </TextButton>
                    {/* <TextButton
                      style={{ marginTop: 16 }}
                      onClick={() => setIsForgotPassword(!isForgotPassword)}
                      fullWidth
                      size="medium"
                    >
                      {isForgotPassword ? 'Logga in' : 'Glömt lösenord?'}
                    </TextButton> */}
                  </Fragment>
                ) : (
                  <Fragment>
                    <p>
                      Kolla din mail inbox, vi har skickat
                      ett mail med en återställningslänk.
                    </p>
                    <TextButton
                      color="primary"
                      className={classes.loginButton}
                      onClick={() =>
                        setIsPasswordReseted(false)
                      }
                      fullWidth
                    >
                      Gå tillbaka
                    </TextButton>
                  </Fragment>
                )}
              </Grid>
            </Loading>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

LoginPage.propTypes = {
  classes: objectOf(shape),
};

LoginPage.defaultProps = {
  classes: null,
};

export default withStyles(styles)(LoginPage);
