import { gql } from 'apollo-boost';

export default gql`
  mutation updateAnnotationClassName(
    $elementId: String!
    $className: String!
    $content: String!
  ) {
    update_annotation(
      _set: { class_name: $className, content: $content }
      where: { element_id: { _eq: $elementId } }
    ) {
      returning {
        id
        class_name
        content
      }
    }
  }
`;
