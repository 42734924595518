import { gql } from 'apollo-boost';

export default gql`
  fragment OrganisationParts on organisation {
    zip_code
    name
    logo
    id
    color_scheme
    city
    address
    abbreviation
    publicTag: public_tag {
      id
    }
  }
`;
