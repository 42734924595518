import React, { useState } from 'react';
import {
  func,
  arrayOf,
  string,
  objectOf,
  shape,
} from 'prop-types';
import Chip from '@material-ui/core/Chip';
import {
  withStyles,
  createTheme,
} from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { useMutation } from '@apollo/react-hooks';
import stylesScss from './FilterContainer.module.scss';
import TextButton from '../../Buttons/TextButton/TextButton';
import variables from '../../../styles/variables.module.scss';
import DefaultTextInput from '../../Inputs/DefaultTextInput';
import {
  INSERT_TAG,
  DELETE_TAG,
} from '../../../graphql/mutations';
import { updateCacheForUserTag } from '../../../utils/apolloHelpers';

const styles = () => ({
  button: {
    color: variables.colorWhite,
    alignSelf: 'flex-start',
    display: 'flex',
    top: '14px',
  },
  confirmDelete: {
    marginRight: '1em',
    color: variables.colorWhite,
  },
  textField: {
    display: 'flex',
    flex: 1,
    marginRight: 24,
  },
});

const createButtonTheme = createTheme({
  palette: {
    primary: { main: variables.colorPrimary },
  },
  typography: {
    fontFamily: 'Titillium',
    useNextVariants: true,
  },
});

const deleteButtonTheme = createTheme({
  palette: {
    primary: { main: red[500] },
  },
  typography: {
    fontFamily: 'Titillium',
    useNextVariants: true,
  },
});

const FilterContainer = ({
  userTagData,
  onTagSelected,
  selectedTags,
  classes,
}) => {
  const [tagName, setTagName] = useState('');
  const [showError, setShowError] = useState({
    alreadyExist: false,
    emptyTag: false,
  });
  const [tagToDelete, setTagToDelete] = useState(null);

  const [addTag] = useMutation(INSERT_TAG);
  const [deleteTag] = useMutation(DELETE_TAG);

  const onAddTag = () => {
    const newTag = tagName.trim().toLowerCase();
    const tagExist = userTagData.find(
      (tag) => tag.name.toLowerCase() === newTag
    );
    if (newTag.length === 0) {
      setShowError({ emptyTag: true });
    } else if (tagExist) {
      setShowError({ alreadyExist: true });
    } else {
      addTag({
        variables: {
          tagName: newTag,
        },
        update(
          cache,
          { data: { insert_tag: returnData } }
        ) {
          updateCacheForUserTag(cache, returnData);
        },
      });
    }
  };

  const onDeleteTag = () => {
    if (selectedTags.includes(tagToDelete)) {
      onTagSelected(tagToDelete);
    }
    deleteTag({
      variables: { id: tagToDelete },
      update(cache, { data: { delete_tag: returnData } }) {
        updateCacheForUserTag(cache, returnData, false);
      },
    });
    setTagToDelete(null);
  };

  const clearError = () => {
    if (showError.alreadyExist || showError.emptyTag) {
      setShowError({
        alreadyExist: false,
        emptyTag: false,
      });
    }
  };

  const onInputChange = ({ target }) => {
    clearError();
    setTagName(target.value);
  };

  const onDeleteTagSelected = (tagId) => {
    clearError();
    setTagToDelete(tagId);
  };

  const handleErrors = () => {
    if (showError.alreadyExist) {
      return 'Taggen finns redan!';
    }
    if (showError.emptyTag) {
      return 'Går inte att lägga till en tom tag!';
    }
    return '';
  };

  return (
    <div className={stylesScss.container}>
      <div className={stylesScss.fieldContainer}>
        <DefaultTextInput
          className={classes.textField}
          fullWidth
          error={
            showError.alreadyExist || showError.emptyTag
          }
          id="tagInput"
          label="Skapa tagg"
          helperText={handleErrors()}
          value={tagName}
          onChange={onInputChange}
          onKeyPress={(event) =>
            event.key === 'Enter' && onAddTag()
          }
        />
        <MuiThemeProvider theme={createButtonTheme}>
          <TextButton
            className={classes.button}
            variant="contained"
            onClick={onAddTag}
            color="primary"
          >
            Skapa
          </TextButton>
        </MuiThemeProvider>
      </div>
      <div className={stylesScss.filterContainer}>
        <p>Filtrera</p>
        <div>
          {userTagData.map(({ id, name }) => {
            const tagFound = selectedTags.includes(id);
            return (
              <Chip
                key={id}
                label={name}
                style={{ margin: 4 }}
                variant={tagFound ? 'default' : 'outlined'}
                color={tagFound ? 'primary' : 'default'}
                onClick={() => onTagSelected(id)}
                onDelete={() => onDeleteTagSelected(id)}
              />
            );
          })}
        </div>
      </div>
      {tagToDelete && (
        <div className={stylesScss.confirmDeleteContainer}>
          <span className={stylesScss.deleteText}>
            Är du säker på att du vill ta bort den här
            taggen?
          </span>
          <div className={stylesScss.confirmDeleteButtons}>
            <MuiThemeProvider theme={deleteButtonTheme}>
              <TextButton
                className={classes.confirmDelete}
                color="primary"
                variant="contained"
                onClick={onDeleteTag}
              >
                Radera
              </TextButton>
            </MuiThemeProvider>
            <TextButton
              variant="contained"
              onClick={() => {
                setTagToDelete(null);
              }}
            >
              Avbryt
            </TextButton>
          </div>
        </div>
      )}
    </div>
  );
};

FilterContainer.propTypes = {
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ).isRequired,
  selectedTags: arrayOf(string).isRequired,
  onTagSelected: func,
  classes: objectOf(shape),
};

FilterContainer.defaultProps = {
  onTagSelected: null,
  classes: null,
};

export default withStyles(styles)(FilterContainer);
