import { gql } from 'apollo-boost';

export default gql`
  mutation UpdateUserSessionId($sessionId: uuid!) {
    updateUser: update_user(
      _set: { session_id: $sessionId }
      where: {}
    ) {
      res: returning {
        sessionId: session_id
      }
    }
  }
`;
