import { gql } from 'apollo-boost';
import { AnnotationParts } from '../fragments';

export default gql`
  query getAllSummarizedAnnotations(
    $bookId: uuid!
    $colorClassName: [String!]
  ) {
    page(
      order_by: { page_number: asc }
      where: { book_id: { _eq: $bookId } }
    ) {
      annotations(
        where: { class_name: { _in: $colorClassName } }
        order_by: { position: asc }
      ) {
        ...AnnotationParts
        value
        position
        page {
          page_number
          book {
            title
          }
        }
      }
    }
  }
  ${AnnotationParts}
`;
