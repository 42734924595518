import React from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import {
  objectOf,
  shape,
  func,
  string,
  node,
} from 'prop-types';

const TooltipButton = ({
  tooltipText,
  onClick,
  children,
  buttonProps,
  tooltipProps,
  tooltipClasses,
  buttonClasses,
}) => (
  <Tooltip
    classes={tooltipClasses}
    title={tooltipText}
    disableFocusListener
    {...tooltipProps}
  >
    <Button
      classes={buttonClasses}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </Button>
  </Tooltip>
);

TooltipButton.propTypes = {
  classes: objectOf(shape),
  tooltipText: string,
  onClick: func,
  children: node,
};

TooltipButton.defaultProps = {
  classes: null,
  tooltipText: null,
  onClick: null,
  children: null,
};

export default TooltipButton;
