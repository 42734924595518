import { BOOK, LIBRARY, NOTE, UPLOAD } from './constants';

export const updateSearchParams = (leftPane, rightPane) => {
  let searchParams = '?';
  if (leftPane) {
    searchParams += `left_type=${leftPane.type}`;
    switch (leftPane.type) {
      case BOOK:
        searchParams += `&left_id=${leftPane.content.book.id}&left_page=${leftPane.content.current_page}`;
        break;
      case UPLOAD:
      case NOTE:
        searchParams += `&left_id=${leftPane.content.id}`;
        break;
      default:
        break;
    }
  }
  if (rightPane) {
    searchParams += `${leftPane ? '&' : ''}right_type=${
      rightPane.type
    }`;
    switch (rightPane.type) {
      case BOOK:
        searchParams += `&right_id=${rightPane.content.book.id}&right_page=${rightPane.content.current_page}`;
        break;
      case UPLOAD:
      case NOTE:
        searchParams += `&right_id=${rightPane.content.id}`;
        break;
      default:
        break;
    }
  }
  if (
    (!!leftPane && leftPane.type !== LIBRARY) ||
    (!!rightPane && rightPane.type !== LIBRARY)
  ) {
    window.history.replaceState(null, null, searchParams);
  }
};
