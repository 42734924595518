import React from 'react';
import { string, arrayOf, func, shape } from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Box } from '@material-ui/core';
import { Book as CoverIcon } from '@material-ui/icons';
import styles from './BookItem.module.scss';
import MoreMenu from '../../MoreMenu';

const BookItem = ({
  userTagData,
  onTagSelected,
  onItemSelected,
  item,
  tags,
}) => (
  <div className={styles.bookGrid}>
    <div onClick={onItemSelected} role="button">
      <span style={{ display: 'block' }}>
        {item.cover ? (
          <img
            className={styles.cover}
            src={item.cover}
            alt="Logo"
          />
        ) : (
          <Box
            className={`${styles.cover} ${styles.coverPlaceholder}`}
          >
            <CoverIcon
              className={styles.coverIcon}
              fontSize="large"
            />
          </Box>
        )}
        <Tooltip title={item.title}>
          <p className={styles.title}>{item.title}</p>
        </Tooltip>
        <Tooltip title={item.author}>
          <p className={styles.author}>{item.author}</p>
        </Tooltip>
      </span>
    </div>
    <div className={styles.moreIconContainer}>
      <MoreMenu
        item={item}
        itemTags={tags}
        data={[{ title: 'Taggar' }]}
        userTagData={userTagData}
        onTagSelected={onTagSelected}
      />
    </div>
  </div>
);

BookItem.propTypes = {
  userTagData: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  onTagSelected: func.isRequired,
  onItemSelected: func.isRequired,
  item: shape({
    title: string,
    author: string,
    cover: string,
  }).isRequired,
  tags: arrayOf(
    shape({
      id: string,
      tag: shape({
        id: string,
        name: string,
      }),
    })
  ),
};

BookItem.defaultProps = {
  userTagData: null,
  tags: [],
};

export default BookItem;
