import moment from 'moment';

const defaultFormat = 'YYYY-MM-DD, HH:mm';
const defaultFormatSeconds = 'YYYY-MM-DD, HH:mm:ss';

export const getCurrentDate = () =>
  moment().format(defaultFormat);
export const formatDate = (date) =>
  date ? moment(date).format(defaultFormat) : null;
export const formatDateWithSeconds = (date) =>
  date ? moment(date).format(defaultFormatSeconds) : null;
