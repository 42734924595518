import React, { useState } from 'react';
import { func } from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Modal from '@material-ui/core/Modal';
import stylesScss from './ProfileContainer.module.scss';
import DefaultTextInput from '../Inputs/DefaultTextInput';
import TextButton from '../Buttons/TextButton';
import variables from '../../styles/variables.module.scss';
import { ENDPOINTS } from '../../config';
import Loading from '../Helpers/Loading/Loading';
import { getUserIdFromCookie } from '../../utils/jwtHelpers';

const ProfileContainer = ({ handleClose }) => {
  const [isPasswordChanging, setIsPasswordChanging] =
    useState(false);
  const [
    isPasswordSuccessMessage,
    setIsPasswordSuccessMessage,
  ] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    currentPasswordHelperText: '',
    newPasswordHelperText: '',
    passwordConfirmHelperText: '',
  });

  const resetHelperTexts = (type) => {
    const {
      currentPasswordHelperText,
      newPasswordHelperText,
      passwordConfirmHelperText,
    } = values;

    if (
      type === 'currentPassword' &&
      currentPasswordHelperText
    ) {
      setValues({
        ...values,
        currentPasswordHelperText: '',
      });
    } else if (
      type === 'newPassword' &&
      newPasswordHelperText
    ) {
      setValues({
        ...values,
        newPasswordHelperText: '',
      });
    } else if (
      type === 'newPasswordConfirm' &&
      passwordConfirmHelperText
    ) {
      setValues({
        ...values,
        passwordConfirmHelperText: '',
      });
    } else {
      setValues({
        ...values,
        passwordConfirmHelperText: '',
        newPasswordHelperText: '',
        currentPasswordHelperText: '',
      });
    }
  };

  const handleChange = (type) => (event) => {
    resetHelperTexts(type);
    setValues({ ...values, [type]: event.target.value });
  };

  const updatePassword = async (url = '') => {
    try {
      const userId = getUserIdFromCookie();
      setIsPasswordChanging(true);
      const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          user_id: userId,
          current_password: values.currentPassword,
          new_password: values.newPassword,
        },
        redirect: 'follow',
        referrer: 'no-referrer',
      });

      return res;
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let currentPasswordHelperText = '';
    let newPasswordHelperText = '';
    let passwordConfirmHelperText = '';
    let success = true;

    if (!values.currentPassword) {
      currentPasswordHelperText = 'Fel lösenord';
      success = false;
    } else if (!values.newPassword) {
      newPasswordHelperText = 'Ange nytt lösenord';
      success = false;
    } else if (
      values.newPassword !== values.newPasswordConfirm
    ) {
      passwordConfirmHelperText =
        'Lösenorden överensstämmer inte';
      success = false;
    }
    setValues({
      ...values,
      currentPasswordHelperText,
      newPasswordHelperText,
      passwordConfirmHelperText,
    });

    if (success) {
      const res = await updatePassword(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/${ENDPOINTS.UPDATE_PASSWORD}`
      );

      if (res.status === 200) {
        setIsPasswordChanging(false);
        setErrorMessage('');
        setIsPasswordSuccessMessage(
          'Ditt lösenord är bytt.'
        );
        setTimeout(() => {
          setIsPasswordSuccessMessage('');
          handleClose();
        }, 2500);
      } else if (res.status === 400) {
        setIsPasswordChanging(false);
        setErrorMessage('Fel lösenord');
      }
    }

    return false;
  };

  return (
    <Modal open onClose={handleClose}>
      <div className={stylesScss.container}>
        <Loading
          loading={isPasswordChanging}
          className={stylesScss.loadingSpinner}
        >
          {isPasswordSuccessMessage ? (
            <h3 className={stylesScss.passChangedLabel}>
              {isPasswordSuccessMessage}
            </h3>
          ) : (
            <div className={stylesScss.contentContainer}>
              <AccountCircle className={stylesScss.icon} />
              <form className={stylesScss.profileForm}>
                <DefaultTextInput
                  type="password"
                  label="Nuvarande lösenord"
                  value={values.currentPassword}
                  onChange={handleChange('currentPassword')}
                  style={{ marginBottom: 24 }}
                  error={
                    Boolean(errorMessage) ||
                    Boolean(
                      values.currentPasswordHelperText
                    )
                  }
                  helperText={
                    errorMessage ||
                    values.currentPasswordHelperText
                  }
                  required
                />
                <DefaultTextInput
                  type="password"
                  label="Nytt lösenord"
                  value={values.newPassword}
                  onChange={handleChange('newPassword')}
                  style={{ marginBottom: 24 }}
                  error={Boolean(
                    values.newPasswordHelperText
                  )}
                  helperText={values.newPasswordHelperText}
                  required
                />
                <DefaultTextInput
                  type="password"
                  label="Repetera lösenord"
                  value={values.newPasswordConfirm}
                  onChange={handleChange(
                    'newPasswordConfirm'
                  )}
                  style={{ marginBottom: 24 }}
                  helperText={
                    values.passwordConfirmHelperText
                  }
                  error={Boolean(
                    values.passwordConfirmHelperText
                  )}
                  required
                />
                <TextButton
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{
                    color: variables.colorWhite,
                    margin: '8px 0',
                  }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Bekräfta
                </TextButton>
                <TextButton
                  fullWidth
                  style={{ margin: '8px 0' }}
                  onClick={handleClose}
                >
                  Avbryt
                </TextButton>
              </form>
            </div>
          )}
        </Loading>
      </div>
    </Modal>
  );
};

ProfileContainer.propTypes = {
  handleClose: func.isRequired,
};

export default ProfileContainer;
