import { gql } from 'apollo-boost';

export default gql`
  mutation insertUploadTag(
    $userUploadId: uuid!
    $tagId: uuid!
  ) {
    insert_user_upload_tag(
      objects: [
        { user_upload_id: $userUploadId, tag_id: $tagId }
      ]
    ) {
      returning {
        user_upload_id
        user_upload {
          user_upload_tags {
            id
            tag {
              id
              name
            }
          }
        }
      }
    }
  }
`;
